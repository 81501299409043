import { useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, UserRoleForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import moment from "moment";
import { IUserRoleFormData } from "components/forms/user-role/schema";
import lodash from "lodash";
import { Applications } from "apollo/data";
import { GET_COMPLAINT_TYPE } from "./view";
import ComplaintTypeForm from "components/forms/complaint-type";
import { createComplaintTypeValidationSchema } from "./create";

const UPDATE_COMPLAINT_TYPE = gql`
  mutation UpdateComplaintType(
    $id: ID!
    $name: String!
    $category: ComplaintCategory!
    $userRole: ID
    $description: String
    $requiresDocument: Boolean
    $requiresInvestigation: Boolean
    $requiresServicePoint: Boolean
    $contactEmailAddress: String

  ) {
    updateComplaintType(
      name: $name
      id: $id
      category: $category
      userRole: $userRole
      description: $description
      requiresDocument: $requiresDocument
      requiresInvestigation: $requiresInvestigation
      requiresServicePoint: $requiresServicePoint
      contactEmailAddress: $contactEmailAddress

    ) {
      _id
    }
  }
`;

export default function UpdateComplaintTypeContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetchingComplaintType } = useQuery(
    GET_COMPLAINT_TYPE,
    {
      variables: {
        id: searchParams.id,
      },
      notifyOnNetworkStatusChange: false,
    }
  );
  const [updateComplaintType, { loading }] = useMutation(UPDATE_COMPLAINT_TYPE);

  const form = useFormik({
    initialValues: {
      name: "",
      description: "",
      category: "",
      requiresDocument: false,
      requiresInvestigation: false,
      requiresServicePoint: true,
      userRole: null as any,
      contactEmailAddress: "",
    },
    validationSchema: createComplaintTypeValidationSchema,
    onSubmit: async (values) => {
            await updateComplaintType({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data?.updateComplaintType?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Complaint Type Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not edit Complaint Type",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      ...data?.complaintType,
      userRole: data?.complaintType?.userRole?._id || (null as any),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.complaintType, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchingComplaintType}
      title='Edit Complaint Type Information'
      description='Provide the details to update the complaint type.' renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Complaint Type..." : "Edit Complaint Type"}
          </button>
        </>
      )}
    >
      {data?.complaintType?._id && <ComplaintTypeForm form={form} />}
    </Modal>
  );
}
