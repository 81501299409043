import { FC } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import numeral from "numeral";

interface BillInstallmentViewProps {
  bills: any[];
}

const BillInstallmentView: FC<BillInstallmentViewProps> = ({ bills }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="flex-1 flex flex-col space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto">
      {bills?.map((bill, idx) => (
        <Disclosure
          as="div"
          defaultOpen={true}
          key={idx}
          className={idx === 0 ? " py-4" : " py-4"}
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                as="div"
                className="flex justify-between items-center cursor-pointer"
              >
                <div>
                  <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
                    {moment(bill.previousReadingDate).format(dateFormat)} -{" "}
                    {moment(bill.currentReadingDate).format(dateFormat)}
                  </h3>
                  <p className="mt-1 text-xs text-gray-500">
                    {bill.previousReadingValue} - {bill.currentReadingValue}
                  </p>
                </div>
                <div>
                  {open ? (
                    <ChevronUpIcon className="h-6 w-6 text-gray-400 dark:text-gray-500" />
                  ) : (
                    <ChevronDownIcon className="h-6 w-6 text-gray-400 dark:text-gray-500" />
                  )}
                </div>
              </Disclosure.Button>
              <Disclosure.Panel as="div" className="mt-6">
                <div className="border-gray-300 border p-4 border-dashed bg-gray-50">
                  <div className="grid grid-cols-4 gap-6">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Previous Reading Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {moment(bill?.previousReadingDate).format(dateFormat)}
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Previous Reading Value
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {numeral(bill?.previousReadingValue || 0).format("0,0")}{" "}
                        KWh
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Current Reading Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {moment(bill?.currentReadingDate).format(dateFormat)}
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Current Reading Value
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {numeral(bill?.currentReadingValue || 0).format("0,0")}{" "}
                        KWh
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Previous Reading Type
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {bill?.previousReadingType}
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Current Reading Type
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {bill?.currentReadingType}
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Consumption
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {numeral(bill?.totalConsumption || 0).format("0,0")} KWh
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Bill Period
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {bill?.billPeriod || 0} Days
                      </div>
                    </div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-300 mt-4 border-collapse ">
                    <thead>
                      <tr className="bg-primary-600">
                        <th
                          scope="col"
                          colSpan={2}
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4 w-[40%]"
                        >
                          Items
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell  w-[20%]"
                        >
                          Units
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell w-[20%]"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4 w-[20%]"
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bill?.bills?.map?.((billX: any) => (
                        <>
                          <tr className="border-b border-gray-200 bg-yellow-50">
                            <td
                              colSpan={5}
                              className="py-3 pl-4 pr-3 text-sm text-gray-600 sm:pl-4"
                            >
                              {moment(billX?.previousDate).format(dateFormat)} -{" "}
                              {moment(billX?.currentDate).format(dateFormat)} (
                              {numeral(billX?.numberOfDays).format("0,0")} days)
                            </td>
                          </tr>
                          {billX?.billItems?.map(
                            (
                              item: {
                                title: string;
                                unit: string;
                                unitCost: any;
                                cost: any;
                              },
                              idx: string
                            ) => (
                              <tr
                                key={idx}
                                className="border-b border-gray-200"
                              >
                                <td
                                  colSpan={2}
                                  className="py-4 pl-4 pr-3 text-sm sm:pl-4"
                                >
                                  <div className="font-medium text-gray-900">
                                    {item.title}
                                  </div>
                                </td>
                                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                                  {item.unit}
                                </td>
                                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                                  {numeral(item.unitCost).format("0,0.0000")}
                                </td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4">
                                  {numeral(item.cost).format("0,0.00")}
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ))}
                      <tr className="border-b border-transparent">
                        <td colSpan={5} className="h-6" />
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="">
                        <th className="relative" rowSpan={3} colSpan={2}></th>
                        <th className="relative" rowSpan={3}></th>
                        <th
                          scope="row"
                          className="pl-4 pr-3  py-3 text-left text-sm font-medium text-gray-700"
                        >
                          Current Bill
                        </th>
                        <td className="pl-3 pr-4 py-3 text-right text-sm text-gray-500">
                          GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          className="bg-primary-600 pl-4 pr-3 py-5 text-left text-sm font-semibold text-white"
                        >
                          Amount Payable
                        </th>
                        <td className="bg-primary-600 pl-3 pr-4 py-5 text-right text-sm font-semibold text-white sm:pr-4">
                          GHS {numeral(bill?.totalBillAmount).format("0,0.00")}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default BillInstallmentView;
