import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { TariffInstanceView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const GET_TARIFF_INSTANCE = gql`
  query GetTariffInstance($id: ID!) {
    tariffInstance: getTariffInstance(id: $id) {
      _id
      code
      name
      tariffClass {
        _id
      }
      energyCharge {
        type
        value
        steps {
          name
          displayName
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      serviceCharge {
        type
        value
        steps {
          name
          displayName
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      hasExtraItems
      extraItems {
        name
        displayName
        category
        type
        appliedTo
        value
      }
      startDate
      endDate
      status
      createdAt
      updatedAt
    }
  }
`;

export default function ViewTariffInstanceContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_TARIFF_INSTANCE, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const cloneItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "configure",
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Tariff Instance Information"
      description="Details of tariff instance are shown below"
      renderActions={() => (
        <>
          {withPermissions([
            "*:*",
            "tariff-instances:*",
            "tariff-instances:configure",
          ])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(cloneItem)}
            >
              Clone
            </button>
          )}
        </>
      )}
    >
      <TariffInstanceView tariffInstance={data?.tariffInstance} />
    </Modal>
  );
}
