import { gql, useQuery } from '@apollo/client';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import ClassBillingPreviewForm from 'components/forms/tariff-class/billing-preview';
import { Modal } from 'components';

const GET_TARIFF_INSTANCES = gql`
  query GetTariffInstances($tariffClass: ID!) {
    tariffInstances: getTariffInstances(tariffClass: $tariffClass) {
      _id
      code
      name
      energyCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      serviceCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      hasExtraItems
      extraItems {
        name
        category
        type
        appliedTo
        value
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;


export default function CalculateTariffClassContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_TARIFF_INSTANCES, {
    variables: {
      tariffClass: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Calculate Bill"
      loading={loading}
      size='4xl'
      description="Provide details to simulate bill calculation"
      hidePadding
    >
      {data?.tariffInstances && (
        <ClassBillingPreviewForm
          tariffInstances={data?.tariffInstances}
        />
      )}
    </Modal>
  )
}