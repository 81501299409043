import { useReactiveVar } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import { IUserTeamFormData } from "./schema";

interface SummaryPageProps {
  handlePrevious: () => void;
  handleStep: (step: number) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitLoading: boolean;
  values: IUserTeamFormData;
}

function Owner({ owner, type }: { owner: any; type?: "leader" }) {
  return (
    <div
      className={classNames(
        type === "leader"
          ? "border-green-600 bg-green-50 dark:border-green-800 dark:bg-green-800 hover:border-green-400 dark:hover:border-green-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
    </div>
  );
}

const summaries = [
  {
    name: "Team Info",
    description: "Information about the team.",
    accessor: "teamInfo",
    href: 0,
    Component: ({ data }: any) => (
      <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
        <div>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Region
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.region?.name || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                District
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.district?.name || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.name || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    name: "Members Info",
    description: "Information about members assigned to the Team.",
    accessor: "teamMembersInfo",
    href: 1,
    Component: ({ data, parentData }: any) => (
      <div>
        <div className="grid grid-cols-3 gap-6">
          <Owner
            key={"leader"}
            owner={parentData?.teamInfo?.leader}
            type="leader"
          />
          {data?.members?.map?.((user: any) => (
            <Owner key={user._id} owner={user} />
          ))}
        </div>
      </div>
    ),
  },
];

const SummaryPage: FC<SummaryPageProps> = ({
  values,
  handlePrevious,
  handleStep,
  submitLoading,
  ...form
}) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto">
        {summaries.map(({ Component, ...summary }, idx) => (
          <Disclosure
            as="div"
            defaultOpen={true}
            key={idx}
            className={idx === 0 ? " p-4" : " p-4"}
          >
            {({ open }) => (
              <>
                <Disclosure.Button
                  as="div"
                  className="flex justify-between items-center cursor-pointer"
                >
                  <div>
                    <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
                      {summary.name}
                    </h3>
                    <p className="mt-1 text-xs text-gray-500">
                      {summary.description}
                    </p>
                  </div>
                  <div>
                    {open ? (
                      <ChevronUpIcon className="h-6 w-6 text-gray-400 dark:text-gray-500" />
                    ) : (
                      <ChevronDownIcon className="h-6 w-6 text-gray-400 dark:text-gray-500" />
                    )}
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel as="div" className="mt-6">
                  <Component
                    data={(values as any)[summary.accessor]}
                    parentData={values}
                    config={currentConfig}
                  />
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => handleStep(summary.href)}
                        className="ml-3 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-600 border-gray-600 hover:border-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        <PencilSquareIcon
                          className="-ml-0.5 mr-1.5 h-4 w-4"
                          aria-hidden="true"
                        />
                        Edit
                      </button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          disabled={submitLoading}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.handleCancel)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SummaryPage;
