import _ from 'lodash';
import numeral from 'numeral';
import { FC } from 'react'
import { TariffInstanceViewProps } from './tariff-instance-view';
import { classNames, withPermissions } from 'utils';
import { Cog8ToothIcon, CogIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { Link } from 'react-location';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';

interface TariffClassViewProps {
  tariffClass: {
    _id: string;
    code: string;
    name: string;
    description: string;
    serviceClass: string;
    currentInstance: TariffInstanceViewProps["tariffInstance"]
  };
}

const TariffClassView: FC<TariffClassViewProps> = ({ tariffClass }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Tariff Class Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tariffClass?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Service Class
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(tariffClass?.serviceClass || "") || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tariffClass?.name || "N/A"}
            </div>
          </div>

          <div className='col-span-4'>
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {tariffClass?.description || "N/A"}
            </div>
          </div>

        </div>
      </div>
      {tariffClass?.currentInstance ? (
        <>
          <div className='pt-6'>
            <span className='text-xs font-light'>Tariff Instance Information</span>
            <div className='grid grid-cols-3 gap-6 mt-2'>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Code
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {tariffClass?.currentInstance?.code || "N/A"}
                </div>
              </div>
              <div className='col-span-2'>
                <span className="block text-sm font-light text-gray-700">
                  Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {tariffClass?.currentInstance?.name || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Status
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  <span
                    className={classNames(
                      tariffClass?.currentInstance?.status === "Pending" ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50` : "",
                      tariffClass?.currentInstance?.status === "Expired" ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50` : "",
                      tariffClass?.currentInstance?.status === "Active" ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50` : "",
                      !tariffClass?.currentInstance?.status ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100" : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="w-1.5 h-1.5">
                      <circle fillRule="evenodd" cx="5" cy="5" r="5" clipRule="evenodd" />
                    </svg>
                    <span>
                      {tariffClass?.currentInstance?.status || "Unknown"}
                    </span>
                  </span>
                </div>
              </div>
              <div className=''>
                <span className="block text-sm font-light text-gray-700">
                  Start Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(tariffClass?.currentInstance?.startDate).format(dateFormat)}
                </div>
              </div>
              <div className=''>
                <span className="block text-sm font-light text-gray-700">
                  Start Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(tariffClass?.currentInstance?.startDate).format(dateFormat)}
                </div>
              </div>
            </div>
          </div>
          <div className='pt-6'>
            <span className='text-xs font-light'>Current Tariff Values</span>
            <div className='grid grid-cols-3 gap-6 mt-2'>


              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Charge Type
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {_.startCase(tariffClass?.currentInstance?.energyCharge?.type || "") || "N/A"}
                </div>
              </div>

              {tariffClass?.currentInstance?.energyCharge?.type === "FixedRate" && (
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Energy Charge Value (GHS)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {numeral(tariffClass?.currentInstance?.energyCharge?.value || 0).format("0,0.0000")}
                  </div>
                </div>
              )}

              {tariffClass?.currentInstance?.energyCharge?.type === "SteppedRate" && (
                <div className='col-start-1 col-span-3'>
                  <span className="block text-sm font-light text-gray-700">
                    Energy Charge Value
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-24"
                          >
                            Alias
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                          >
                            Min (KWh)
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                          >
                            Max (KWh)
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right w-28"
                          >
                            Price (GHS)
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center w-24"
                          >
                            Exclusive
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {tariffClass?.currentInstance?.energyCharge?.steps?.map((step, idx) => (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.name || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.displayName || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.minimumConsumption || 0).format("0,0")}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{step?.maximumConsumption >= Number.MAX_SAFE_INTEGER ? <>&infin;</> : numeral(step?.maximumConsumption).format("0,0")}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.value || 0).format("0,0.0000")}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-center">{step?.exclusive ? "Yes" : "No"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className='col-start-1'>
                <span className="block text-sm font-light text-gray-700">
                  Service Charge Type
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {_.startCase(tariffClass?.currentInstance?.serviceCharge?.type || "") || "N/A"}
                </div>
              </div>

              {tariffClass?.currentInstance?.serviceCharge?.type === "FixedRate" && (
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Service Charge Value (GHS)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {numeral(tariffClass?.currentInstance?.serviceCharge?.value || 0).format("0,0.0000")}
                  </div>
                </div>
              )}

              {tariffClass?.currentInstance?.serviceCharge?.type === "SteppedRate" && (
                <div className='col-start-1 col-span-3'>
                  <span className="block text-sm font-light text-gray-700">
                    Service Charge Value
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-24"
                          >
                            Alias
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                          >
                            Min (KWh)
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                          >
                            Max (KWh)
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right w-28"
                          >
                            Price (GHS)
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center w-24"
                          >
                            Exclusive
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {tariffClass?.currentInstance?.serviceCharge?.steps?.map((step, idx) => (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.name || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.displayName || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.minimumConsumption || 0).format("0,0")}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{step?.maximumConsumption >= Number.MAX_SAFE_INTEGER ? <>&infin;</> : numeral(step?.maximumConsumption).format("0,0")}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.value || 0).format("0,0.0000")}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-center">{step?.exclusive ? "Yes" : "No"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='pt-6'>
            <span className='text-xs font-light'>Extra Tariff Items</span>
            <div className="grid grid-cols-1 gap-6 mt-2">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Has Extra Items ?
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {tariffClass?.currentInstance?.hasExtraItems ? "Yes" : "No"}
                </div>
              </div>

              {tariffClass?.currentInstance?.hasExtraItems && (
                <div className='col-start-1 col-span-3'>
                  <span className="block text-sm font-light text-gray-700">
                    Extra Tariff Items
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Alias
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Category
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-left"
                          >
                            Applied To
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                          >
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {tariffClass?.currentInstance?.extraItems?.map((step, idx) => (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.name || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.displayName || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{step.category || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{_.startCase(step.type) || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{_.startCase(step.appliedTo) || "N/A"}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.value || 0).format("0,0.0000")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (

        <div className='pt-6'>
          <span className='text-xs font-light'>Tariff Instance Information</span>
          {withPermissions(["*:*", "tariff-instances:*", "tariff-instances:configure"])(
            <div className='flex  mt-2 w-full items-center justify-center'>

              <Link
                to={`/tariffs/${tariffClass?._id}`}
                search={{
                  modal: "configure"
                }}
                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <Cog8ToothIcon
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  strokeWidth={1}
                  aria-hidden="true"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No active configuration</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by configuring a new instance.</p>
              </Link>
            </div>,
            <div className='flex  mt-2 w-full items-center justify-center'>

              <div
                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <Cog8ToothIcon
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  strokeWidth={1}
                  aria-hidden="true"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No active configuration</h3>
                <p className="mt-1 text-sm text-gray-500">Contact admin to configure a new instance.</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TariffClassView