import { gql, useQuery } from "@apollo/client";

const GET_USERS_COUNT = gql`
query GetUsersCount(
  $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $role: ID
){
  count:getUsersCount(
    search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      role: $role
  )
}`

const GET_USERS = gql`
query GetUsers(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $role: ID
  ) {
    rows:getUsers(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      role: $role
    ) {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        region {
          _id
        }
        regionCode
        regionName
        district {
          _id
        }
        districtCode
        districtName
        meta {
          isPasswordSet
          isBlocked
          isDeleted
          lastLoginAt
        }
        createdAt
        updatedAt
        roleCode
        roleName
      }
  }

`;

export const useUsers = (variables: any) => {
  const { data, ...rest } = useQuery(GET_USERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { data: countData } = useQuery(GET_USERS_COUNT, {
    variables,
  });
  const users = data?.rows || [];
  const count = countData?.count || 0;
  return { users, count, ...rest };
}