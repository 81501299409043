import { gql, useQuery } from "@apollo/client";

const GET_TARIFF_CLASSES = gql`
  query GetTariffClasses(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    rows: getTariffClasses(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      name
      description
      serviceClass
      createdAt
      updatedAt
    }
  }
`;

const GET_TARIFF_CLASSES_COUNT = gql`
  query GetTariffClassesCount($search: String, $searchFields: [String!]) {
    count: getTariffClassesCount(search: $search, searchFields: $searchFields)
  }
`;

export const useTariffClasses = (variables: any) => {
  const { data, ...rest } = useQuery(GET_TARIFF_CLASSES, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { data: countData } = useQuery(GET_TARIFF_CLASSES_COUNT, {
    variables,
  });
  const tariffClasses = data?.rows || [];
  const count = countData?.count || 0;
  return { tariffClasses, count, ...rest };
};
