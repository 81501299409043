import { FC, useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, ContractorUserForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CONTRACTOR_USER } from "./view";

export const UPDATE_CONTRACTOR_USER = gql`
  mutation UpdateContractorUser(
    $id: ID!
    $username: String
    $lastName: String
    $firstName: String
    $gender: Gender
    $ghanaCardNumber: String
    $phoneNumber: String
    $emailAddress: String
    $profileImageUrl: String
  ) {
    updateContractorUser(
      id: $id
      username: $username
      lastName: $lastName
      firstName: $firstName
      gender: $gender
      ghanaCardNumber: $ghanaCardNumber
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      profileImageUrl: $profileImageUrl
    ) {
      _id
    }
  }
`;

interface UpdateContractorUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  contractor: string;
}

const UpdateContractorUserContainer: FC<UpdateContractorUserContainerProps> = ({
  open,
  setOpen,
  refetch,
  contractor,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: loadingUser } = useQuery(GET_CONTRACTOR_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateContractorUser, { loading }] = useMutation(
    UPDATE_CONTRACTOR_USER
  );

  const form = useFormik({
    initialValues: {
      username: "",
      lastName: "",
      firstName: "",
      gender: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      profileImageUrl: "",
    },
    onSubmit: async (values) => {
      await updateContractorUser({
        variables: {
          id: searchParams.id,
          ...values,
          // contractor,
        },
      }).then(({ data }) => {
        if (data.updateContractorUser._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not create User" })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      username: data?.user?.code || "",
      lastName: data?.user?.lastName || "",
      firstName: data?.user?.firstName || "",
      gender: data?.user?.gender || "",
      ghanaCardNumber: data?.user?.ghanaCardNumber || "",
      phoneNumber: data?.user?.phoneNumber || "",
      emailAddress: data?.user?.emailAddress || "",
      profileImageUrl: data?.user?.profileImageUrl || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loadingUser}
      title="Edit User Information"
      description="Provide the details to update user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing User..." : "Edit User"}
          </button>
        </>
      )}
    >
      {data?.user?._id && <ContractorUserForm form={form} />}
    </Modal>
  );
};

export default UpdateContractorUserContainer;
