import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, SelectInput, Shimmers, TableComponent } from "components";
import _ from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  withPermissions,
  wrapOnchange,
} from "utils";
import config from "config";
import {
  PlusIcon,
  ArrowUpTrayIcon,
  HomeIcon,
  BuildingStorefrontIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import ViewAccessLogContainer from "./view";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { ExportDataContainer } from "containers";

const GET_ACCESS_LOGS = gql`
  query GetAccessLogs(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    getAccessLogs(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      rows {
        _id
        code
        name
        description
        serviceClass
        currentInstance {
          _id
        }
        createdAt
        updatedAt
      }
      total
      totalPages
      page
      pageSize
    }
  }
`;

const AccessLogsPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };
  const navigateItem = (id: string) => () => {
    navigate({
      to: `./${id}`,
    });
  };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [searchBy, setSearchBy] = useUrlState("search_by");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: "".toString(),
      searchFields: ["code", "name", "description"],
      sort: searchParams.sort || "",
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_ACCESS_LOGS, {
    variables: filter,
    notifyOnNetworkStatusChange: true,
  });

  const onSearch = (searchValue: string) => {
    refetch({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: searchValue?.toString(),
      searchFields: [searchBy],
      sort: searchParams.sort || "",
    });
  };

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "access-logs:*", "access-logs:import"])(
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <ArrowUpTrayIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Import
              </button>
            )}
            {withPermissions(["*:*", "access-logs:*", "access-logs:create"])(
              <button
                type="button"
                onClick={wrapClick(() => setModal("create"))}
                className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add New Tariff Class
              </button>
            )}
          </>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"tariff classes"}
            refetch={refetch}
            onSearchClicked={onSearch}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                values={{ searchBy }}
                handleChange={wrapOnchange(setSearchBy)}
                handleBlur={undefined}
                options={[
                  {
                    label: "--- select item ---",
                    value: "",
                  },
                  {
                    label: "Code",
                    value: "code",
                  },
                  {
                    label: "Name",
                    value: "name",
                  },
                  {
                    label: "Description",
                    value: "descroption",
                  },
                ]}
              />
            )}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={data?.getAccessLogs}
            hasSearch={true}
            defaultView={"grid"}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="AccessLog"
                filter={filter}
              />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  description
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.name || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.description || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <span
                    className={classNames(
                      item?.serviceClass === "Residential"
                        ? `bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-gray-50`
                        : "",
                      item?.serviceClass === "NonResidential"
                        ? `bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-gray-50`
                        : "",
                      item?.serviceClass === "SpecialLoadTariff"
                        ? `bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-gray-50`
                        : "",
                      !item?.serviceClass
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <span>{_.startCase(item?.serviceClass || "Unknown")}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    <ActionButton
                      action="calculate"
                      onClick={dispatchAction(item?._id, "calculate")}
                      disabled={!item?.currentInstance}
                    />
                    {withPermissions([
                      "*:*",
                      "access-logs:*",
                      "access-logs:update",
                    ])(
                      <ActionButton
                        action="update"
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                    {withPermissions([
                      "*:*",
                      "tariff-instances:*",
                      "tariff-instances:manage",
                    ])(
                      <ActionButton
                        action="expand"
                        onClick={navigateItem(item?._id)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
            renderGridLoader={() => (
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="flex pb-8 px-4 pt-4">
                  <div className="rounded-md h-12 w-12 bg-gray-400" />
                  <dd className="ml-4 flex-1 flex items-center">
                    <Shimmers.DoubleShimmer />
                  </dd>
                </div>
                <div className=" bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4 space-x-10">
                  <Shimmers.SingleShimmer />
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </div>
              </div>
            )}
            renderGridItem={(item) => (
              <div
                key={item?._id}
                className="relative overflow-hidden rounded-lg bg-white pb-8 shadow px-4 pt-4"
              >
                <dt>
                  <div
                    className={classNames(
                      item?.serviceClass === "Residential" ? "bg-cyan-600" : "",
                      item?.serviceClass === "NonResidential"
                        ? "bg-primary-600"
                        : "",
                      item?.serviceClass === "SpecialLoadTariff"
                        ? "bg-primary-600"
                        : "",
                      "absolute rounded-md p-3"
                    )}
                  >
                    {item?.serviceClass === "Residential" && (
                      <HomeIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    )}
                    {item?.serviceClass === "NonResidential" && (
                      <BuildingStorefrontIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    )}
                    {item?.serviceClass === "SpecialLoadTariff" && (
                      <BuildingOffice2Icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    {item?.code}
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="font-normal text-gray-900 line-clamp-1">
                    {item?.name}
                  </p>
                  <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4">
                    <span
                      className={classNames(
                        item?.serviceClass === "Residential"
                          ? `bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-gray-50`
                          : "",
                        item?.serviceClass === "NonResidential"
                          ? `bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-gray-50`
                          : "",
                        item?.serviceClass === "SpecialLoadTariff"
                          ? `bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-gray-50`
                          : "",
                        // "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100",
                        "inline-flex rounded-full  px-3 py-0 text-xs items-center space-x-1"
                      )}
                    >
                      <span>
                        {_.startCase(item?.serviceClass || "Unknown")}
                      </span>
                    </span>

                    <div className="space-x-1">
                      <ActionButton
                        action="view"
                        onClick={dispatchAction(item?._id, "view")}
                      />
                      <ActionButton
                        action="calculate"
                        onClick={dispatchAction(item?._id, "calculate")}
                        disabled={!item?.currentInstance}
                      />
                      {withPermissions([
                        "*:*",
                        "access-logs:*",
                        "access-logs:update",
                      ])(
                        <ActionButton
                          action="update"
                          onClick={dispatchAction(item?._id, "update")}
                        />
                      )}
                      {withPermissions([
                        "*:*",
                        "tariff-instances:*",
                        "tariff-instances:manage",
                      ])(
                        <ActionButton
                          action="expand"
                          onClick={navigateItem(item?._id)}
                        />
                      )}
                    </div>
                  </div>
                </dd>
              </div>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewAccessLogContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default AccessLogsPage;
