import { Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik"
import _ from "lodash";
import { FC } from "react"
import { classNames, wrapClick } from "utils";

interface ExtraItemsInfoFormProps {
  handleNext: (values: any) => void
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
}


const ExtraItemsInfoForm: FC<ExtraItemsInfoFormProps> = ({ initialValues, handleNext, handlePrevious, handleCancel }) => {

  const form = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter((val: string, _id: number) => _id !== id),
    ])
  }

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Extra Tariff Items Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">

            <div className="">
              <label htmlFor={"hasExtraItems"} className="block text-sm font-medium text-gray-700">
                Has Extra Tariff Items?
              </label>
              <div className="mt-1">
                <Switch.Group as="div" className="">
                  <Switch
                    checked={_.get(form.values, `hasExtraItems`)}
                    onChange={(_val: boolean) => form.setFieldValue(`hasExtraItems`, _val)}
                    className={classNames(
                      _.get(form.values, "hasExtraItems") ? "bg-primary-600" : "bg-gray-200 dark:bg-gray-700",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        _.get(form.values, "hasExtraItems") ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-800 shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
            {form.values.hasExtraItems && (
              <div className="col-span-3">
                <span className="block text-sm font-medium text-gray-700">
                  Extra Tariff Items *
                </span>
                <div className="mt-1 w-full ">
                  <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-24"
                        >
                          Alias
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-28"
                        >
                          Category
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-28"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right w-28"
                        >
                          Value
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center"
                        >
                          Applied To
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right w-[38px]"
                        >
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {_.times(form.values.extraItems?.length + 1, (idx) => {
                        const id = `extraItems.${idx}`;
                        const isLast = (form.values.extraItems?.length || 0) === idx

                        return (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="p-0">
                              <input
                                key={idx}
                                type={"text"}
                                id={`${id}.name`}
                                name={`${id}.name`}
                                value={_.get(form.values, `${id}.name`, "")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                placeholder={"eg. VAT"}
                                className={classNames(
                                  _.get(form.errors, `${id}.name`) && _.get(form.touched, `${id}.name`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                  "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                )}
                              />
                            </td>
                            <td className="p-0">
                              <input
                                key={idx}
                                type={"text"}
                                id={`${id}.displayName`}
                                name={`${id}.displayName`}
                                value={_.get(form.values, `${id}.displayName`, "")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                placeholder={"eg. EFT"}
                                className={classNames(
                                  _.get(form.errors, `${id}.displayName`) && _.get(form.touched, `${id}.displayName`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                  "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                )}
                              />
                            </td>
                            <td className="p-0">
                              <select
                                key={idx}
                                id={`${id}.category`}
                                name={`${id}.category`}
                                value={_.get(form.values, `${id}.category`, "")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                placeholder={"eg. 0"}
                                className={classNames(
                                  _.get(form.errors, `${id}.category`) && _.get(form.touched, `${id}.category`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                  "block w-max-content sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                )}
                              >
                              {[
                                {label: "- select -", value: ""},
                                {label: "Levy", value: "Levy"},
                                {label: "Subsidy", value: "Subsidy"},
                                {label: "Tax", value: "Tax"},
                              ]
                              .map((option, idx) => (
                                <option key={idx} value={option.value}>{option.label}</option>
                              ))}
                            </select>
                            </td>
                            <td className="p-0">
                              <select
                                key={idx}
                                id={`${id}.type`}
                                name={`${id}.type`}
                                value={_.get(form.values, `${id}.type`, "")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                placeholder={"eg. 300"}
                                className={classNames(
                                  _.get(form.errors, `${id}.type`) && _.get(form.touched, `${id}.type`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                  "block w-max-content sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                )}
                                >
                                {[
                                  {label: "- select -", value: ""},
                                  {label: "Percentage", value: "FixedPercentage"},
                                  {label: "Rate", value: "FixedRate"},
                                  {label: "Value", value: "FixedValue"},
                                ]
                                .map((option, idx) => (
                                  <option key={idx} value={option.value}>{option.label}</option>
                                ))}
                              </select>
                            </td>
                            <td className="p-0">
                              <input
                                key={idx}
                                type={"number"}
                                id={`${id}.value`}
                                name={`${id}.value`}
                                value={_.get(form.values, `${id}.value`, "")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                placeholder={"eg. 0.343043"}
                                step={0.0001}
                                min={0}
                                className={classNames(
                                  _.get(form.errors, `${id}.value`) && _.get(form.touched, `${id}.value`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                  "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                )}
                              />
                            </td>
                            <td className="p-0">
                              <select
                                key={idx}
                                id={`${id}.appliedTo`}
                                name={`${id}.appliedTo`}
                                value={_.get(form.values, `${id}.appliedTo`, "")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                placeholder={"eg. 300"}
                                className={classNames(
                                  _.get(form.errors, `${id}.appliedTo`) && _.get(form.touched, `${id}.appliedTo`) ? "focus:ring-red-500 focus:border-red-500 border-red-600 border" : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                  "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border"
                                )}
                                >
                                {[
                                  {label: "- select -", value: ""},
                                  {label: "Energy Charge", value: "EnergyCharge"},
                                  {label: "Service Charge", value: "ServiceCharge"},
                                  {label: "Energy + Service Charge", value: "EnergyPlusServiceCharge"},
                                  {label: "Consumption Period", value: "ConsumptionPeriod"},
                                  {label: "Consumption Value", value: "ConsumptionValue"},
                                ]
                                .map((option, idx) => (
                                  <option key={idx} value={option.value}>{option.label}</option>
                                ))}
                              </select>
                            </td>
                            <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                              {!isLast && (
                                <button
                                  type="button"
                                  onClick={wrapClick(() => removeItem("extraItems", idx))}
                                  className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                >
                                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default ExtraItemsInfoForm