import { gql, useQuery } from "@apollo/client";


const GET_METER_CONTRACTORS = gql`
query GetContractors(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    rows:getMeterContractors(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
        _id
        code
        name
        address
        ghanaPostAddress
        contactPerson {
          emailAddress
          fullName
          phoneNumber
        }
        createdAt
        updatedAt
    }
  }

`;


const GET_METER_CONTRACTORS_COUNT = gql`
query GetMeterContractorsCount(
  $search: String
  $searchFields: [String!]
  $district: ID
) {
  count: getMeterContractorsCount(
    search: $search
    searchFields: $searchFields
    district: $district
  )
}

`

export const useMeterContractors = (variables: any) => {
  const { data, ...rest } = useQuery(GET_METER_CONTRACTORS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { data: countData } = useQuery(GET_METER_CONTRACTORS_COUNT, {
    variables,
  });
  const contractors = data?.rows || [];
  const count = countData?.count || 0;
  return { contractors, count, ...rest };
}