import { gql, useQuery, useReactiveVar } from "@apollo/client"
import { currentConfigVar } from "apollo/cache/config"
import moment from "moment"
import numeral from "numeral"
import { FC } from "react"
import { useSearch } from "react-location"
import { LocationGenerics } from "router/location"
import { classNames } from "utils"

const statsMap = [
  {
    title: "Consumption & Billing",
    extraClasses: "md:col-span-6 text-5xl tracking-wider",
    stats: [
      { name: "Total Billed Amount (GHS)", accessor: "totalBilledAmount" },
      { name: "Total Consumption Billed (KWh)", accessor: "totalConsumptionBilled" },
    ]
  },
  {
    title: "Meter Engagements",
    extraClasses: "md:col-span-3 text-3xl tracking-wider",
    stats: [
      { name: "Total Bills Generated", accessor: "totalBilledMeters" },
      { name: "Total Anomalies Reported", accessor: "totalAnomaliesReported" },
      { name: "Total Exceptions Reported", accessor: "totalExceptionsReported" },
      { name: "Total Meter Engagements", accessor: "totalEngagedMeters" },
    ]
  },
  {
    title: "Access & Traces",
    extraClasses: "md:col-span-3 text-3xl tracking-wider",
    stats: [
      { name: "Total Non-Access", accessor: "totalNonAccessMeters" },
      { name: "Total No Trace Meters", accessor: "totalNoTraceMeters" },
      { name: "Total Unlisted Meters", accessor: "totalUnlistedMeters" },
      { name: "Total Non-Visited", accessor: "totalUnvisitedMeters" },
    ]
  },
  {
    title: "Meter Readers",
    extraClasses: "md:col-span-4 text-3xl tracking-wider",
    stats: [
      { name: "Operational Meter Readers", accessor: "totalWorkingMeterReaders" },
      { name: "Non-Operational Meter Readers", accessor: "totalNotWorkingMeterReaders" },
      { name: "Total Meter Readers", accessor: "totalMeterReaders" },
    ]
  },
]

const DATE_FORMAT = "YYYY-MM-DD";

const OVERVIEW_SUMMARY = gql`
query GetSummaryReadingOverview($region: ID, $district: ID, $fromDate: DateTime, $toDate: DateTime) {
  summary: getSummaryReadingOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate) {
    totalMeters
    totalBilledAmount
    totalBilledMeters
    totalEngagedMeters
    totalNoTraceMeters
    totalUnlistedMeters
    totalNonAccessMeters
    totalUnvisitedMeters
    totalConsumptionBilled
    totalAnomaliesReported
    totalExceptionsReported
    totalMeterReaders
    totalWorkingMeterReaders
    totalNotWorkingMeterReaders
  }
}
`

const ReadingOverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>()

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      region: searchParams?.region || undefined,
      district: searchParams?.district || undefined,
      ...(searchParams?.view !== "month"
        ? {
          fromDate: moment(searchParams?.fromDate, DATE_FORMAT).startOf("day").toISOString(),
          toDate: moment(searchParams?.toDate, DATE_FORMAT).endOf("day").toISOString()
        }
        : {
          month: moment(searchParams.fromDate || new Date()).month() + 1,
          year: moment(searchParams.fromDate || new Date()).year()
        }
      )
    },
  })

  return (
    <div className="space-y-6">
      {statsMap?.map(({ title, extraClasses, stats }) => (
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
          <dl className="mt-5 grid grid-cols-1 gap-6 md:grid-cols-12">
            {stats.map((item, index) => (
              <div key={item.name} className={classNames(
                (index !== 0 && index !== 5) ? "border-l border-gray-200" : "",
                (index >= 5) ? "border-t border-gray-200" : "",
                "px-4 py-5 sm:p-6",
                `md:col-span-${12 / stats.length}`,
                "overflow-hidden rounded-lg shadow bg-white  text-2xl",
                extraClasses
              )}>
                <dt className="text-sm font-normal tracking-normal text-gray-900">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline font-semibold text-primary-600">
                    {numeral(data?.summary?.[item.accessor] || 0).format(item.accessor === "totalBilled" ? "0,0.00" : "0,0")}
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      ))}
    </div>
  )
}

export default ReadingOverviewContainer