import { TextInput } from "components/core"
import { useFormik } from "formik"
import { FC, useMemo } from "react"
import { generateBill } from "./generate-bill";
import moment from "moment";
import numeral from "numeral";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import lodash from "lodash";

interface BillingPreviewFormProps {
  tariffInstances: any[]
}

const ClassBillingPreviewForm: FC<BillingPreviewFormProps> = ({ tariffInstances }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const form = useFormik({
    initialValues: {
      previousReadingDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      previousReadingValue: 0,
      currentReadingDate: moment().format("YYYY-MM-DD"),
      currentReadingValue: 0,
    },
    onSubmit: (values) => {
    }
  })

  const previewBill = useMemo(() => generateBill({
    ...form.values,
    previousReadingDate: moment(form.values.previousReadingDate).toDate(),
    currentReadingDate: moment(form.values.currentReadingDate).toDate(),
    tariffInstances: lodash.filter(
      tariffInstances,
      (tariffInstance) =>
        moment(tariffInstance.endDate).isSameOrAfter(
          form.values.previousReadingDate
        ) || !tariffInstance.endDate
    )
  }), [form.values, tariffInstances])

  return (
    <div className="flex-1 flex flex-col overflow-y-auto p-6">
      <div className="space-y-6 divide-y divide-gray-200">
        <div>
          <span className="text-xs font-light">Billing Information</span>
          <div className="grid grid-cols-4 gap-6 mt-2">

            <div className="">
              <TextInput
                id="previousReadingDate"
                label="Last Reading Date"
                type="date"
                placeholder="User Code"
                required={true}
                {...form}
              />
            </div>

            <div className="">
              <TextInput
                id="currentReadingDate"
                label="Current Reading Date"
                type="date"
                placeholder="User Code"
                required={true}
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="previousReadingValue"
                label="Last Reading (KWh)"
                type="number"
                step={1}
                placeholder="eg. 1000"
                required={true}
                {...form}
              />
            </div>

            <div>
              <TextInput
                id="currentReadingValue"
                label="Current Reading (KWh)"
                type="number"
                step={1}
                placeholder="eg. 1000"
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>


        <div className="pt-6">
          <span className="text-xs font-light">Bill Preview</span>
          <div className="mt-2 border-gray-300 border p-4 border-dashed bg-gray-50">
            <div className="grid grid-cols-4 gap-6">

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Previous Reading
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(form?.values?.previousReadingValue || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Current Reading
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(form?.values?.currentReadingValue || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Consumption
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(previewBill?.totalConsumption || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Bill Period
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {previewBill?.billPeriod || 0} Days
                </div>
              </div>


            </div>
            <table className="min-w-full divide-y divide-gray-300 mt-4 border-collapse ">
              <thead>
                <tr className="bg-primary-600">
                  <th
                    scope="col"
                    colSpan={2}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4 w-[40%]"
                  >
                    Items
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell  w-[20%]"
                  >
                    Units
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell w-[20%]"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4 w-[20%]"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {previewBill?.bills?.map?.((billX) => (
                  <>
                    <tr className="border-b border-gray-200 bg-yellow-50">
                      <td
                        colSpan={5}
                        className="py-3 pl-4 pr-3 text-sm text-gray-600 sm:pl-4"
                      >
                        {moment(billX?.previousDate).format(dateFormat)} -{" "}
                        {moment(billX?.currentDate).format(dateFormat)} (
                        {numeral(billX?.numberOfDays).format("0,0")} days)
                      </td>
                    </tr>
                    {billX?.billItems?.map((item, idx) => (
                      <tr key={idx} className="border-b border-gray-200">
                        <td
                          colSpan={2}
                          className="py-4 pl-4 pr-3 text-sm sm:pl-4"
                        >
                          <div className="font-medium text-gray-900">
                            {item.title}
                          </div>
                        </td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                          {item.unit}
                        </td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                          {numeral(item.unitCost).format("0,0.0000")}
                        </td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4">
                          {numeral(item.cost).format("0,0.00")}
                        </td>
                      </tr>
                    ))}
                  </>
                ))}
                <tr className="border-b border-transparent">
                  <td colSpan={5} className="h-6" />
                </tr>
              </tbody>
              <tfoot>
                <tr className="">
                  <th className="relative" rowSpan={3} colSpan={2}>
                    
                  </th>
                  <th className="relative" rowSpan={3}>
                    
                  </th>
                  <th
                    scope="row"
                    className="pl-4 pr-3  py-3 text-left text-sm font-medium text-gray-700"
                  >
                    Current Bill
                  </th>
                  <td className="pl-3 pr-4 py-3 text-right text-sm text-gray-500">
                    GHS {numeral(previewBill?.totalBillAmount).format("0,0.00")}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    className="bg-primary-600 pl-4 pr-3 py-5 text-left text-sm font-semibold text-white"
                  >
                    Amount Payable
                  </th>
                  <td className="bg-primary-600 pl-3 pr-4 py-5 text-right text-sm font-semibold text-white sm:pr-4">
                  GHS {numeral(previewBill?.totalBillAmount).format("0,0.00")}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClassBillingPreviewForm