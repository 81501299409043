import { validators, wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import MeterContractorForm from 'components/forms/meter-contractor';
import * as yup from 'yup';

const CREATE_CONTRACTOR = gql`
mutation CreateContractor(
  $name: String!
  $address: String!
  $ghanaPostAddress: String!
  $contactPerson: ContactPersonInput!
  $districts: [ID!]!
) {
  createMeterContractor(
    name: $name
    address: $address
    ghanaPostAddress: $ghanaPostAddress
    contactPerson: $contactPerson
    districts: $districts
  ) {
    _id
    code
    createdAt
    updatedAt
  }
}

`

export default function CreateContractorContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const [createContractor, { loading }] = useMutation(CREATE_CONTRACTOR)

  const form = useFormik({
    initialValues: {
      name: "",
      address: "",
      ghanaPostAddress: "",
      districts: [] as string[],
      contactPerson: {
        fullName: "",
        emailAddress: "",
        phoneNumber: "",
      }
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Contractor name is required"),
      address: yup.string().required("Contractor address is required"),
      ghanaPostAddress: yup.string()
      .matches(
        validators.GhanaPostRegex,
        "Kindly enter a valid GhanaPost Address"
      )
      .required("GhanaPost Address is required"),
      district: yup.array().min(1, "Kindly enter at least one district"),
      contactPerson: yup.object().shape({
        fullName: yup.string().required("Contact Person's name is required"),
        emailAddress: yup.string().email("Invalid email address"),
        phoneNumber: yup.string()
          .matches(
            /^(0|\+233)(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
            "Kindly enter a valid phone number"
          )
          .required("Phone number is required"),
      }),
    }),
    onSubmit: async (values) => {
      await createContractor({
        variables: {
          ...values,
        }
      }).then(({ data }) => {
        if (data.createMeterContractor._id) {
          toast(JSON.stringify({ type: "success", title: "Contractor Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Contractor" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Meter Contractor"
      description="Provide the details to add a new meter contractor"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Meter Contractor..." : "Add Meter Contractor"}
          </button>

        </>
      )}>
      <MeterContractorForm
        form={form}
      />
    </Modal>
  )
}