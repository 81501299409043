import { Switch } from "@headlessui/react";
import { Applications } from "apollo/data";
import { useFormik } from "formik";
import _ from "lodash";
import { FC } from "react";
import { classNames, wrapClick } from "utils";

interface PermissionsInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  validationSchema?: any;
  values: any;
  handleCancel: () => void;
}

const PermissionsInfoForm: FC<PermissionsInfoFormProps> = ({
  initialValues,
  validationSchema,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // normalise items here

      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1">
        <div className="-pb-3">
          <h1 className="font-semibold mt-4">Application Access Control</h1>
          {_.get( form.errors, "applications") && _.get(form.touched, "applications") ? (
            <p className='text-sm !mt-1 text-red-600' id={`applications-error`}>
              {_.get(form.errors, "applications")?.toString()}
            </p>
          ) : null}
        </div>
        {_.map(Applications, (application, idx) => {
          const id = `${application.code}`;
          return (
            <div key={idx} className="pt-6 first:pt-0">
              <span className="text-xs font-light">{application.url}</span>
              <div className="space-y-6 mt-2">
                <div className="col-span-3">
                  <Switch.Group as="div">
                    <div className="flex justify-between">
                      <span className="flex flex-grow flex-col">
                        <Switch.Label
                          as="span"
                          className="text-sm font-medium text-gray-900"
                          passive
                        >
                          Gets Access to {application?.name}?
                        </Switch.Label>
                        <Switch.Description
                          as="span"
                          className="text-sm text-gray-500 mt-1"
                        >
                          {application?.description}
                        </Switch.Description>
                      </span>

                      <Switch
                        checked={_.includes(
                          form?.values?.applications,
                          application.code
                        )}
                        onChange={(val: boolean) =>
                          val
                            ? form.setFieldValue("applications", [
                                ...form?.values?.applications,
                                application.code,
                              ])
                            : form.setFieldValue(
                                "applications",
                                _.filter(
                                  form?.values?.applications,
                                  (app) => app !== application.code
                                )
                              )
                        }
                        className={classNames(
                          _.includes(
                            form?.values?.applications,
                            application.code
                          )
                            ? "bg-primary-600"
                            : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            _.includes(
                              form?.values?.applications,
                              application.code
                            )
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                </div>
              </div>
            </div>
          );
        })}
        
        <div className="space-y-6 mt-2">
          <div>
            <h1 className="font-semibold mt-4">Role Permissions</h1>
            {_.get( form.errors, "permissions") && _.get(form.touched, "permissions") ? (
              <p className='!mt-1 text-sm text-red-600' id={`permissions-error`}>
                {_.get(form.errors, "permissions")?.toString()}
              </p>
            ) : null}
          </div>
          <div className="border border-gray-300 bg-gray-50 grid grid-cols-3 gap-6 p-6 rounded">
            {_.map(
              _.reduce(
                Applications,
                (acc: any[], app) => {
                  _.forEach(app.sections, (section) => {
                    const existingSection = _.find(acc, {
                      name: section.name,
                    });
                    if (!existingSection) {
                      acc.push({
                        name: section.name,
                        permissions: section.permissions,
                      });
                    } else {
                      existingSection.permissions = [
                        ...existingSection.permissions,
                        ...section.permissions.filter(
                          (perm) =>
                            !existingSection.permissions
                              ?.map((existingPerm: any) => existingPerm?.value)
                              .includes(perm.value)
                        ),
                      ];
                    }
                  });

                  return acc;
                },
                []
              ),
              (section) => (
                <div className="">
                  <span className="block text-sm font-medium text-gray-700">
                    {_.startCase(section.name)}
                  </span>
                  <div className="mt-1 w-full space-y-1">
                    {_.map(section.permissions, (permission) => (
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id={`${permission?.value}.permissions`}
                            aria-describedby={`${permission.value}-description`}
                            name={`${permission?.value}.permissions`}
                            type="checkbox"
                            value={permission.value}
                            checked={_.includes(
                              form?.values?.permissions,
                              permission.value
                            )}
                            onChange={(e) =>
                              e.target.checked
                                ? form.setFieldValue("permissions", [
                                    ...form?.values?.permissions,
                                    permission.value,
                                  ])
                                : form.setFieldValue(
                                    "permissions",
                                    _.filter(
                                      form?.values?.permissions,
                                      (perm) => perm != permission.value
                                    )
                                  )
                            }
                            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor={`${permission?.value}.permissions`}
                            className="font-medium text-gray-900"
                          >
                            {permission.value}
                          </label>
                          {false && (
                            <p
                              id="candidates-description"
                              className="text-gray-500"
                            >
                              Get notified when a candidate applies for a job.
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PermissionsInfoForm;
