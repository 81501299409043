import { Outlet, Route } from "react-location";
import {
  DashboardPage,
  DistrictsPage,
  RegionsPage,
  ForgotPasswordPage,
  SettingsPage,
  SignInPage,
  TariffInstancesPage,
  TariffClassesPage,
  UsersPage,
  AuthPage,
  UserRolesPage,
  ActivityLogsPage,
  ContractorsPage,
  BillSimulatorPage,
  UserTeamsPage,
} from "pages";
import {
  BriefcaseIcon,
  ChartBarSquareIcon,
  IdentificationIcon,
  MapIcon,
  ReceiptPercentIcon,
  Square3Stack3DIcon,
  UsersIcon,
  CursorArrowRippleIcon,
  CalculatorIcon,
  ChatBubbleLeftRightIcon,
  CogIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { TariffInstancesBreadcrumb } from "containers";
import CalendarLayout from "layouts/calendar-wrapper";
import { UserPermission } from "apollo/cache/auth";
import ContractorUsersPage from "pages/contractor-users";
import MeterContractorsPage from "pages/meter-contractors";
import MeterContractorUsersPage from "pages/meter-contractor-users";
import ComplaintTypesPage from "pages/complaint-types";
import ComplaintCategoriesPage from "pages/complaint-categories";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  // STATISTICS
  {
    path: "/",
    element: <CalendarLayout />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "Statistics",
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "Statistics",
        },
      },
    ],
  },

  // Configuration
  {
    path: "regions",
    element: <RegionsPage />,
    sidebar: {
      label: "Regions",
      icon: MapIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regions Management",
      section: "Configuration",
    },
    withPermissions: ["*:*", "regions:*", "regions:manage"],
  },
  {
    path: "districts",
    element: <DistrictsPage />,
    sidebar: {
      label: "Districts",
      icon: Square3Stack3DIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Districts Management",
      section: "Configuration",
    },
    withPermissions: ["*:*", "districts:*", "districts:manage"],
  },

  // Configuration
  // {
  //   path: "access-logs",
  //   element: <AccessLogsPage />,
  //   sidebar: {
  //     label: "Access Logs",
  //     icon: ClockIcon
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Access Logs",
  //     section: "Audit Trail",
  //   },
  //   withPermissions: ["*:*", "access-logs:*", "access-logs:manage"]
  // },
  {
    path: "activity-logs",
    element: <ActivityLogsPage />,
    sidebar: {
      label: "Activity Logs",
      icon: CursorArrowRippleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Activity Logs",
      section: "Audit Trail",
    },
    withPermissions: ["*:*", "activity-logs:*", "activity-logs:manage"],
  },

  // USERS AND PROFILE
  {
    path: "users",
    element: <UsersPage />,
    sidebar: {
      label: "Users",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Users",
      section: "Users & Permissions",
    },
    withPermissions: ["*:*", "users:*", "users:manage"],
  },
  {
    path: "user-roles",
    element: <UserRolesPage />,
    sidebar: {
      label: "User Roles",
      icon: IdentificationIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "User Roles",
      section: "Users & Permissions",
    },
    withPermissions: ["*:*", "user-roles:*", "user-roles:manage"],
  },
  {
    path: "user-teams",
    element: <UserTeamsPage />,
    sidebar: {
      label: "User Teams",
      icon: UserGroupIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "User Teams",
      section: "Users & Permissions",
    },
    withPermissions: ["*:*", "user-teams:*", "user-teams:manage"],
  },
  {
    path: "complaint-types",
    element: <ComplaintTypesPage />,
    sidebar: {
      label: "Complaint Types",
      icon: ChatBubbleLeftRightIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Complaint Types",
      section: "Complaints",
    },
    //TODO: set permissions for complaint types
  },
  {
    path: "complaint-categories",
    element: <ComplaintCategoriesPage />,
    sidebar: {
      label: "Complaint Categories",
      icon: CogIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Complaint Categories",
      section: "Complaints",
    },
    withPermissions: [],
  },
  {
    path: "contractors",
    element: <Outlet />,
    sidebar: {
      label: "Contractors",
      icon: BriefcaseIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Contractors",
      section: "Users & Permissions",
    },
    withPermissions: ["*:*", "contractors:*", "contractors:manage"],
    children: [
      {
        path: "/",
        element: <ContractorsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Contractors",
          section: "Users & Permissions",
        },
        withPermissions: ["*:*", "contractors:*", "contractors:manage"],
      },
      {
        path: ":contractor",
        element: <ContractorUsersPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Contractors Users",
          section: "Users & Permissions",
        },
        withPermissions: ["*:*", "contractors:*", "contractors:manage"],
      },
    ],
  },
  {
    path: "meter-contractors",
    element: <Outlet />,
    sidebar: {
      label: "Meter Contractors",
      icon: BriefcaseIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Contractors",
      section: "Users & Permissions",
    },
    withPermissions: ["*:*", "contractors:*", "contractors:manage"],
    children: [
      {
        path: "/",
        element: <MeterContractorsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Contractors",
          section: "Users & Permissions",
        },
        withPermissions: ["*:*", "contractors:*", "contractors:manage"],
      },
      {
        path: ":meterContractor",
        element: <MeterContractorUsersPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Meter Contractor's Users",
          section: "Users & Permissions",
        },
        withPermissions: ["*:*", "contractors:*", "contractors:manage"],
      },
    ],
  },

  // CONFIGURATION
  {
    path: "tariffs",
    element: <Outlet />,
    sidebar: {
      label: "Tariffs",
      icon: ReceiptPercentIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Tariff Management",
      section: "Configuration",
    },
    children: [
      {
        path: "/",
        element: <TariffClassesPage />,
        meta: {
          layout: "App",
          section: "Configuration",
        },
        withPermissions: ["*:*", "tariff-classes:*", "tariff-classes:manage"],
      },
      {
        path: ":tariffClass",
        element: <TariffInstancesPage />,
        meta: {
          layout: "App",
          breadcrumb: ({ tariffClass }: { tariffClass: string }) => {
            return <TariffInstancesBreadcrumb id={tariffClass} />;
          },
          section: "Configuration",
        },
        withPermissions: [
          "*:*",
          "tariff-instances:*",
          "tariff-instances:manage",
        ],
      },
    ],
    withPermissions: ["*:*", "tariff-classes:*", "tariff-classes:manage"],
  },
  {
    path: "bill-simulator",
    element: <BillSimulatorPage />,
    sidebar: {
      label: "Bill Simulator",
      icon: CalculatorIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Bill Simulator",
      section: "Configuration",
    },
    withPermissions: ["*:*", "tariff-classes:*", "tariff-classes:manage"],
  },

  {
    path: "settings",
    element: <SettingsPage />,
    meta: {
      layout: "App",
      breadcrumb: () => "Settings",
      section: "Configuration",
    },
  },

  // AUTH
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SignInPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
