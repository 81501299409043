import { Application, UserPermission } from "apollo/cache/auth";
import * as Yup from "yup";

export const SigninSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export const SigninInitialValues = {
  email: "",
  password: "",
};

export interface IUserTeamFormData {
  teamInfo: {
    name: string;
    region?: {
      _id: string;
      code: string;
      name: string;
    } | null;
    district?: {
      _id: string;
      code: string;
      name: string;
    } | null;
    leader?: any | null;
  };
  teamMembersInfo: {
    members: any[];
  };
}

export const UserTeamFormDataValidationSchema = Yup.object({
  teamInfo: Yup.object({
    name: Yup.string().min(3, "Team name is too short").max(128, "Team name is too long").required("Kindly enter a team name"),
    region: Yup.object().nullable(true).required("Kindly Select A Region"),
    district: Yup.object().nullable(true).required("Kindly Select A District"),
    leader: Yup.object().nullable(true).required("Kindly Select A Leader"),
  }),
  teamMembersInfo: Yup.object({
    members: Yup.array().of(Yup.object().nullable(true)).min(1, "Kindly add at least one member"),
  }),
})
export type UserTeamFormDataValidationSchemaType = typeof UserTeamFormDataValidationSchema