import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { FC } from 'react'
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { classNames } from 'utils'
import { Heatmap } from '../graphs'

const DATE_FORMAT = "YYYY-MM-DD";

const OVERVIEW_SUMMARY = gql`
query GetUsersActivationSummaries($filter: GetUsersActivationSummaryFilter) {
  summary: getUsersActivationSummaries(filter: $filter) {
    region
    total
    completedSignup
    dropoffSignup
    completedKyc
    dropoffKyc
    addedFundSource
  }
}
`

const SignupHeatmapContainer: FC = () => {

  const searchParams = useSearch<LocationGenerics>()

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      filter: {

        ...((searchParams?.region && searchParams.region !== "world") ? {
          region: {
            eq: searchParams.region
          }
        } : {}),
        createdAt: {
          between: {
            start: moment(searchParams?.fromDate, DATE_FORMAT).startOf("day").toISOString(),
            end: moment(searchParams?.toDate, DATE_FORMAT).endOf("day").toISOString()
          }
        }
      }
    }
  })

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Countries Activation summary</h3>
      <div className={classNames(
        "mt-5 overflow-hidden rounded-lg bg-white shadow"
      )}
      style={{
        height: `${( data?.summary?.length || 8) * 4 * 12}px`
      }}
      >

        <Heatmap
          data={data?.summary || []}
        />
      </div>
    </div>
  )
}

export default SignupHeatmapContainer