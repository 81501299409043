import { FC } from "react"
import { wrapClick } from "utils";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import numeral from "numeral";
import _ from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";

interface SummaryPageProps {
  handlePrevious: () => void;
  handleStep: (step: number) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitLoading: boolean;
  values: {
    tariffInstanceInfo: {
      code: string;
      name: string;
      startDate: string;
    },
    chargeInfo: {
      energyCharge: {
        type: string;
        value: number;
        steps: {
          name: string;
          displayName: string;
          minimumConsumption: number;
          maximumConsumption: number;
          value: number;
          exclusive: boolean;
        }[];
      };
      serviceCharge: {
        type: string;
        value: number;
        steps: {
          name: string;
          displayName: string;
          minimumConsumption: number;
          maximumConsumption: number;
          value: number;
          exclusive: boolean;
        }[];
      };
    };
    extraItemsInfo: {
      hasExtraItems: boolean;
      extraItems: {
        name: string;
        displayName: string;
        category: string;
        type: string;
        appliedTo: string;
        value: number;
      }[];
    }
  };
}


const summaries = [
  {
    name: "Instance Info",
    description: "Information about transaction.",
    accessor: "tariffInstanceInfo",
    href: 0,
    Component: ({ data }: any) => (
      <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
        <div>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.code || "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.name || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Start Date
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.startDate || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    name: "Charge Info",
    description: "Information about wallet to be credited.",
    accessor: "chargeInfo",
    href: 1,
    Component: ({ data }: any) => (
      <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">

        <div className="">
          <div className="grid grid-cols-3 gap-6">

            <div>
              <span className="block text-sm font-light text-gray-700">
                Energy Charge Type
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {_.startCase(data?.energyCharge?.type || "") || "N/A"}
              </div>
            </div>

            {data?.energyCharge?.type === "FixedRate" && (
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Charge Value (GHS)
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(data?.energyCharge?.value || 0).format("0,0.0000")}
                </div>
              </div>
            )}

            {data?.energyCharge?.type === "SteppedRate" && (
              <div className="col-start-1 col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Energy Charge Value
                </span>
                <div className="mt-1 w-full ">
                  <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-24"
                        >
                          Alias
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                        >
                          Min (KWh)
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                        >
                          Max (KWh)
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right w-28"
                        >
                          Price (GHS)
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center w-24"
                        >
                          Exclusive
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data?.energyCharge?.steps?.map((step: any, idx: string) => (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.name || "N/A"}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.displayName || "N/A"}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.minimumConsumption || 0).format("0,0")}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{step?.maximumConsumption >= Number.MAX_SAFE_INTEGER ? <>&infin;</> : numeral(step?.maximumConsumption).format("0,0")}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.value || 0).format("0,0.0000")}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-center">{step?.exclusive ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="col-start-1">
              <span className="block text-sm font-light text-gray-700">
                Service Charge Type
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {_.startCase(data?.serviceCharge?.type || "") || "N/A"}
              </div>
            </div>

            {data?.serviceCharge?.type === "FixedRate" && (
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Service Charge Value (GHS)
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(data?.serviceCharge?.value || 0).format("0,0.0000")}
                </div>
              </div>
            )}

            {data?.serviceCharge?.type === "SteppedRate" && (
              <div className="col-start-1 col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Service Charge Value
                </span>
                <div className="mt-1 w-full ">
                  <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-24"
                        >
                          Alias
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                        >
                          Min (KWh)
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900 w-24"
                        >
                          Max (KWh)
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right w-28"
                        >
                          Price (GHS)
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-center w-24"
                        >
                          Exclusive
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data?.serviceCharge?.steps?.map((step: any, idx: string) => (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.name || "N/A"}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.displayName || "N/A"}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.minimumConsumption || 0).format("0,0")}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{step?.maximumConsumption >= Number.MAX_SAFE_INTEGER ? <>&infin;</> : numeral(step?.maximumConsumption).format("0,0")}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.value || 0).format("0,0.0000")}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-center">{step?.exclusive ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  },
  {
    name: "Extra Items Info",
    description: "Information about wallet to be credited.",
    accessor: "extraItemsInfo",
    href: 2,
    Component: ({ data }: any) => (
      <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">

        <div className="">
          <div className="grid grid-cols-3 gap-6">

            <div>
              <span className="block text-sm font-light text-gray-700">
                Has Extra Items ?
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.hasExtraItems ? "Yes" : "No"}
              </div>
            </div>

            {data?.hasExtraItems && (
              <div className="col-start-1 col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Extra Tariff Items
                </span>
                <div className="mt-1 w-full ">
                  <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Alias
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Category
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-left"
                        >
                          Applied To
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                        >
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data?.extraItems?.map((step: any, idx: string) => (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.name || "N/A"}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{step.displayName || "N/A"}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{step.category || "N/A"}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{_.startCase(step.type) || "N/A"}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{_.startCase(step.appliedTo) || "N/A"}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(step?.value || 0).format("0,0.0000")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    )
  }
]


const SummaryPage: FC<SummaryPageProps> = ({ values, handlePrevious, handleStep, submitLoading, ...form }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto">
        {summaries.map(({ Component, ...summary }, idx) => (
          <Disclosure as="div" defaultOpen={true} key={idx} className={idx === 0 ? " p-4" : " p-4"}>
            {({ open }) => (
              <>
                <Disclosure.Button as="div" className="flex justify-between items-center cursor-pointer">
                  <div>
                    <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">{summary.name}</h3>
                    <p className="mt-1 text-xs text-gray-500">{summary.description}</p>
                  </div>
                  <div>
                    {open ? (
                      <ChevronUpIcon
                        className="h-6 w-6 text-gray-400 dark:text-gray-500"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="h-6 w-6 text-gray-400 dark:text-gray-500"
                      />
                    )
                    }
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel as="div" className="mt-6">
                  <Component data={(values as any)[summary.accessor]} config={currentConfig} />
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => handleStep(summary.href)}
                        className="ml-3 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-600 border-gray-600 hover:border-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        <PencilSquareIcon className="-ml-0.5 mr-1.5 h-4 w-4" aria-hidden="true" />
                        Edit
                      </button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          disabled={submitLoading}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.handleCancel)}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default SummaryPage