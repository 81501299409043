import { gql, useQuery } from "@apollo/client";

const GET_USER_ROLES = gql`
  query GetUserRoles(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    rows: getUserRoles(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      name
      description
      type
      status
      expiresAt
      meta {
        permissionsCount
        applicationsCount
        usersCount
      }
      createdAt
      updatedAt
    }
    count: getUserRolesCount(search: $search, searchFields: $searchFields)
  }
`;

export const useUserRoles = (variables: any) => {
  const { data, ...rest } = useQuery(GET_USER_ROLES, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const roles = data?.rows || [];
  const count = data?.count || 0;
  return { roles, count, ...rest };
};
