import { gql, useMutation } from "@apollo/client";
import { Modal, UserTeamForm } from "components";
import { IUserTeamFormData } from "components/forms/user-team/schema";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { wrapClick } from "utils";
import lodash from "lodash";

const CREATE_USER_TEAM = gql`
  mutation CreateUserTeam(
    $name: String!
    $region: ID!
    $district: ID!
    $leader: ID!
    $members: [ID!]!
  ) {
    createUserTeam(
      name: $name
      region: $region
      district: $district
      leader: $leader
      members: $members
    ) {
      _id
    }
  }
`;

export default function CreateUserTeamContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createUserTeam, { loading }] = useMutation(CREATE_USER_TEAM);

  const form = useFormik<IUserTeamFormData>({
    initialValues: {
      teamInfo: {
        name: "",
        region: null,
        district: null,
        leader: null
      },
      teamMembersInfo: {
        members: [],
      },
    },
    onSubmit: async (values) => {
      await createUserTeam({
        variables: {
          name: values.teamInfo.name,
          region: values.teamInfo.region?._id,
          district: values.teamInfo.district?._id,
          leader: values.teamInfo.leader?._id,
          members: lodash.map(values.teamMembersInfo.members, "_id"),
        },
      }).then(({ data }) => {
        if (data.createUserTeam._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Team Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create User Team",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Add New User Team'
      description='Provide the details to add a new User Team'
      hideActions={true}
      size='4xl'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding User Team..." : "Add User Team"}
          </button>
        </>
      )}
    >
      <UserTeamForm form={form}/>
    </Modal>
  );
}


