import { gql, useLazyQuery } from "@apollo/client";
import { PlusIcon, UserPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Avatar, SelectInput, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import lodash from "lodash";

interface MembersInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  validationSchema?: any;
  values: any;
  handleCancel: () => void;
}
const GET_USERS = gql`
  query GetAssignees(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
  ) {
    getUsers(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
    ) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      role {
        _id
        code
        name
      }
      createdAt
      updatedAt
    }
  }
`;

function Owner({
  owner,
  onClick,
  type,
}: {
  owner: any;
  onClick: any;
  type: "add" | "remove";
}) {
  return (
    <div
      className={classNames(
        "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
      <div className="flex-shrink-0">
        <button
          type="button"
          onClick={onClick}
          className={classNames(
            type === "add"
              ? "bg-white hover:bg-green-100 text-gray-400 hover:text-green-500"
              : "bg-white hover:bg-red-100 text-gray-400 hover:text-red-500",
            "w-8 h-8  dark:bg-gray-900 dark:hover:bg-gray-900 inline-flex items-center justify-center dark:text-gray-400 rounded-full bg-transparent dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          )}
        >
          <span className="sr-only">View member details</span>
          {type === "add" ? (
            <PlusIcon className="w-5 h-5" aria-hidden="true" />
          ) : (
            <XMarkIcon className="w-5 h-5" aria-hidden="true" />
          )}
        </button>
      </div>
    </div>
  );
}

const MembersInfoForm: FC<MembersInfoFormProps> = ({
  initialValues,
  validationSchema,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const removeItem = (member: any) => {
    form.setFieldValue("members", [
      ...lodash
        .get(form.values, "members")
        ?.filter((val: any, _id: number) => val._id !== member._id),
    ]);
  };

  const addItem = (member: any) => {
    form.setFieldValue(
      "members",
      lodash.uniq([...lodash.get(form.values, "members"), member])
    );
  };

  const [getUsers, { loading, data }] = useLazyQuery(GET_USERS);

  const searchForm = useFormik({
    initialValues: {
      userIdentifier: "fullName",
      emailAddress: "",
      fullName: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      code: "",
    },
    onSubmit: (values) => {
      console.log(
        values.userIdentifier === "fullName"
          ? ["lastName", "firstName"]
          : [values.userIdentifier]
      );
      getUsers({
        variables: {
          page: 1,
          pageSize: 9,
          search: (values as any)[values.userIdentifier],
          searchFields:
            values.userIdentifier === "fullName"
              ? ["lastName", "firstName"]
              : [values.userIdentifier],
        },
      });
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden min-h-[65vh]"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div className="space-y-4 divide-y divide-gray-200 flex-1 overflow-y-auto">
          <div>
            <span className="text-xs font-light">Team Members Details</span>
            {form?.values?.members?.length ? (
              <div className="h-[16rem] mt-2 rounded-lg border-2 border-dashed border-gray-300 p-4 overflow-y-auto">
                <div className="grid grid-cols-3 gap-6">
                  {form?.values?.members?.map?.((user: any) => (
                    <Owner
                      key={user._id}
                      onClick={wrapClick(() => removeItem(user))}
                      owner={user}
                      type="remove"
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="min-h-[16rem] flex mt-2 relative flex-col w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center items-center justify-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                <UserPlusIcon
                  className="mx-auto h-10 w-10 text-gray-400"
                  stroke="currentColor"
                  strokeWidth={1}
                  aria-hidden="true"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No members added
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by searching for an staff below.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-4 divide-y divide-gray-200 flex-1 overflow-y-auto">
          <div className="pt-4">
            <span className="text-xs font-light">Search Member</span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-3 sm:col-span-2">
                <SelectInput
                  id="userIdentifier"
                  label="Find Staff By"
                  options={[
                    { label: "Email Address", value: "emailAddress" },
                    { label: "Full Name", value: "fullName" },
                    { label: "Ghana Card Number", value: "ghanaCardNumber" },
                    { label: "Phone Number", value: "phoneNumber" },
                    { label: "Staff Code", value: "code" },
                  ]}
                  placeholder="e.g. Mensah"
                  {...searchForm}
                />
              </div>

              {searchForm.values?.userIdentifier === "fullName" && (
                <div className="col-span-6 sm:col-span-3">
                  <TextInput
                    id="fullName"
                    label="Full Name (Last Name, Other Names)"
                    type="text"
                    placeholder="e.g. Mensah John"
                    {...searchForm}
                  />
                </div>
              )}

              {searchForm.values?.userIdentifier === "emailAddress" && (
                <div className="col-span-6 sm:col-span-3">
                  <TextInput
                    id="emailAddress"
                    label="Email Address"
                    type="email"
                    placeholder="e.g. user@ecg.com.gh"
                    {...searchForm}
                  />
                </div>
              )}

              {searchForm.values?.userIdentifier === "phoneNumber" && (
                <div className="col-span-6 sm:col-span-3">
                  <TextInput
                    id="phoneNumber"
                    label="Phone Number"
                    type="text"
                    placeholder="e.g. 0200000000"
                    {...searchForm}
                  />
                </div>
              )}

              {searchForm.values?.userIdentifier === "ghanaCardNumber" && (
                <div className="col-span-6 sm:col-span-3">
                  <TextInput
                    id="ghanaCardNumber"
                    label="Ghana Card Number"
                    type="text"
                    placeholder="e.g. GHA-725667795-8"
                    {...searchForm}
                  />
                </div>
              )}

              {searchForm.values?.userIdentifier === "code" && (
                <div className="col-span-6 sm:col-span-3">
                  <TextInput
                    id="code"
                    label="Staff Code"
                    type="text"
                    placeholder="e.g. MR12345678"
                    {...searchForm}
                  />
                </div>
              )}
              <div className="col-span-6 sm:col-span-1 items-end justify-end flex">
                <button
                  onClick={wrapClick(searchForm.handleSubmit)}
                  className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {loading ? "Searching..." : "Search"}
                </button>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-6 mt-6 pt-6 px-1 border-t border-gray-200">
              {lodash
                .chain(data?.getUsers)
                .differenceBy(form.values?.members, "_id")
                ?.map?.((user: any) => (
                  <Owner
                    key={user._id}
                    onClick={wrapClick(() => addItem(user))}
                    owner={user}
                    type="add"
                  />
                ))
                .value()}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default MembersInfoForm;
