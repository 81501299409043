import { gql, useQuery } from "@apollo/client";

const GET_DISTRICTS = gql`
  query GetDistricts(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
  ) {
    rows: getDistricts(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
    ) {
      _id
      code
      name
      region {
        _id
        code
        name
      }
      meta {
        blocksCount
        customersCount
      }
      createdAt
      updatedAt
    }
    count: getDistrictsCount(
      search: $search
      searchFields: $searchFields
      region: $region
    )
  }
`;

export const useDistricts = (variables: any) => {
  const { data, ...rest } = useQuery(GET_DISTRICTS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const districts = data?.rows || [];
  const count = data?.count || 0;
  return { districts, count, ...rest };
};
