import { gql, useQuery } from "@apollo/client";
import { SearchSelectInput, TextInput } from "components/core";
import { DistrictPicker } from "containers";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

const GET_METER_CONTRACTORS = gql`
  query GetMeterContractors($district: ID) {
    getMeterContractors(district: $district) {
      _id
      code
      name
      createdAt
      updatedAt
    }
  }
`;
interface ContractorFormProps {
  update?: boolean;
  form: FormikProps<{
    name: string;
    // code: string;
    address: string;
    district: any;
    ghanaPostAddress: string;
    contactPerson: {
      fullName: string;
      emailAddress: string;
      phoneNumber: string;
    };
    parentContractor: string;
  }>;
}

const ContractorForm: FC<ContractorFormProps> = ({ form, update = false }) => {
  const {
    data: meterContractorsData,
    loading: meterContractorsLoading,
    refetch: refetchMeterContractors,
  } = useQuery(GET_METER_CONTRACTORS, {
    variables: {
      district: form?.values?.district,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Contractor Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <DistrictPicker
              id='district'
              label='District'
              placeholder='e.g. Kwabenya District'
              required={true}
              disabled={update}
              {...form}
              setFieldValue={(field: string, value: any) => {
                form.setFieldValue(field, value);
              }}
              rawId={true}
            // position={"top"}
            />
          </div>
          <div className=''>
            <TextInput
              id='name'
              label='Contractor Name'
              required={true}
              type='text'
              placeholder='e.g. Accra West Contractor'
              {...form}
            />
          </div>
          <div>
            <TextInput
              id='ghanaPostAddress'
              label='GhanaPost Address'
              required={true}
              type='text'
              placeholder='e.g. AE-0223-1090'
              {...form}
            />
          </div>

          <div className='space-y-1'>
            <TextInput
              id='address'
              label='Office Address'
              required={true}
              type='text'
              placeholder='No. 13 Anunte Drive, Adenta Housing Down'
              {...form}
            />
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contact Person</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <TextInput
              id='contactPerson.fullName'
              label='Full Name'
              type='text'
              placeholder='e.g. Solomon Nana Kweku Bekoe'
              required={true}
              {...form}
            />
          </div>

          <div className='space-y-1'>
            <TextInput
              id='contactPerson.phoneNumber'
              label='Phone Number'
              type='text'
              required
              placeholder='e.g. 0244923099'
              {...form}
            />
          </div>

          <div className='space-y-1 col-span-2'>
            <TextInput
              id='contactPerson.emailAddress'
              label='Email Address'
              type='text'
              placeholder='e.g. s.bekoe@ecg.com.gh'
              {...form}
            />
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Parent Contractor</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div className='space-y-1 col-span-2'>
            <SearchSelectInput
              disabled={_.isEmpty(form?.values?.district)}
              optionsLoading={meterContractorsLoading}
              id='parentContractor'
              label=''
              options={
                meterContractorsData?.getMeterContractors
                  ? [
                    ...meterContractorsData?.getMeterContractors?.map(
                      (item: any) => ({
                        label: { title: item.name },
                        value: item._id,
                      })
                    ),
                  ]
                  : []
              }
              placeholder='Select Parent Contractor'
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorForm;
