import { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from 'utils';
import {
  gql,
  useLazyQuery,
  useMutation,
  // useReactiveVar
} from '@apollo/client';
// import { currentConfigVar } from 'apollo/cache/config';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, TariffInstanceForm } from 'components';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import moment from 'moment';

const GET_TARIFF_INSTANCE = gql`
  query GetTariffInstance($id: ID!) {
    tariffInstance: getTariffInstance(id: $id) {
      _id
      code
      name
      tariffClass {
        _id
      }
      energyCharge {
        type
        value
        steps {
          name
          displayName
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      serviceCharge {
        type
        value
        steps {
          name
          displayName
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      hasExtraItems
      extraItems {
        name
        displayName
        category
        type
        appliedTo
        value
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`

const CONFIGURE_TARIFF = gql`
  mutation CreateTariffInstance(
    $code: String!
    $name: String!
    $tariffClass: ID!
    $energyCharge: TariffChargeInput!
    $serviceCharge: TariffChargeInput!
    $startDate: DateTime!
    $hasExtraItems: Boolean!
    $extraItems: [TariffItemInput]
  ) {
    createTariffInstance(
      code: $code
      name: $name
      tariffClass: $tariffClass
      energyCharge: $energyCharge
      serviceCharge: $serviceCharge
      startDate: $startDate
      hasExtraItems: $hasExtraItems
      extraItems: $extraItems
    ) {
      _id
    }
  }
`

export default function ConfigureTariffInstanceContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  // const theme = useReactiveVar(currentConfigVar);

  const cancelButtonRef = useRef(null);
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const [getTariffInstance] = useLazyQuery(GET_TARIFF_INSTANCE);
  const [configureTariffClass] = useMutation(CONFIGURE_TARIFF)

  const form = useFormik({
    initialValues: {
      tariffInstanceInfo: {
        code: "",
        name: "",
        startDate: moment().format("YYYY-MM-DD"),
      },
      chargeInfo: {
        energyCharge: {
          type: "FixedRate",
          value: 0,
          steps: [] as {
            name: string;
            displayName: string;
            minimumConsumption: number;
            maximumConsumption: number;
            value: number;
            exclusive: boolean;
          }[],
        },
        serviceCharge: {
          type: "FixedRate",
          value: 0,
          steps: [] as {
            name: string;
            displayName: string;
            minimumConsumption: number;
            maximumConsumption: number;
            value: number;
            exclusive: boolean;
          }[],
        }
      },
      extraItemsInfo: {
        hasExtraItems: false,
        extraItems: [] as {
          name: string;
          displayName: string;
          category: string;
          type: string;
          appliedTo: string;
          value: number;
        }[],
      },
    },
    onSubmit: async (values) => {
      await configureTariffClass({
        variables: {
          ...values.tariffInstanceInfo,
          ...values.chargeInfo,
          ...values.extraItemsInfo,
          tariffClass: params.tariffClass,
        }
      }).then(({ data }) => {
        if (data.createTariffInstance._id) {
          toast(JSON.stringify({ type: "success", title: "Instance Created Successfully" }))
          refetch?.();
          setOpen(false);
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Instance" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    if (searchParams?.id) {
      getTariffInstance({
        variables: {
          id: searchParams.id
        }
      }).then(({ data }) => {
        if (data?.tariffInstance) {
          form.setValues({
            tariffInstanceInfo: {
              code: data?.tariffInstance?.code || "",
              name: data?.tariffInstance?.name ? `${data?.tariffInstance?.name} - Copy` : "",
              startDate: data?.tariffInstance?.startDate || moment().format("YYYY-MM-DD"),
            },
            chargeInfo: {
              energyCharge: {
                type: data?.tariffInstance?.energyCharge?.type || "FixedRate",
                value: data?.tariffInstance?.energyCharge?.value || 0,
                steps: data?.tariffInstance?.energyCharge?.steps?.map(({ __typename, _id, ...step }: any) => step) || [],
              },
              serviceCharge: {
                type: data?.tariffInstance?.serviceCharge?.type || "FixedRate",
                value: data?.tariffInstance?.serviceCharge?.value || 0,
                steps: data?.tariffInstance?.serviceCharge?.steps?.map(({ __typename, _id, ...step }: any) => step) || [],
              },
            },
            extraItemsInfo: {
              hasExtraItems: !!data?.tariffInstance?.hasExtraItems || false,
              extraItems: data?.tariffInstance?.extraItems?.map(({ __typename, _id, ...extraItem }: any) => extraItem) || [],
            }
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.id])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hideActions={true}
      title="Configure Tariff Class"
      description="Provide the details to modify tariff class"
      size='4xl'
    >
      <TariffInstanceForm
        form={form}
      />
    </Modal>
  )
}