import { useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { DistrictForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { DistrictFormProps } from "components/forms/district";
import { districtValidationSchema } from "./create";
import _ from 'lodash'

const GET_DISTRICT = gql`
  query GetDistrict($id: ID!) {
    district: getDistrict(id: $id) {
      _id
      code
      jobCode
      name
      region {
        _id
        name
        code
      }
      cartesianLocation {
        northing
        easting
        zone
      }
      meta {
        usersCount
        blocksCount
        roundsCount
        plotsCount
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_DISTRICT = gql`
  mutation UpdateDistrict(
    $id: ID!
    $code: String!
    $name: String!
    $region: ID!
    $cartesianLocation: CartesianLocationInput
  ) {
    updateDistrict(
      id: $id
      code: $code
      name: $name
      region: $region
      cartesianLocation: $cartesianLocation
    ) {
      _id
    }
  }
`;

export default function UpdateDistrictContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_DISTRICT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateDistrict, { loading }] = useMutation(UPDATE_DISTRICT);

  const form = useFormik<DistrictFormProps["form"]["values"]>({
    initialValues: {
      code: "",
      name: "",
      region: "",
      jobCode: "",
      cartesianLocation: {
        northing: 0,
        easting: 0,
        zone: "",
      },
    },
    validationSchema: districtValidationSchema,
    onSubmit: async (values) => {
      await updateDistrict({
        variables: {
          ...values,
          cartesianLocation: {
            ...values.cartesianLocation,
            ...{ zone: parseInt(values?.cartesianLocation?.zone) },
          },
          id: searchParams.id,
          region: values?.region?._id,
        },
      }).then(({ data }) => {
        if (data.updateDistrict?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "District Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create District",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      code: data?.district?.code.slice(-2) || "",
      name: data?.district?.name || "",
      region: data?.district?.region ? _.pick(data?.district?.region, ["_id", "code", "name"]) : "",
      jobCode: data?.district?.jobCode || "",
      cartesianLocation: {
        northing: data?.district?.cartesianLocation?.northing || 0,
        easting: data?.district?.cartesianLocation?.easting || 0,
        zone: data?.district?.cartesianLocation?.zone?.toString?.() || "",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.district, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Edit District Information'
      description='Provide the details to update district'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving Changes..." : "Save Changes"}
          </button>
        </>
      )}
    >
      {data?.district?._id && <DistrictForm form={form} disabled={true} />}
    </Modal>
  );
}
