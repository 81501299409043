import { SelectInput, TextArea, TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface TariffClassFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    description: string;
    serviceClass: string;
  }>;
}

const TariffClassForm: FC<TariffClassFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Tariff Class Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="eg. 09"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <SelectInput
              id="serviceClass"
              label="Service Class"
              required={true}
              options={[
                { label: "--- Select One ---", value: "" },
                { label: "Residential", value: "Residential" },
                { label: "Industrial", value: "Industrial" },
                { label: "Non-Residential", value: "NonResidential" },
                { label: "Special Load Tariff (SLT)", value: "SpecialLoadTariff" },
              ]}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Residential"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextArea
              id="description"
              label="Description"
              placeholder="eg. Special tariff for the needy"
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TariffClassForm