import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { FC } from 'react'
import { useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import { classNames } from 'utils'
import { Calendar, Line, TimeRange } from '../graphs'

const DATE_FORMAT = "YYYY-MM-DD";

const OVERVIEW_SUMMARY = gql`
query GetUsersActivationHeatmap($filter: GetUsersActivationSummaryFilter) {
  summary: getUsersActivationHeatmap(filter: $filter) {
    date
    total
  }
}
`

const breakPoints: {[key: string]: number} = {
  "12": 0,
  "11": (4 * 6),
  "10": (4 * 9),
  "9": (4 * 14),
  "8": (4 * 22),
  "7": (4 * 30),
  "6": (4 * 41),
  "5": (4 * 44),
  "4": (4 * 52),
  "3": (4 * 48),
  "2": (4 * 48),
  "1": (4 * 48),
}

const SignupTimerangeContainer: FC = () => {

  const searchParams = useSearch<LocationGenerics>()

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      filter: {

        ...((searchParams?.region && searchParams.region !== "world") ? {
          region: {
            eq: searchParams.region
          }
        } : {}),
        createdAt: {
          between: {
            start: moment(searchParams?.fromDate, DATE_FORMAT).startOf("day").toISOString(),
            end: moment(searchParams?.toDate, DATE_FORMAT).endOf("day").toISOString()
          }
        }
      }
    }
  })
  const periodLength =moment(searchParams.toDate, DATE_FORMAT).diff(moment(searchParams.fromDate, DATE_FORMAT), "days")

  if (searchParams.fromDate === searchParams.toDate) return null
  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">New Users Summary Per Day</h3>
      {(periodLength > 365) ? (
      <div className={classNames(
        "mt-5 overflow-hidden rounded-lg bg-white shadow"
      )}
      style={{
        height: `${ _.ceil(periodLength/365) * 4 * 60}px`
      }}
      >
          <Calendar
            data={_.times(periodLength + 1, (idx) => {
              const dateIdx = moment(searchParams.fromDate).add(idx, "days").format(DATE_FORMAT);
              const datum = data?.summary?.find?.((_d: { date: string; total: number }) => dateIdx === _d.date)
              return ({
                "value": datum?.total || 0,
                "day": dateIdx
              })
            })}
            fromDate={searchParams.fromDate as string}
            toDate={searchParams.toDate as string}
          />
        </div>

      ) : ((periodLength > 80) ? (
        <div className={classNames(
          "mt-5 overflow-hidden rounded-lg bg-white shadow"
        )}
        style={{
          height: `${(76 * 4) + breakPoints[_.ceil(periodLength/ 30).toString()]}px`,
          // height: `${(76 * 4) + ((_.ceil(periodLength/ 7) / 52)* 4 * 3)}px`,
        }}
        >
          <TimeRange
            data={_.times(periodLength + 1, (idx) => {
              const dateIdx = moment(searchParams.fromDate).add(idx, "days").format(DATE_FORMAT);
              const datum = data?.summary?.find?.((_d: { date: string; total: number }) => dateIdx === _d.date)
              return ({
                "value": datum?.total || 0,
                "day": dateIdx
              })
            })}
            fromDate={searchParams.fromDate as string}
            toDate={searchParams.toDate as string}
          />
        </div>
      ) : (
        <div className="mt-5 overflow-hidden rounded-lg bg-white shadow xl:h-96">
          <Line
            data={_.times(periodLength + 1, (idx) => {
              const dateIdx = moment(searchParams.fromDate).add(idx, "days").format(DATE_FORMAT);
              const datum = data?.summary?.find?.((_d: { date: string; total: number }) => dateIdx === _d.date)
              return ({
                "value": datum?.total || 0,
                "day": dateIdx
              })
            })}
            fromDate={searchParams.fromDate as string}
            toDate={searchParams.toDate as string}
          />
        </div>
      ))}
    </div>
  )
}

export default SignupTimerangeContainer