import { validators, wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, RegionForm } from "components";
import * as Yup from "yup";

const CREATE_REGION = gql`
  mutation CreateRegion(
    $code: String!
    $name: String!
    $cartesianLocation: CartesianLocationInput
  ) {
    createRegion(
      code: $code
      name: $name
      cartesianLocation: $cartesianLocation
    ) {
      _id
    }
  }
`;

export const regionValidationSchema = Yup.object().shape({
  code: Yup.string()
    .test("len", "Must be exactly 2 characters", (val) => val?.length === 2)
    // all characters must be digits and non-zeros
    .matches(/^[0-9][0-9]*$/, "Kindly enter a valid region code") // formerly, "10" was invalid
    .required("Region code is required"),
  name: Yup.string().required("Region name is required"),
  cartesianLocation: Yup.object({
    northing: Yup.number().required("Northing is required"),
    easting: Yup.number().required("Easting is required"),
    zone: Yup.string().required("Zone is required"),
  })
});

export default function CreateRegionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createRegion, { loading }] = useMutation(CREATE_REGION);

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      cartesianLocation: {
        northing: 0,
        easting: 0,
        zone: "",
      },
    },
    validationSchema: regionValidationSchema,
    onSubmit: async (values) => {
      await createRegion({
        variables: {
          ...values,
          cartesianLocation: {
            ...values.cartesianLocation,
            ...(values?.cartesianLocation?.zone
              ? { zone: parseInt(values?.cartesianLocation?.zone) }
              : {}),
          },
        },
      }).then(({ data }) => {
        if (data.createRegion._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Region Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not create Region" })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Add New Region'
      description='Provide the details to add a new region'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Region..." : "Add Region"}
          </button>
        </>
      )}
    >
      <RegionForm form={form} />
    </Modal>
  );
}
