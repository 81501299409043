import { gql, useQuery } from "@apollo/client";
import { Header, Shimmers, TableComponent } from "components";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, withPermissions, useTableData } from "utils";
import config from "config";
import { Avatar, SelectInput } from "components/core";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreateRegionContainer from "./create";
import UpdateRegionContainer from "./update";
import ViewRegionContainer from "./view";
import { ExportDataContainer, ImportDataContainer } from "containers";
import ActionButton, { Action } from "components/buttons/action-button";
import { useRegions } from "./hooks";

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 2,
  },
  {
    label: "Name",
    value: "name",
    min: 4,
  },
];

const RegionsPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
    }),
    [searchParams]
  );

  const { regions, count, loading, networkStatus, refetch } =
    useRegions(filter);
  const records = useTableData({ rows: regions, count: count || 0 });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "code" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "regions:*", "regions:create"])(
              <button
                type='button'
                onClick={wrapClick(() => setModal("create"))}
                className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Add New Region
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"regions"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Region
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Districts | Blocks
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Properties | Customers
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.name}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.code}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {numeral(item?.meta?.districtsCount || 0).format("0,0")}{" "}
                    districts
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {numeral(item?.meta?.blocksCount || 0).format("0,0")} blocks
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {numeral(item?.meta?.propertiesCount || 0).format("0,0")}{" "}
                    properties
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {numeral(item?.meta?.customersCount || 0).format("0,0")}{" "}
                    customers
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["*:*", "regions:*", "regions:update"])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(["*:*", "regions:*", "regions:create"])(
        <CreateRegionContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {withPermissions(["*:*", "regions:*", "regions:import"])(
        <ImportDataContainer
          open={modal === "import"}
          setOpen={(val: boolean) => setModal(val ? "import" : undefined)}
          entityType={"Region"}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "regions:*", "regions:update"])(
            <UpdateRegionContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewRegionContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default RegionsPage;
