import { gql, useQuery } from "@apollo/client";

const GET_COMPLAINT_TYPES = gql`
 query GetComplaintTypes(
  $page: Int
  $category: ComplaintCategory
  $categories: [ComplaintCategory]
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
) {
  rows: getComplaintTypes(
    categories: $categories
    category: $category
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
  ) {
    _id
    code
    name
    description
    userRole {
      name
    }
    category
    createdAt
    updatedAt
  }
  count: getComplaintTypesCount(
    search: $search
    searchFields: $searchFields
    categories: $categories
    category: $category
  )
}

`;

export const useComplaintTypes = (variables: any) => {
  const { data, ...rest } = useQuery(GET_COMPLAINT_TYPES, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const complaintsTypes = data?.rows || [];
  const count = data?.count || 0;
  return { complaintsTypes, count, ...rest };
};
