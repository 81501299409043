import { gql } from "@apollo/client";
import { Header, Shimmers, TableComponent } from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  withPermissions,
  wrapOnchange,
  useTableData,
} from "utils";
import config from "config";
import { PlusIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import CreateUserContainer from "./create";
import UpdateUserContainer from "./update";
import ViewUserContainer from "./view";
import { DistrictPicker, RegionPicker, UserRolePicker } from "containers";
import { Avatar, SelectInput } from "components/core";
import ActionButton, { Action } from "components/buttons/action-button";
import { useUsers } from "./hooks";

const searchOptions = [
  {
    label: "Staff ID",
    value: "code",
    min: 4,
  },
  {
    label: "First Name",
    value: "firstName",
    min: 3,
  },
  {
    label: "Last Name",
    value: "lastName",
    min: 3,
  },
  {
    label: "Email Address",
    value: "emailAddress",
    min: 5,
  },
  {
    label: "Phone Number",
    value: "phoneNumber",
    min: 5,
  },
  {
    label: "Ghana Card Number",
    value: "ghanaCardNumber",
    min: 4,
  },
];
const UsersPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [role, setRole] = useUrlState("role");
  const [district, setDistrict] = useUrlState("district");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      role: searchParams.role || undefined,
    }),
    [searchParams]
  );

  const { users, count, loading, refetch, networkStatus } = useUsers(filter);

  const records = useTableData({ rows: users, count });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "users:*", "users:import"])(
              <button
                type='button'
                className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <ArrowUpTrayIcon
                  className='-ml-0.5 mr-2 h-4 w-4'
                  aria-hidden='true'
                />
                Import
              </button>
            )}
            {withPermissions(["*:*", "users:*", "users:create"])(
              <button
                type='button'
                onClick={wrapClick(() => setModal("create"))}
                className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Add New User
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"users"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            hasSearch={true}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2'>
                <UserRolePicker
                  id='role'
                  labelHidden={true}
                  setFieldValue={(_field: string, value: string) => {
                    setRole(value);
                  }}
                  values={{ role }}
                  rawId={true}
                />
                <RegionPicker
                  id='region'
                  labelHidden={true}
                  setFieldValue={(_field: string, value: string) => {
                    setRegion(value);
                    setDistrict(undefined);
                  }}
                  values={{ region }}
                  rawId={true}
                />
                {region && (
                  <DistrictPicker
                    id='district'
                    labelHidden={true}
                    setFieldValue={(_field: string, value: string) =>
                      setDistrict(value)
                    }
                    values={{ district }}
                    filter={{ region }}
                    rawId={true}
                  />
                )}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  User
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Contact
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Ghana Card | Gender
                </th>
                {!region && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Region
                  </th>
                )}
                {!district && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    District
                  </th>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Role
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                {!region && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {!district && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.lastName || "")?.trim(),
                            (item?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {[
                          (item?.lastName || "")?.trim(),
                          (item?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.phoneNumber || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.emailAddress || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.ghanaCardNumber || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.gender || "N/A"}
                  </div>
                </td>
                {!region && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.regionName || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.regionCode || "N/A"}
                    </div>
                  </td>
                )}
                {!district && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.districtName || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.districtCode || "N/A"}
                    </div>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      item?.roleName === "SuperAdmin"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      item?.roleName === "Admin"
                        ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                        : "",
                      item?.roleName === "Supervisor"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      item?.roleName === "Reader"
                        ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                        : "",
                      item?.roleName === "Queued"
                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      item?.roleName === "Success"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      item?.roleName === "Reversed"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.roleName
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.roleName || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["*:*", "users:*", "users:update"])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                    {withPermissions(["*:*", "users:*", "users:delete"])(
                      <ActionButton
                        action='delete'
                        onClick={dispatchAction(item?._id, "delete")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(["*:*", "users:*", "users:create"])(
        <CreateUserContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "users:*", "users:update"])(
            <UpdateUserContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewUserContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </main>
  );
};

export default UsersPage;
