import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, TariffClassForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { createTariffClassValidationSchema } from './create';

const GET_TARIFF_CLASS = gql`
  query GetTariffClass($id: ID!) {
    tariffClass: getTariffClass(id: $id) {
      _id
      code
      name
      description
      serviceClass
      createdAt
      updatedAt
    }
  }
`

const UPDATE_TARIFF_CLASS = gql`
  mutation UpdateTariffClass(
    $id: ID!
    $code: String!
    $name: String!
    $description: String
    $serviceClass: ServiceClass!
  ) {
    updateTariffClass(
      id: $id
      code: $code
      name: $name
      description: $description
      serviceClass: $serviceClass
    ) {
      _id
    }
  }
`

export default function UpdateTariffClassContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetching } = useQuery(GET_TARIFF_CLASS, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [updateTariffClass, { loading }] = useMutation(UPDATE_TARIFF_CLASS)

  const form = useFormik({
    initialValues: {
      code: "",
      description: "",
      name: "",
      serviceClass: "",
    },
    validationSchema: createTariffClassValidationSchema,
    onSubmit: async (values) => {
      await updateTariffClass({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.updateTariffClass._id) {
          toast(JSON.stringify({ type: "success", title: "Tariff Class Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Tariff Class" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      code: data?.tariffClass?.code || "",
      description: data?.tariffClass?.description || "",
      name: data?.tariffClass?.name || "",
      serviceClass: data?.tariffClass?.serviceClass || "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.tariffClass, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetching}
      title="Edit Tariff Class Information"
      description="Provide the details to update tariff class"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Tariff Class..." : "Edit Tariff Class"}
          </button>

        </>
      )}>
      {data?.tariffClass?._id && (
        <TariffClassForm
          form={form}
        />
      )}
    </Modal>
  )
}