import { gql, useMutation, useQuery } from "@apollo/client";
import { Modal, UserTeamForm } from "components";
import { IUserTeamFormData } from "components/forms/user-team/schema";
import { useFormik } from "formik";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick } from "utils";
import lodash from "lodash";

const GET_USER_TEAM = gql`
  query GetUserTeam($id: ID!) {
    userTeam: getUserTeam(id: $id) {
      _id
      code
      name
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      leader {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        role {
          _id
          code
          name
        }
        createdAt
        updatedAt
      }
      members {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        role {
          _id
          code
          name
        }
        createdAt
        updatedAt
      }
      meta {
        usersCount
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_USER_TEAM = gql`
  mutation UpdateUserTeam(
    $id: ID!
    $name: String
    $region: ID
    $district: ID
    $leader: ID
    $members: [ID]
  ) {
    updateUserTeam(
      id: $id
      name: $name
      region: $region
      district: $district
      leader: $leader
      members: $members
    ) {
      _id
    }
  }
`;

export default function UpdateUserTeamContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetchingUserTeam } = useQuery(GET_USER_TEAM, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateUserTeam, { loading }] = useMutation(UPDATE_USER_TEAM);

  const form = useFormik<IUserTeamFormData>({
    initialValues: {
      teamInfo: {
        name: "",
        region: null,
        district: null,
        leader: null,
      },
      teamMembersInfo: {
        members: [],
      },
    },
    onSubmit: async (values) => {
      await updateUserTeam({
        variables: {
          id: searchParams.id,
          name: values.teamInfo.name,
          region: values.teamInfo.region?._id,
          district: values.teamInfo.district?._id,
          leader: values.teamInfo.leader?._id,
          members: lodash.map(values.teamMembersInfo.members, "_id"),
        },
      }).then(({ data }) => {
        if (data.updateUserTeam._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Team Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create User Team",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      teamInfo: {
        name: data?.userTeam?.name || "",
        region: lodash.omit(data?.userTeam?.region, ["__typename"]) as any || null,
        district: lodash.omit(data?.userTeam?.district, ["__typename"]) as any || null,
        leader: data?.userTeam?.leader || null,
      },
      teamMembersInfo: {
        members: data?.userTeam?.members || [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.userTeam, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchingUserTeam}
      title="Edit User Team Information"
      description="Provide the details to update user team"
      hideActions={true}
      size="4xl"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing User Team..." : "Edit User Team"}
          </button>
        </>
      )}
    >
      {data?.userTeam?._id && <UserTeamForm form={form} />}
    </Modal>
  );
}
