import { TextInput } from "components/core";
import { DistrictPicker } from "containers";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

interface MeterContractorFormProps {
  form: FormikProps<{
    name: string;
    address: string;
    districts: string[];
    ghanaPostAddress: string;
    contactPerson: {
      fullName: string;
      emailAddress: string;
      phoneNumber: string;
    };
  }>;
}

const MeterContractorForm: FC<MeterContractorFormProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Contractor Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="col-span-2">
            <TextInput
              id="name"
              label="Contractor Name"
              type="text"
              placeholder="e.g. Accra West Contractor"
              required
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="ghanaPostAddress"
              label="GhanaPost Address"
              type="text"
              placeholder="e.g. AE-0223-1090"
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="address"
              label="Office Address"
              type="text"
              placeholder="No. 13 Anunte Drive, Adenta Housing Down"
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Person</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <TextInput
              id="contactPerson.fullName"
              label="Full Name"
              type="text"
              placeholder="e.g. Solomon Nana Kweku Bekoe"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="contactPerson.phoneNumber"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0244923099"
              required
              {...form}
            />
          </div>

          <div className="space-y-1 col-span-2">
            <TextInput
              id="contactPerson.emailAddress"
              label="Email Address"
              type="text"
              placeholder="e.g. s.bekoe@ecg.com.gh"
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contractor Districts</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          {_.times(form.values.districts?.length + 1, (idx) => (
            <div>
              <DistrictPicker
                id={`districts.[${idx}]`}
                label={`District ${idx + 1}`}
                placeholder="e.g. Kwabenya District"
                required={idx !== form.values.districts.length}
                {...form}
                rawId
                position={"top"}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeterContractorForm;
