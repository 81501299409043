const applications = [
  {
    code: "analytics",
    name: "Business Analytics Console",
    description:
      "The Business Analytics console provides District Managers  of the Electricity Company of Ghana with statistics and insights on the daily or  periodic engagements between their districts and customers. It allows them to monitor all operations within the various departments of the district.",
    url: "https://analytics.ecg.com.gh",
    sections: [
      {
        name: "customers",
        permissions: [
          { name: "", value: "customers:*" },
          { name: "", value: "customers:manage" },
          { name: "", value: "customers:create" },
          { name: "", value: "customers:update" },
          { name: "", value: "customers:import" },
          { name: "", value: "customers:delete" },
        ],
      },
      {
        name: "service-requests",
        permissions: [
          { name: "", value: "service-requests:*" },
          { name: "", value: "service-requests:manage" },
          { name: "", value: "service-requests:create" },
          { name: "", value: "service-requests:update" },
          { name: "", value: "service-requests:import" },
          { name: "", value: "service-requests:delete" },
        ],
      },
      {
        name: "waivers",
        permissions: [
          { name: "", value: "waivers:*" },
          { name: "", value: "waivers:manage" },
          { name: "", value: "waivers:create" },
          { name: "", value: "waivers:update" },
          { name: "", value: "waivers:import" },
          { name: "", value: "waivers:delete" },
        ],
      },
      {
        name: "service-orders",
        permissions: [
          { name: "", value: "service-orders:*" },
          { name: "", value: "service-orders:manage" },
          { name: "", value: "service-orders:create" },
          { name: "", value: "service-orders:update" },
          { name: "", value: "service-orders:import" },
          { name: "", value: "service-orders:delete" },
        ],
      },
      {
        name: "reading-sets",
        permissions: [
          { name: "", value: "reading-sets:*" },
          { name: "", value: "reading-sets:manage" },
          { name: "", value: "reading-sets:create" },
          { name: "", value: "reading-sets:update" },
          { name: "", value: "reading-sets:import" },
          { name: "", value: "reading-sets:delete" },
        ],
      },
      {
        name: "reading-and-billing",
        permissions: [
          { name: "", value: "reading-and-billing:*" },
          { name: "", value: "reading-and-billing:manage" },
          { name: "", value: "reading-and-billing:create" },
          { name: "", value: "reading-and-billing:update" },
          { name: "", value: "reading-and-billing:import" },
          { name: "", value: "reading-and-billing:delete" },
        ],
      },
      {
        name: "polymorph-mms-installations",
        permissions: [
          { name: "", value: "polymorph-mms-installations:*" },
          { name: "", value: "polymorph-mms-installations:manage" },
          { name: "", value: "polymorph-mms-installations:create" },
          { name: "", value: "polymorph-mms-installations:update" },
          { name: "", value: "polymorph-mms-installations:import" },
          { name: "", value: "polymorph-mms-installations:delete" },
        ],
      },
      {
        name: "polymorph-non-mms-installations",
        permissions: [
          { name: "", value: "polymorph-non-mms-installations:*" },
          { name: "", value: "polymorph-non-mms-installations:manage" },
          { name: "", value: "polymorph-non-mms-installations:create" },
          { name: "", value: "polymorph-non-mms-installations:update" },
          { name: "", value: "polymorph-non-mms-installations:import" },
          { name: "", value: "polymorph-non-mms-installations:delete" },
        ],
      },
      {
        name: "polymorph-mms-replacements",
        permissions: [
          { name: "", value: "polymorph-mms-replacements:*" },
          { name: "", value: "polymorph-mms-replacements:manage" },
          { name: "", value: "polymorph-mms-replacements:create" },
          { name: "", value: "polymorph-mms-replacements:update" },
          { name: "", value: "polymorph-mms-replacements:import" },
          { name: "", value: "polymorph-mms-replacements:delete" },
        ],
      },
      {
        name: "polymorph-non-mms-replacements",
        permissions: [
          { name: "", value: "polymorph-non-mms-replacements:*" },
          { name: "", value: "polymorph-non-mms-replacements:manage" },
          { name: "", value: "polymorph-non-mms-replacements:create" },
          { name: "", value: "polymorph-non-mms-replacements:update" },
          { name: "", value: "polymorph-non-mms-replacements:import" },
          { name: "", value: "polymorph-non-mms-replacements:delete" },
        ],
      },
      {
        name: "paid-up-backlog",
        permissions: [
          { name: "", value: "paid-up-backlog:*" },
          { name: "", value: "paid-up-backlog:manage" },
          { name: "", value: "paid-up-backlog:create" },
          { name: "", value: "paid-up-backlog:update" },
          { name: "", value: "paid-up-backlog:import" },
          { name: "", value: "paid-up-backlog:delete" },
        ],
      },
      {
        name: "installation-service-orders",
        permissions: [
          { name: "", value: "installation-service-orders:*" },
          { name: "", value: "installation-service-orders:manage" },
          { name: "", value: "installation-service-orders:create" },
          { name: "", value: "installation-service-orders:update" },
          { name: "", value: "installation-service-orders:import" },
          { name: "", value: "installation-service-orders:delete" },
        ],
      },
      {
        name: "inspection-service-orders",
        permissions: [
          { name: "", value: "inspection-service-orders:*" },
          { name: "", value: "inspection-service-orders:manage" },
          { name: "", value: "inspection-service-orders:create" },
          { name: "", value: "inspection-service-orders:update" },
          { name: "", value: "inspection-service-orders:import" },
          { name: "", value: "inspection-service-orders:delete" },
        ],
      },
      {
        name: "investigation-service-orders",
        permissions: [
          { name: "", value: "investigation-service-orders:*" },
          { name: "", value: "investigation-service-orders:manage" },
          { name: "", value: "investigation-service-orders:create" },
          { name: "", value: "investigation-service-orders:update" },
          { name: "", value: "investigation-service-orders:import" },
          { name: "", value: "investigation-service-orders:delete" },
        ],
      },
      {
        name: "replacement-service-orders",
        permissions: [
          { name: "", value: "replacement-service-orders:*" },
          { name: "", value: "replacement-service-orders:manage" },
          { name: "", value: "replacement-service-orders:create" },
          { name: "", value: "replacement-service-orders:update" },
          { name: "", value: "replacement-service-orders:import" },
          { name: "", value: "replacement-service-orders:delete" },
        ],
      },
      {
        name: "regularization",
        permissions: [
          { name: "", value: "regularization:*" },
          { name: "", value: "regularization:manage" },
          { name: "", value: "regularization:create" },
          { name: "", value: "regularization:update" },
          { name: "", value: "regularization:import" },
          { name: "", value: "regularization:delete" },
        ],
      },
      {
        name: "reading-anomalies",
        permissions: [
          { name: "", value: "reading-anomalies:*" },
          { name: "", value: "reading-anomalies:manage" },
          { name: "", value: "reading-anomalies:create" },
          { name: "", value: "reading-anomalies:update" },
          { name: "", value: "reading-anomalies:import" },
          { name: "", value: "reading-anomalies:delete" },
        ],
      },
      {
        name: "customer-acquisition",
        permissions: [
          { name: "", value: "customer-acquisition:*" },
          { name: "", value: "customer-acquisition:manage" },
          { name: "", value: "customer-acquisition:create" },
          { name: "", value: "customer-acquisition:update" },
          { name: "", value: "customer-acquisition:import" },
          { name: "", value: "customer-acquisition:delete" },
        ],
      },
    ],
  },
  {
    code: "debt",
    name: "Debt Management Console",
    description: "Add description here",
    url: "https://debt.ecg.com.gh",
    sections: [
      {
        name: "bills",
        permissions: [
          { name: "", value: "bills:*" },
          { name: "", value: "bills:manage" },
          { name: "", value: "bills:create" },
          { name: "", value: "bills:update" },
          { name: "", value: "bills:import" },
          { name: "", value: "bills:delete" },
          { name: "", value: "bills:send" },
          { name: "", value: "bills:print" },
          { name: "", value: "bills:approve" },
          { name: "", value: "bills:schedule" },
        ],
      },
      {
        name: "debts",
        permissions: [
          { name: "", value: "debts:*" },
          { name: "", value: "debts:manage" },
          { name: "", value: "debts:create" },
          { name: "", value: "debts:update" },
          { name: "", value: "debts:delete" },
          { name: "", value: "debts:send" },
          { name: "", value: "debts:print" },
          { name: "", value: "debts:approve" },
          { name: "", value: "debts:schedule" },
        ],
      },
      {
        name: "payments",
        permissions: [
          { name: "", value: "payments:*" },
          { name: "", value: "payments:manage" },
          { name: "", value: "payments:create" },
          { name: "", value: "payments:update" },
          { name: "", value: "payments:import" },
          { name: "", value: "payments:delete" },
        ],
      },
      {
        name: "disconnection-service-orders",
        permissions: [
          { name: "", value: "disconnection-service-orders:*" },
          { name: "", value: "disconnection-service-orders:manage" },
          { name: "", value: "disconnection-service-orders:create" },
          { name: "", value: "disconnection-service-orders:update" },
          { name: "", value: "disconnection-service-orders:import" },
          { name: "", value: "disconnection-service-orders:delete" },
        ],
      },
      {
        name: "reconnection-service-orders",
        permissions: [
          { name: "", value: "reconnection-service-orders:*" },
          { name: "", value: "reconnection-service-orders:manage" },
          { name: "", value: "reconnection-service-orders:create" },
          { name: "", value: "reconnection-service-orders:update" },
          { name: "", value: "reconnection-service-orders:import" },
          { name: "", value: "reconnection-service-orders:delete" },
        ],
      },
      {
        name: "customers",
        permissions: [
          { name: "", value: "customers:*" },
          { name: "", value: "customers:manage" },
          { name: "", value: "customers:create" },
          { name: "", value: "customers:update" },
          { name: "", value: "customers:import" },
          { name: "", value: "customers:delete" },
        ],
      },
    ],
  },
  {
    code: "revenue",
    name: "Revenue Protection Console",
    description: "Add description here",
    url: "https://revenue.ecg.com.gh",
    sections: [
      {
        name: "fraud-bills",
        permissions: [
          { name: "", value: "fraud-bills:*" },
          { name: "", value: "fraud-bills:manage" },
          { name: "", value: "fraud-bills:create" },
          { name: "", value: "fraud-bills:update" },
          { name: "", value: "fraud-bills:import" },
          { name: "", value: "fraud-bills:delete" },
          { name: "", value: "fraud-bills:send" },
          { name: "", value: "fraud-bills:print" },
        ],
      },
    ],
  },
  {
    code: "prepaid",
    name: "Prepaid Monitoring Console",
    description: "Add description here",
    url: "https://prepaid.ecg.com.gh",
    sections: [
      {
        name: "fraud-bills",
        permissions: [
          { name: "", value: "fraud-bills:*" },
          { name: "", value: "fraud-bills:manage" },
          { name: "", value: "fraud-bills:create" },
          { name: "", value: "fraud-bills:update" },
          { name: "", value: "fraud-bills:import" },
          { name: "", value: "fraud-bills:delete" },
          { name: "", value: "fraud-bills:send" },
          { name: "", value: "fraud-bills:print" },
        ],
      },
    ],
  },
  {
    code: "commercial",
    name: "Commercial Services Console",
    description:
      "The Commercial Services portal serves as the central system for Customer Relations Executives and their assistants to oversee and handle customer interactions.",
    url: "https://commercial.ecg.com.gh",
    sections: [
      {
        name: "payments",
        permissions: [
          { name: "", value: "payments:*" },
          { name: "", value: "payments:manage" },
          { name: "", value: "payments:create" },
          { name: "", value: "payments:update" },
          { name: "", value: "payments:import" },
          { name: "", value: "payments:delete" },
        ],
      },
      {
        name: "customers",
        permissions: [
          { name: "", value: "customers:*" },
          { name: "", value: "customers:manage" },
          { name: "", value: "customers:create" },
          { name: "", value: "customers:update" },
          { name: "", value: "customers:import" },
          { name: "", value: "customers:delete" },
        ],
      },
      {
        name: "service-requests",
        permissions: [
          { name: "", value: "service-requests:*" },
          { name: "", value: "service-requests:manage" },
          { name: "", value: "service-requests:create" },
          { name: "", value: "service-requests:update" },
          { name: "", value: "service-requests:import" },
          { name: "", value: "service-requests:delete" },
        ],
      },
    ],
  },
  {
    code: "slt",
    name: "SLT Services Console",
    description:
      "The SLT Services portal serves as the central system to onboard and manage industrial customers",
    url: "https://slt.subsenterprise.com",
    sections: [
      {
        name: "payments",
        permissions: [
          { name: "", value: "payments:*" },
          { name: "", value: "payments:manage" },
          { name: "", value: "payments:create" },
          { name: "", value: "payments:update" },
          { name: "", value: "payments:import" },
          { name: "", value: "payments:delete" },
        ],
      },
      {
        name: "customers",
        permissions: [
          { name: "", value: "customers:*" },
          { name: "", value: "customers:manage" },
          { name: "", value: "customers:create" },
          { name: "", value: "customers:update" },
          { name: "", value: "customers:import" },
          { name: "", value: "customers:delete" },
        ],
      },
      {
        name: "service-requests",
        permissions: [
          { name: "", value: "service-requests:*" },
          { name: "", value: "service-requests:manage" },
          { name: "", value: "service-requests:create" },
          { name: "", value: "service-requests:update" },
          { name: "", value: "service-requests:import" },
          { name: "", value: "service-requests:delete" },
        ],
      },
    ],
  },
  {
    code: "identity",
    name: "Identity Management Console",
    description:
      "The Identity Management portal acts as a centralized hub for overseeing user access and enhancing security. It facilitates the configuration of user profiles for distinct Electricity Company of Ghana (ECG) consoles.",
    url: "https://identity.ecg.com.gh",
    sections: [
      {
        name: "access-logs",
        permissions: [
          { name: "", value: "access-logs:*" },
          { name: "", value: "access-logs:manage" },
          { name: "", value: "access-logs:create" },
          { name: "", value: "access-logs:update" },
          { name: "", value: "access-logs:import" },
          { name: "", value: "access-logs:delete" },
        ],
      },
      {
        name: "activity-logs",
        permissions: [
          { name: "", value: "activity-logs:*" },
          { name: "", value: "activity-logs:manage" },
          { name: "", value: "activity-logs:create" },
          { name: "", value: "activity-logs:update" },
          { name: "", value: "activity-logs:import" },
          { name: "", value: "activity-logs:delete" },
        ],
      },
      {
        name: "contractors",
        permissions: [
          { name: "", value: "contractors:*" },
          { name: "", value: "contractors:manage" },
          { name: "", value: "contractors:create" },
          { name: "", value: "contractors:update" },
          { name: "", value: "contractors:import" },
        ],
      },

      {
        name: "contractor-users",
        permissions: [
          { name: "", value: "contractor-users:*" },
          { name: "", value: "contractor-users:manage" },
          { name: "", value: "contractor-users:create" },
          { name: "", value: "contractor-users:delete" },
          { name: "", value: "contractor-users:update" },
          { name: "", value: "contractor-users:import" },
        ],
      },
      {
        name: "users",
        permissions: [
          { name: "", value: "users:*" },
          { name: "", value: "users:manage" },
          { name: "", value: "users:create" },
          { name: "", value: "users:update" },
          { name: "", value: "users:import" },
          { name: "", value: "users:delete" },
        ],
      },
      {
        name: "user-roles",
        permissions: [
          { name: "", value: "user-roles:*" },
          { name: "", value: "user-roles:manage" },
          { name: "", value: "user-roles:create" },
          { name: "", value: "user-roles:update" },
          { name: "", value: "user-roles:import" },
          { name: "", value: "user-roles:delete" },
        ],
      },
      {
        name: "tariff-classes",
        permissions: [
          { name: "", value: "tariff-classes:*" },
          { name: "", value: "tariff-classes:manage" },
          { name: "", value: "tariff-classes:create" },
          { name: "", value: "tariff-classes:update" },
          { name: "", value: "tariff-classes:import" },
          { name: "", value: "tariff-classes:delete" },
        ],
      },

      {
        name: "tariff-instances",
        permissions: [
          { name: "", value: "tariff-instances:*" },
          { name: "", value: "tariff-instances:manage" },
          { name: "", value: "tariff-instances:create" },
          { name: "", value: "tariff-instances:update" },
          { name: "", value: "tariff-instances:import" },
          { name: "", value: "tariff-instances:delete" },
          { name: "", value: "tariff-instances:configure" },
        ],
      },
    ],
  },
  {
    code: "billing",
    name: "Spot Billing Console",
    description:
      "The Spot Billing portal serves as the central command center for District Commercial Officers and their assistants. It empowers them to efficiently oversee various tasks, including  meter reading management, anomaly resolution, disconnections, reconnections, debt management, exception handling, and real-time monitoring of meter reader activities.",
    url: "https://spot-billing.ecg.com.gh",
    sections: [
      {
        name: "regions",
        permissions: [
          { name: "", value: "regions:*" },
          { name: "", value: "regions:manage" },
          { name: "", value: "regions:create" },
          { name: "", value: "regions:update" },
          { name: "", value: "regions:import" },
          { name: "", value: "regions:delete" },
        ],
      },
      {
        name: "districts",
        permissions: [
          { name: "", value: "districts:*" },
          { name: "", value: "districts:manage" },
          { name: "", value: "districts:create" },
          { name: "", value: "districts:update" },
          { name: "", value: "districts:import" },
          { name: "", value: "districts:delete" },
        ],
      },
      {
        name: "blocks",
        permissions: [
          { name: "", value: "blocks:*" },
          { name: "", value: "blocks:manage" },
          { name: "", value: "blocks:create" },
          { name: "", value: "blocks:update" },
          { name: "", value: "blocks:import" },
          { name: "", value: "blocks:delete" },
        ],
      },
      {
        name: "rounds",
        permissions: [
          { name: "", value: "rounds:*" },
          { name: "", value: "rounds:manage" },
          { name: "", value: "rounds:create" },
          { name: "", value: "rounds:update" },
          { name: "", value: "rounds:import" },
          { name: "", value: "rounds:delete" },
        ],
      },
      {
        name: "plots",
        permissions: [
          { name: "", value: "plots:*" },
          { name: "", value: "plots:manage" },
          { name: "", value: "plots:create" },
          { name: "", value: "plots:update" },
          { name: "", value: "plots:import" },
          { name: "", value: "plots:delete" },
        ],
      },
      {
        name: "itineraries",
        permissions: [
          { name: "", value: "itineraries:*" },
          { name: "", value: "itineraries:manage" },
          { name: "", value: "itineraries:create" },
          { name: "", value: "itineraries:update" },
          { name: "", value: "itineraries:import" },
          { name: "", value: "itineraries:delete" },
          { name: "", value: "itineraries:configure" },
        ],
      },
      {
        name: "properties",
        permissions: [
          { name: "", value: "properties:*" },
          { name: "", value: "properties:manage" },
          { name: "", value: "properties:create" },
          { name: "", value: "properties:update" },
          { name: "", value: "properties:import" },
          { name: "", value: "properties:delete" },
        ],
      },
      {
        name: "readings",
        permissions: [
          { name: "", value: "readings:*" },
          { name: "", value: "readings:manage" },
          { name: "", value: "readings:create" },
          { name: "", value: "readings:update" },
          { name: "", value: "readings:import" },
          { name: "", value: "readings:delete" },
          { name: "", value: "readings:schedule" },
          { name: "", value: "readings:assign" },
          { name: "", value: "readings:upload" },
        ],
      },
      {
        name: "reading-sets",
        permissions: [
          { name: "", value: "reading-sets:*" },
          { name: "", value: "reading-sets:manage" },
          { name: "", value: "reading-sets:create" },
          { name: "", value: "reading-sets:update" },
          { name: "", value: "reading-sets:import" },
          { name: "", value: "reading-sets:delete" },
          { name: "", value: "reading-sets:schedule" },
          { name: "", value: "reading-sets:assign" },
        ],
      },
      {
        name: "reading-anomalies",
        permissions: [
          { name: "", value: "reading-anomalies:*" },
          { name: "", value: "reading-anomalies:manage" },
          { name: "", value: "reading-anomalies:create" },
          { name: "", value: "reading-anomalies:update" },
          { name: "", value: "reading-anomalies:import" },
          { name: "", value: "reading-anomalies:delete" },
          { name: "", value: "reading-anomalies:approve" },
          { name: "", value: "reading-anomalies:assign" },
        ],
      },
      {
        name: "reading-exceptions",
        permissions: [
          { name: "", value: "reading-exceptions:*" },
          { name: "", value: "reading-exceptions:manage" },
          { name: "", value: "reading-exceptions:create" },
          { name: "", value: "reading-exceptions:update" },
          { name: "", value: "reading-exceptions:import" },
          { name: "", value: "reading-exceptions:delete" },
          { name: "", value: "reading-exceptions:approve" },
          { name: "", value: "reading-exceptions:assign" },
        ],
      },
      {
        name: "reading-followups",
        permissions: [
          { name: "", value: "reading-followups:*" },
          { name: "", value: "reading-followups:manage" },
          { name: "", value: "reading-followups:create" },
          { name: "", value: "reading-followups:update" },
          { name: "", value: "reading-followups:import" },
          { name: "", value: "reading-followups:delete" },
          { name: "", value: "reading-followups:approve" },
          { name: "", value: "reading-followups:assign" },
        ],
      },
      {
        name: "unlisted-meters",
        permissions: [
          { name: "", value: "unlisted-meters:*" },
          { name: "", value: "unlisted-meters:manage" },
          { name: "", value: "unlisted-meters:create" },
          { name: "", value: "unlisted-meters:update" },
          { name: "", value: "unlisted-meters:import" },
          { name: "", value: "unlisted-meters:delete" },
          { name: "", value: "unlisted-meters:approve" },
          { name: "", value: "unlisted-meters:assign" },
        ],
      },
      {
        name: "bills",
        permissions: [
          { name: "", value: "bills:*" },
          { name: "", value: "bills:manage" },
          { name: "", value: "bills:create" },
          { name: "", value: "bills:update" },
          { name: "", value: "bills:import" },
          { name: "", value: "bills:delete" },
          { name: "", value: "bills:send" },
          { name: "", value: "bills:print" },
        ],
      },
      {
        name: "service-points",
        permissions: [
          { name: "", value: "service-points:*" },
          { name: "", value: "service-points:manage" },
          { name: "", value: "service-points:create" },
          { name: "", value: "service-points:update" },
          { name: "", value: "service-points:import" },
          { name: "", value: "service-points:delete" },
          { name: "", value: "service-points:update-tarrif" },
          { name: "", value: "service-points:suspend" },
          { name: "", value: "service-points:restore" },
          { name: "", value: "service-points:terminate" },
          { name: "", value: "service-points:update-location" },
          { name: "", value: "service-points:update-contact" },
          { name: "", value: "service-points:update-identity" },
        ],
      },
      {
        name: "disconnection-service-orders",
        permissions: [
          { name: "", value: "disconnection-service-orders:*" },
          { name: "", value: "disconnection-service-orders:manage" },
          { name: "", value: "disconnection-service-orders:create" },
          { name: "", value: "disconnection-service-orders:update" },
          { name: "", value: "disconnection-service-orders:import" },
          { name: "", value: "disconnection-service-orders:delete" },
        ],
      },
      {
        name: "reconnection-service-orders",
        permissions: [
          { name: "", value: "reconnection-service-orders:*" },
          { name: "", value: "reconnection-service-orders:manage" },
          { name: "", value: "reconnection-service-orders:create" },
          { name: "", value: "reconnection-service-orders:update" },
          { name: "", value: "reconnection-service-orders:import" },
          { name: "", value: "reconnection-service-orders:delete" },
        ],
      },
      {
        name: "billing-tools",
        permissions: [
          { name: "", value: "billing-tools:*" },
          { name: "", value: "billing-tools:manage" },
        ],
      },
    ],
  },
  {
    code: "mapping",
    name: "Mapping Console",
    description:
      "The mapping portal primarily serves the purpose of assigning geocodes to new customers as they are acquired and making revisions to geocodes for customers who are already in the system.",
    url: "https://mapping.ecg.com.gh",
    sections: [
      {
        name: "regions",
        permissions: [
          { name: "", value: "regions:*" },
          { name: "", value: "regions:manage" },
          { name: "", value: "regions:create" },
          { name: "", value: "regions:update" },
          { name: "", value: "regions:import" },
          { name: "", value: "regions:delete" },
        ],
      },
      {
        name: "districts",
        permissions: [
          { name: "", value: "districts:*" },
          { name: "", value: "districts:manage" },
          { name: "", value: "districts:create" },
          { name: "", value: "districts:update" },
          { name: "", value: "districts:import" },
          { name: "", value: "districts:delete" },
        ],
      },
      {
        name: "blocks",
        permissions: [
          { name: "", value: "blocks:*" },
          { name: "", value: "blocks:manage" },
          { name: "", value: "blocks:create" },
          { name: "", value: "blocks:update" },
          { name: "", value: "blocks:import" },
          { name: "", value: "blocks:delete" },
        ],
      },
      {
        name: "rounds",
        permissions: [
          { name: "", value: "rounds:*" },
          { name: "", value: "rounds:manage" },
          { name: "", value: "rounds:create" },
          { name: "", value: "rounds:update" },
          { name: "", value: "rounds:import" },
          { name: "", value: "rounds:delete" },
        ],
      },
      {
        name: "plots",
        permissions: [
          { name: "", value: "plots:*" },
          { name: "", value: "plots:manage" },
          { name: "", value: "plots:create" },
          { name: "", value: "plots:update" },
          { name: "", value: "plots:import" },
          { name: "", value: "plots:delete" },
        ],
      },
      {
        name: "properties",
        permissions: [
          { name: "", value: "properties:*" },
          { name: "", value: "properties:manage" },
          { name: "", value: "properties:create" },
          { name: "", value: "properties:update" },
          { name: "", value: "properties:import" },
          { name: "", value: "properties:delete" },
        ],
      },
      {
        name: "service-points",
        permissions: [
          { name: "", value: "service-points:*" },
          { name: "", value: "service-points:manage" },
          { name: "", value: "service-points:create" },
          { name: "", value: "service-points:update" },
          { name: "", value: "service-points:import" },
          { name: "", value: "service-points:delete" },
        ],
      },
      {
        name: "geocode-assignments",
        permissions: [
          { name: "", value: "geocode-assignments:*" },
          { name: "", value: "geocode-assignments:manage" },
          { name: "", value: "geocode-assignments:create" },
          { name: "", value: "geocode-assignments:update" },
          { name: "", value: "geocode-assignments:import" },
          { name: "", value: "geocode-assignments:delete" },
        ],
      },
      {
        name: "geocode-amendments",
        permissions: [
          { name: "", value: "geocode-amendments:*" },
          { name: "", value: "geocode-amendments:manage" },
          { name: "", value: "geocode-amendments:create" },
          { name: "", value: "geocode-amendments:update" },
          { name: "", value: "geocode-amendments:import" },
          { name: "", value: "geocode-amendments:delete" },
        ],
      },
    ],
  },
  {
    code: "stores",
    name: "Store Management Console",
    description:
      "The Materials Management portal serves as the central platform for overseeing the management of meters and materials used by the Electricity Company of Ghana.",
    url: "https://stores.ecg.com.gh",
    sections: [
      {
        name: "meters",
        permissions: [
          { name: "", value: "meters:*" },
          { name: "", value: "meters:manage" },
          { name: "", value: "meters:create" },
          { name: "", value: "meters:update" },
          { name: "", value: "meters:import" },
          { name: "", value: "meters:delete" },
        ],
      },
      {
        name: "meter-brands",
        permissions: [
          { name: "", value: "meter-brands:*" },
          { name: "", value: "meter-brands:manage" },
          { name: "", value: "meter-brands:create" },
          { name: "", value: "meter-brands:update" },
          { name: "", value: "meter-brands:import" },
          { name: "", value: "meter-brands:delete" },
        ],
      },
      {
        name: "meter-models",
        permissions: [
          { name: "", value: "meter-models:*" },
          { name: "", value: "meter-models:manage" },
          { name: "", value: "meter-models:create" },
          { name: "", value: "meter-models:update" },
          { name: "", value: "meter-models:import" },
          { name: "", value: "meter-models:delete" },
        ],
      },
      {
        name: "meter-systems",
        permissions: [
          { name: "", value: "meter-systems:*" },
          { name: "", value: "meter-systems:manage" },
          { name: "", value: "meter-systems:create" },
          { name: "", value: "meter-systems:update" },
          { name: "", value: "meter-systems:import" },
          { name: "", value: "meter-systems:delete" },
        ],
      },
      {
        name: "meter-shipments",
        permissions: [
          { name: "", value: "meter-shipments:*" },
          { name: "", value: "meter-shipments:manage" },
          { name: "", value: "meter-shipments:create" },
          { name: "", value: "meter-shipments:update" },
          { name: "", value: "meter-shipments:import" },
          { name: "", value: "meter-shipments:delete" },
        ],
      },
      {
        name: "meter-incoming-requests",
        permissions: [
          { name: "", value: "meter-incoming-requests:*" },
          { name: "", value: "meter-incoming-requests:manage" },
          { name: "", value: "meter-incoming-requests:approve" },
          { name: "", value: "meter-incoming-requests:reject" },
        ],
      },
      {
        name: "meter-outgoing-requests",
        permissions: [
          { name: "", value: "meter-outgoing-requests:*" },
          { name: "", value: "meter-outgoing-requests:manage" },
          { name: "", value: "meter-outgoing-requests:create" },
          { name: "", value: "meter-outgoing-requests:cancel" },
          { name: "", value: "meter-outgoing-requests:update" },
        ],
      },
      {
        name: "materials",
        permissions: [
          { name: "", value: "materials:*" },
          { name: "", value: "materials:manage" },
          { name: "", value: "materials:create" },
          { name: "", value: "materials:update" },
          { name: "", value: "materials:import" },
          { name: "", value: "materials:delete" },
        ],
      },
      {
        name: "material-shipments",
        permissions: [
          { name: "", value: "material-shipments:*" },
          { name: "", value: "material-shipments:manage" },
          { name: "", value: "material-shipments:create" },
          { name: "", value: "material-shipments:update" },
          { name: "", value: "material-shipments:import" },
          { name: "", value: "material-shipments:delete" },
        ],
      },
      {
        name: "material-incoming-requests",
        permissions: [
          { name: "", value: "material-incoming-requests:*" },
          { name: "", value: "material-incoming-requests:manage" },
          { name: "", value: "material-incoming-requests:approve" },
          { name: "", value: "material-incoming-requests:reject" },
        ],
      },
      {
        name: "material-outgoing-requests",
        permissions: [
          { name: "", value: "material-outgoing-requests:*" },
          { name: "", value: "material-outgoing-requests:manage" },
          { name: "", value: "material-outgoing-requests:create" },
          { name: "", value: "material-outgoing-requests:cancel" },
          { name: "", value: "material-outgoing-requests:update" },
        ],
      },
    ],
  },
  {
    code: "technical",
    name: "Technical Services Console",
    description:
      "The Technical Services portal serves as the central system through which District Technical Officers of the Electricity Company of Ghana oversee and coordinate technical and field service interactions",
    url: "https://technical.ecg.com.gh",
    sections: [
      {
        name: "inspection-service-orders",
        permissions: [
          { name: "", value: "inspection-service-orders:*" },
          { name: "", value: "inspection-service-orders:manage" },
          { name: "", value: "inspection-service-orders:create" },
          { name: "", value: "inspection-service-orders:update" },
          { name: "", value: "inspection-service-orders:import" },
          { name: "", value: "inspection-service-orders:delete" },
        ],
      },
      {
        name: "contractors",
        permissions: [
          { name: "", value: "contractors:*" },
          { name: "", value: "contractors:manage" },
          { name: "", value: "contractors:create" },
          { name: "", value: "contractors:update" },
          { name: "", value: "contractors:import" },
          { name: "", value: "contractors:delete" },
        ],
      },
      {
        name: "customers",
        permissions: [
          { name: "", value: "customers:*" },
          { name: "", value: "customers:manage" },
          { name: "", value: "customers:create" },
          { name: "", value: "customers:update" },
          { name: "", value: "customers:import" },
          { name: "", value: "customers:delete" },
        ],
      },
      {
        name: "meter-contractors",
        permissions: [
          { name: "", value: "meter-contractors:*" },
          { name: "", value: "meter-contractors:manage" },
          { name: "", value: "meter-contractors:create" },
          { name: "", value: "meter-contractors:update" },
          { name: "", value: "meter-contractors:import" },
          { name: "", value: "meter-contractors:delete" },
        ],
      },
      {
        name: "installation-service-orders",
        permissions: [
          { name: "", value: "installation-service-orders:*" },
          { name: "", value: "installation-service-orders:manage" },
          { name: "", value: "installation-service-orders:create" },
          { name: "", value: "installation-service-orders:update" },
          { name: "", value: "installation-service-orders:import" },
          { name: "", value: "installation-service-orders:delete" },
        ],
      },
      {
        name: "service-regularizations",
        permissions: [
          { name: "", value: "service-regularizations:*" },
          { name: "", value: "service-regularizations:manage" },
          { name: "", value: "service-regularizations:create" },
          { name: "", value: "service-regularizations:update" },
          { name: "", value: "service-regularizations:import" },
          { name: "", value: "service-regularizations:delete" },
        ],
      },
      {
        name: "investigation-service-orders",
        permissions: [
          { name: "", value: "investigation-service-orders:*" },
          { name: "", value: "investigation-service-orders:manage" },
          { name: "", value: "investigation-service-orders:create" },
          { name: "", value: "investigation-service-orders:update" },
          { name: "", value: "investigation-service-orders:import" },
          { name: "", value: "investigation-service-orders:delete" },
        ],
      },
      {
        name: "replacement-service-orders",
        permissions: [
          { name: "", value: "replacement-service-orders:*" },
          { name: "", value: "replacement-service-orders:manage" },
          { name: "", value: "replacement-service-orders:create" },
          { name: "", value: "replacement-service-orders:update" },
          { name: "", value: "replacement-service-orders:import" },
          { name: "", value: "replacement-service-orders:delete" },
        ],
      },
      {
        name: "termination-service-orders",
        permissions: [
          { "name": "", "value": "termination-service-orders:*" },
          { "name": "", "value": "termination-service-orders:manage" },
          { "name": "", "value": "termination-service-orders:create" },
          { "name": "", "value": "termination-service-orders:update" },
          { "name": "", "value": "termination-service-orders:import" },
          { "name": "", "value": "termination-service-orders:delete" },
        ],
      },

    ],
  },
  {
    code: "backlog",
    name: " Backlog Console",
    description:
      "The Backlog portal is designed to aid the Electricity Company of Ghana in the management and resolution of a wide range of pending tasks and issues, primarily associated with their operational backlog",
    url: "https://backlog.ecg.com.gh",
    sections: [
      {
        name: "lrp-backlogs",
        permissions: [
          { name: "", value: "lrp-backlogs:*" },
          { name: "", value: "lrp-backlogs:manage" },
          { name: "", value: "lrp-backlogs:create" },
        ],
      },
      {
        name: "application-waivers",
        permissions: [
          { name: "", value: "application-waivers:*" },
          { name: "", value: "application-waivers:manage" },
          { name: "", value: "application-waivers:create" },
        ],
      },
    ],
  },
  {
    code: "twelve",
    name: "Twelve Twelve Console",
    description: "Add description here",
    url: "https://twelve.ecg.com.gh",
    sections: [
      {
        name: "reported-anomalies",
        permissions: [
          { name: "", value: "reported-anomalies:*" },
          { name: "", value: "reported-anomalies:manage" },
          { name: "", value: "reported-anomalies:create" },
        ],
      },
      {
        name: "submitted-readings",
        permissions: [
          { name: "", value: "submitted-readings:*" },
          { name: "", value: "submitted-readings:manage" },
          { name: "", value: "submitted-readings:create" },
        ],
      },
      {
        name: "updated-customers",
        permissions: [
          { name: "", value: "updated-customers:*" },
          { name: "", value: "updated-customers:manage" },
          { name: "", value: "updated-customers:create" },
        ],
      },
      {
        name: "reading-exceptions",
        permissions: [
          { name: "", value: "reading-exceptions:*" },
          { name: "", value: "reading-exceptions:manage" },
          { name: "", value: "reading-exceptions:create" },
          { name: "", value: "reading-exceptions:update" },
          { name: "", value: "reading-exceptions:import" },
          { name: "", value: "reading-exceptions:delete" },
          { name: "", value: "reading-exceptions:approve" },
        ],
      },
      {
        name: "reading-anomalies",
        permissions: [
          { name: "", value: "reading-anomalies:*" },
          { name: "", value: "reading-anomalies:manage" },
          { name: "", value: "reading-anomalies:create" },
          { name: "", value: "reading-anomalies:update" },
          { name: "", value: "reading-anomalies:import" },
          { name: "", value: "reading-anomalies:delete" },
        ],
      },
      {
        name: "readings",
        permissions: [
          { name: "", value: "readings:*" },
          { name: "", value: "readings:manage" },
          { name: "", value: "readings:create" },
          { name: "", value: "readings:update" },
          { name: "", value: "readings:import" },
          { name: "", value: "readings:delete" },
          { name: "", value: "readings:upload" },
        ],
      },
      {
        name: "reading-sets",
        permissions: [
          { name: "", value: "reading-sets:*" },
          { name: "", value: "reading-sets:manage" },
          { name: "", value: "reading-sets:create" },
          { name: "", value: "reading-sets:update" },
          { name: "", value: "reading-sets:import" },
          { name: "", value: "reading-sets:delete" },
          { name: "", value: "reading-sets:schedule" },
          { name: "", value: "reading-sets:assign" },
        ],
      },
      {
        name: "reading-and-billing",
        permissions: [
          { name: "", value: "reading-and-billing:*" },
          { name: "", value: "reading-and-billing:manage" },
          { name: "", value: "reading-and-billing:create" },
          { name: "", value: "reading-and-billing:update" },
          { name: "", value: "reading-and-billing:import" },
          { name: "", value: "reading-and-billing:delete" },
          { name: "", value: "reading-and-billing:schedule" },
          { name: "", value: "reading-and-billing:upload" },
        ],
      },
    ],
  },
  {
    code: "commercialMobile",
    name: "Commercial Services Mobile",
    description: "Add description here",
    url: "mobile://commerical.ecg.com.gh",
    sections: [
      {
        name: "service-orders",
        permissions: [
          { name: "", value: "service-orders:*" },
          { name: "", value: "service-orders:manage" },
          { name: "", value: "service-orders:create" },
          { name: "", value: "service-orders:update" },
          { name: "", value: "service-orders:import" },
        ],
      },
    ],
  },
  {
    code: "technicalMobile",
    name: "Technical Services Mobile",
    description: "Add description here",
    url: "mobile://technical.ecg.com.gh",
    sections: [
      {
        name: "service-orders",
        permissions: [
          { name: "", value: "service-orders:*" },
          { name: "", value: "service-orders:manage" },
          { name: "", value: "service-orders:create" },
          { name: "", value: "service-orders:update" },
          { name: "", value: "service-orders:import" },
        ],
      },
    ],
  },
  {
    code: "approvalMobile",
    name: "Approvals Mobile",
    description: "Add description here",
    url: "mobile://approval.ecg.com.gh",
    sections: [
      {
        name: "service-orders",
        permissions: [
          { name: "", value: "service-orders:*" },
          { name: "", value: "service-orders:manage" },
          { name: "", value: "service-orders:create" },
          { name: "", value: "service-orders:update" },
          { name: "", value: "service-orders:import" },
        ],
      },
    ],
  },
  {
    code: "finance",
    name: "Finance Management Console",
    description: "Console for viewing and managing jobs",
    url: "https://finance.subsenterprise.com/",
    sections: [
      //  TODO: Add verify permissions
      {
        name: "service-requests",
        permissions: [
          { name: "", value: "service-requests:*" },
          { name: "", value: "service-requests:manage" },
          { name: "", value: "service-requests:create" },
          { name: "", value: "service-requests:update" },
          { name: "", value: "service-requests:import" },
          { name: "", value: "service-requests:delete" },
        ],
      },
      {
        name: "installation-types",
        permissions: [
          { name: "", value: "installation-types:*" },
          { name: "", value: "installation-types:manage" },
          { name: "", value: "installation-types:create" },
          { name: "", value: "installation-types:update" },
          { name: "", value: "installation-types:import" },
          { name: "", value: "installation-types:delete" },
        ],
      },
    ],
  },
  {
    code: "engineering",
    name: "District Engineer Console",
    description: "Console for district engineer to manage transformers and fault service orders",
    url: "https://engineering.subsenterprise.com/",
    sections: [
      //  TODO: Add verify permissions
      {
        name: "transformers",
        permissions: [
          { name: "", value: "transformers:*" },
          { name: "", value: "transformers:manage" },
          { name: "", value: "transformers:create" },
          { name: "", value: "transformers:update" },
          { name: "", value: "transformers:import" },
          { name: "", value: "transformers:delete" },
        ],
      },
      {
        name: "fault-service-orders",
        permissions: [
          { name: "", value: "fault-service-orders:*" },
          { name: "", value: "fault-service-orders:manage" },
          { name: "", value: "fault-service-orders:create" },
          { name: "", value: "fault-service-orders:update" },
          { name: "", value: "fault-service-orders:import" },
          { name: "", value: "fault-service-orders:delete" },
        ],
      },
      {
        name: "customers",
        permissions: [
          { name: "", value: "customers:*" },
          { name: "", value: "customers:manage" },
          { name: "", value: "customers:create" },
          { name: "", value: "customers:update" },
          { name: "", value: "customers:import" },
          { name: "", value: "customers:delete" },
        ],
      },
    ],
  },
  {
    code: "mis",
    name: "MIS Console",
    description: "Console for viewing and managing jobs",
    url: "https://mis.subsenterprise.com/",
    sections: [
      //  TODO: Add permissions once system is resolved

    ],
  },
];

export default applications;
