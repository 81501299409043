import { FC, useEffect } from "react";
import { validators, wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, UserForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_USER } from "./view";
import * as yup from 'yup';
import _ from 'lodash';

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $code: String
    $lastName: String
    $firstName: String
    $gender: Gender
    $ghanaCardNumber: String
    $phoneNumber: String
    $emailAddress: String
    $profileImageUrl: String
    $role: ID
    $district: ID
    $region: ID
  ) {
    updateUser(
      id: $id
      code: $code
      lastName: $lastName
      firstName: $firstName
      gender: $gender
      ghanaCardNumber: $ghanaCardNumber
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      profileImageUrl: $profileImageUrl
      role: $role
      district: $district
      region: $region
    ) {
      _id
    }
  }
`;

interface UpdateUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const UpdateUserContainer: FC<UpdateUserContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetchingUser } = useQuery(GET_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateUser, { loading }] = useMutation(UPDATE_USER);

  const form = useFormik({
    initialValues: {
      code: "",
      lastName: "",
      firstName: "",
      gender: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      profileImageUrl: "",
      role: searchParams?.role || (null as any),
      region: "",
      district: "",
    },
    validationSchema: yup.object().shape({
      code: yup.string().required("Code is required"),
      lastName: yup.string().required("Last Name is required"),
      firstName: yup.string().required("First Name is required"),
      ghanaCardNumber: yup.string()
      .matches(
        validators.GhanaCardRegex,
        "Kindly enter a valid Ghana Card Number"
      ),
      emailAddress: yup.string().email("Kindly enter a valid email address"),
      phoneNumber: yup.string()
        .matches(
          /^(\+233|0)(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
          "Kindly enter a valid phone number"
        )
        .required("Phone number is required"),
      role: yup.object().nullable().required("Role is required"),
      gender: yup.string().oneOf(["Male", "Female"])
    }),
    onSubmit: async (values) => {
      await updateUser({
        variables: {
          id: searchParams.id,
          ...values,
          emailAddress: values?.emailAddress?.toLowerCase(),
          role: values.role?._id,
        },
      }).then(({ data }) => {
        if (data.updateUser._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not create User" })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
   form.setValues({
      code: data?.user?.code || "",
      lastName: data?.user?.lastName || "",
      firstName: data?.user?.firstName || "",
      gender: data?.user?.gender || "",
      ghanaCardNumber: data?.user?.ghanaCardNumber || "",
      phoneNumber: data?.user?.phoneNumber || "",
      emailAddress: data?.user?.emailAddress || "",
      profileImageUrl: data?.user?.profileImageUrl || "",
      role: _.pick(data?.user?.role, [ "_id", "code", "name", "requiresDistrict", "requiresRegion"]), //? data?.user?.role._id : "",
      region: data?.user?.region?._id || "",
      district: data?.user?.district?._id || "",
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchingUser}
      title="Edit User Information"
      description="Provide the details to update user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing User..." : "Edit User"}
          </button>
        </>
      )}
    >
      {data?.user?._id && <UserForm form={form} />}
    </Modal>
  );
};

export default UpdateUserContainer;
