import { FC } from "react";
import { wrapClick } from "utils";
import { Disclosure } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { IUserRoleFormData } from "./schema";
import { Applications } from "apollo/data";
import { UserPermission } from "apollo/cache/auth";

interface SummaryPageProps {
  handlePrevious: () => void;
  handleStep: (step: number) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitLoading: boolean;
  values: IUserRoleFormData;
}

const summaries = [
  {
    name: "Role Info",
    description: "Information about the role.",
    accessor: "roleInfo",
    href: 0,
    Component: ({ data }: any) => (
      <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3'>
        <div>
          <div className='grid grid-cols-3 gap-6'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Type
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {_.startCase(data?.type?.toLowerCase()) || "N/A"}
              </div>
            </div>
            <div className=''>
              <span className='block text-sm font-light text-gray-700'>
                Name
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.name || "N/A"}
              </div>
            </div>
            {data?.type === "TEMPORAL" && (
              <div className=''>
                <span className='block text-sm font-light text-gray-700'>
                  Expires In
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {data?.expiresAt || "N/A"}
                </div>
              </div>
            )}
            <div className='col-start-1'>
              <span className='block text-sm font-light text-gray-700'>
                Description
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.description || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    name: "Permissions Info",
    description: "Information about permissions to be assigned to the User Role.",
    accessor: "rolePermissionsInfo",
    href: 1,
    Component: ({ data }: any) => (
      <div>
        <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 divide-y divide-gray-300 space-y-6 mb-6'>
          {_.map(
            Applications.filter((application) =>
              _.includes(data?.applications, application.code)
            ),
            (application, idx) => {
              const id = `applications.${application.code}`;
              return (
                <div key={idx} className='pt-6 first:pt-0'>
                  <span className='text-xs font-light'>{application.url}</span>
                  <div className='space-y-6 mt-2'>
                    <div className='col-span-3'>
                      <div>
                        <div className='flex justify-between'>
                          <span className='flex flex-grow flex-col'>
                            <span className='text-sm font-medium text-gray-900'>
                              Gets Access to {application?.name}
                            </span>
                            <span className='text-sm text-gray-500 mt-1'>
                              {application?.description}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className='border border-gray-300 bg-gray-50 grid grid-cols-3 gap-6 p-6 rounded'>
          {_.map(
            _.reduce(
              Applications,
              (acc: any[], app) => {
                _.forEach(app.sections, (section) => {
                  const existingSection = _.find(acc, {
                    name: section.name,
                  });
                  if (!existingSection) {
                    acc.push({
                      name: section.name,
                      permissions: section.permissions,
                    });
                  } else {
                    existingSection.permissions = [
                      ...existingSection.permissions,
                      ...section.permissions.filter(
                        (perm) =>
                          !existingSection.permissions
                            ?.map((existingPerm: any) => existingPerm?.value)
                            .includes(perm.value)
                      ),
                    ];
                  }
                });

                return acc;
              },
              []
            )?.filter(
              (section) =>
                _.intersection(
                  _.map(section.permissions, "value"),
                  data?.permissions
                ).length
            ),
            (section) => (
              <div className=''>
                <span className='block text-sm font-medium text-gray-700'>
                  {_.startCase(section.name)}
                </span>
                <div className='mt-1 w-full space-y-1'>
                  {_.map(
                    section.permissions?.filter((permission: any) =>
                      data?.permissions?.includes(
                        permission.value as UserPermission
                      )
                    ),
                    (permission) => (
                      <div className='relative flex items-start'>
                        <div className='flex h-6 items-center'>
                          <input
                            id={`${permission?.value}.permissions`}
                            aria-describedby={`${permission.value}-description`}
                            name={`${permission?.value}.permissions`}
                            type='checkbox'
                            value={permission.value}
                            checked={data?.permissions?.includes(
                              permission.value as UserPermission
                            )}
                            className='h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600'
                          />
                        </div>
                        <div className='ml-3 text-sm leading-6'>
                          <label
                            htmlFor='candidates'
                            className='font-medium text-gray-900'
                          >
                            {permission.value}
                          </label>
                          {false && (
                            <p
                              id='candidates-description'
                              className='text-gray-500'
                            >
                              Get notified when a candidate applies for a job.
                            </p>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    ),
  },
];

const SummaryPage: FC<SummaryPageProps> = ({
  values,
  handlePrevious,
  handleStep,
  submitLoading,
  ...form
}) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <form
      onSubmit={form.handleSubmit}
      className='flex-1 flex flex-col overflow-hidden'
    >
      <div className='space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto'>
        {summaries.map(({ Component, ...summary }, idx) => (
          <Disclosure
            as='div'
            defaultOpen={true}
            key={idx}
            className={idx === 0 ? " p-4" : " p-4"}
          >
            {({ open }) => (
              <>
                <Disclosure.Button
                  as='div'
                  className='flex justify-between items-center cursor-pointer'
                >
                  <div>
                    <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
                      {summary.name}
                    </h3>
                    <p className='mt-1 text-xs text-gray-500'>
                      {summary.description}
                    </p>
                  </div>
                  <div>
                    {open ? (
                      <ChevronUpIcon className='h-6 w-6 text-gray-400 dark:text-gray-500' />
                    ) : (
                      <ChevronDownIcon className='h-6 w-6 text-gray-400 dark:text-gray-500' />
                    )}
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel as='div' className='mt-6'>
                  <Component
                    data={(values as any)[summary.accessor]}
                    config={currentConfig}
                  />
                  <div className='pt-5'>
                    <div className='flex justify-end'>
                      <button
                        type='button'
                        onClick={() => handleStep(summary.href)}
                        className='ml-3 inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-600 border-gray-600 hover:border-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                      >
                        <PencilSquareIcon
                          className='-ml-0.5 mr-1.5 h-4 w-4'
                          aria-hidden='true'
                        />
                        Edit
                      </button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='submit'
          disabled={submitLoading}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.handleCancel)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SummaryPage;
