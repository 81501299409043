import { AvatarUpload, SelectInput, TextInput } from "components/core";
import { DistrictPicker, RegionPicker, UserRolePicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

interface UserFormProps {
  form: FormikProps<{
    code: string;
    lastName: string;
    firstName: string;
    ghanaCardNumber: string;
    phoneNumber: string;
    emailAddress: string;
    gender: string;
    profileImageUrl: string;
    region: string;
    district: string;
    role: any;
  }>;
}

const UserForm: FC<UserFormProps> = ({ form }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Staff Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <AvatarUpload id='profileImageUrl' label='Photo' {...form} />
          </div>
          <div>
            <UserRolePicker
              id='role'
              label='User Role'
              placeholder='User Role Name'
              required={true}
              {...form}
              setFieldValue={(field: string, value: any) => {
                form.setFieldValue(field, value);
                form.setFieldValue("region", null);
                form.setFieldValue("district", null);
              }}
            />
          </div>

          {form.values.role?.requiresRegion && (
            <div>
              <RegionPicker
                id='region'
                label='Region'
                placeholder='Region Name'
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue(field, value);
                  form.setFieldValue("district", null);
                }}
                rawId={true}
              />
            </div>
          )}

          {form.values.role?.requiresDistrict && (
            <div>
              {form.values.region && (
                <DistrictPicker
                  id='district'
                  label='District'
                  placeholder='District Name'
                  required={true}
                  filter={{ region: form.values.region }}
                  {...form}
                  rawId={true}
                />
              )}
            </div>
          )}

          <div>
            <TextInput
              id='code'
              label='Staff ID'
              type='text'
              placeholder='eg. 101314'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='ghanaCardNumber'
              label='Ghana Card'
              type='text'
              placeholder='Ghana Card'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='lastName'
              label='Last Name'
              type='text'
              placeholder='e.g. Mensah'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='firstName'
              label='Other Names'
              required={true}
              type='text'
              placeholder='e.g. Enoch Nana Nyankah'
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='phoneNumber'
              label='Phone Number'
              type='text'
              required={true}
              placeholder='e.g. 0550123292'
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='emailAddress'
              label='Email Address'
              type='email'
              placeholder='e.g. nyankahmensah@gmail.com'
              required
              {...form}
            />
          </div>
          <div>
            <SelectInput
              id='gender'
              label='Gender'
              options={["Male", "Female"]}
              placeholder='e.g. nyankahmensah@gmail.com'
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
