import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { RegionView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_REGION = gql`
  query GetRegion($id: ID!) {
    region: getRegion(id: $id) {
      _id
      code
      name
      # address
      # ghanaPostAddress
      # contactPerson {
      #   emailAddress
      #   phoneNumber
      #   fullName
      # }
      cartesianLocation {
        northing
        easting
        zone
      }
      meta {
        usersCount
        districtsCount
        blocksCount
        roundsCount
        plotsCount
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
  }
`

export default function ViewRegionContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_REGION, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Region Information"
      description="Details of region are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "regions:*", "regions:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <RegionView
        region={data?.region}
      />
    </Modal>
  )
}