import { StepWizard } from "components";
import { FormikProps } from "formik";
import { FC, useState } from "react";
import MembersInfoForm from "./members-info";
import { IUserTeamFormData, UserTeamFormDataValidationSchema } from "./schema";
import SummaryPage from "./summary";
import TeamInfoForm from "./team-info";

interface UserTeamFormProps {
  form: FormikProps<IUserTeamFormData>;
}

const steps = [
  { name: "Team Info", href: 0 },
  { name: "Members Info", href: 1 },
  { name: "Preview", href: 2 },
];

const UserTeamForm: FC<UserTeamFormProps> = ({ form }) => {
  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const handleNextStep = (_step?: string) => (_values: any) => {
    // check if last step
    if (_step) {
      form.setFieldValue(_step, _values);
    }
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    form.resetForm();
    onCancel();
  };

  const renderForm = () => {
    switch (step) {
      case 0: {
        return (
          <TeamInfoForm
            handleNext={handleNextStep("teamInfo")}
            initialValues={form.values["teamInfo"]}
            validationSchema={UserTeamFormDataValidationSchema.fields.teamInfo}
            values={form.values}
            handlePrevious={handlePreviousStep}
            handleCancel={handleCancel}
          />
        );
      }
      case 1: {
        return (
          <MembersInfoForm
            handleNext={handleNextStep("teamMembersInfo")}
            initialValues={{
              members: form.values?.teamMembersInfo.members,
            }}
            validationSchema={UserTeamFormDataValidationSchema.fields.teamMembersInfo}
            values={form.values}
            handlePrevious={handlePreviousStep}
            handleCancel={handleCancel}
          />
        );
      }
      case 2: {
        return (
          <SummaryPage
            values={form.values}
            handlePrevious={handlePreviousStep}
            handleStep={setStep}
            handleSubmit={form.handleSubmit}
            handleCancel={handleCancel}
            submitLoading={form.isSubmitting}
          />
        );
      }
      default: {
        return (
          <TeamInfoForm
            handleNext={handleNextStep("teamInfo")}
            initialValues={form.values["teamInfo"]}
            values={form.values}
            validationSchema={UserTeamFormDataValidationSchema.fields.teamInfo}
            handlePrevious={handlePreviousStep}
            handleCancel={handleCancel}
          />
        );
      }
    }
  };

  return (
    <div className="bg-white dark:bg-gray-900 shadow-xl flex flex-col flex-1  overflow-hidden relative">
      <div className="px-6 pt-6">
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">{renderForm()}</div>
    </div>
  );
};

export default UserTeamForm;
