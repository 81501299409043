import { gql, useQuery } from '@apollo/client';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import moment from 'moment';
import BillingPreviewForm from 'components/forms/tariff-instance/billing-preview';
import { Modal } from 'components';

const GET_TARIFF_CLASS = gql`
  query GetTariffClass($id: ID!) {
    tariffClass: getTariffClass(id: $id) {
      _id
      code
      name
      description
      serviceClass
      # currentInstance {
      #   _id
      #   code
      #   name
      #   tariffClass {
      #     _id
      #   }
      #   energyCharge {
      #     type
      #     value
      #     steps {
      #       name
      #       minimumConsumption
      #       maximumConsumption
      #       value
      #       exclusive
      #     }
      #   }
      #   serviceCharge {
      #     type
      #     value
      #     steps {
      #       name
      #       minimumConsumption
      #       maximumConsumption
      #       value
      #       exclusive
      #     }
      #   }
      #   hasExtraItems
      #   extraItems {
      #     name
      #     category
      #     type
      #     appliedTo
      #     value
      #   }
      #   startDate
      #   endDate
      #   createdAt
      #   updatedAt
      # }
      createdAt
      updatedAt
    }
  }
`


export default function CalculateTariffClassContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_TARIFF_CLASS, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Calculate Bill"
      description="Provide details to simulate bill calculation"
    >
      {data?.tariffClass?._id && (
        <BillingPreviewForm
          values={{
            tariffInstanceInfo: {
              code: data?.tariffClass?.currentInstance?.code || "",
              name: data?.tariffClass?.currentInstance?.name ? `${data?.tariffClass?.currentInstance?.name} - Copy` : "",
              startDate: data?.tariffClass?.currentInstance?.startDate || moment().format("YYYY-MM-DD"),
            },
            chargeInfo: {
              energyCharge: {
                type: data?.tariffClass?.currentInstance?.energyCharge?.type || "FixedRate",
                value: data?.tariffClass?.currentInstance?.energyCharge?.value || 0,
                steps: data?.tariffClass?.currentInstance?.energyCharge?.steps?.map(({ __typename, _id, ...step }: any) => step) || [],
              },
              serviceCharge: {
                type: data?.tariffClass?.currentInstance?.serviceCharge?.type || "FixedRate",
                value: data?.tariffClass?.currentInstance?.serviceCharge?.value || 0,
                steps: data?.tariffClass?.currentInstance?.serviceCharge?.steps?.map(({ __typename, _id, ...step }: any) => step) || [],
              },
            },
            extraItemsInfo: {
              hasExtraItems: !!data?.tariffClass?.currentInstance?.hasExtraItems || false,
              extraItems: data?.tariffClass?.currentInstance?.extraItems?.map(({ __typename, _id, ...extraItem }: any) => extraItem) || [],
            }

          }}
          hideSubmitButtons={true}
        />
      )}
    </Modal>
  )
}