import { validators, wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, ContractorForm } from "components";
import * as yup from "yup";

const CREATE_CONTRACTOR = gql`
  mutation CreateContractor(
    # $code: String!
    $name: String!
    $address: String!
    $ghanaPostAddress: String!
    $contactPerson: ContactPersonInput!
    # $type: ContractorType
    $district: ID!
    $parentContractor: ID
  ) {
    createContractor(
      # code: $code
      name: $name
      address: $address
      ghanaPostAddress: $ghanaPostAddress
      contactPerson: $contactPerson
      # type: $type
      district: $district
      parentContractor: $parentContractor
    ) {
      _id
      code
      createdAt
      updatedAt
    }
  }
`;

export default function CreateContractorContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createContractor, { loading }] = useMutation(CREATE_CONTRACTOR);

  const form = useFormik({
    initialValues: {
      name: "",
      // type: "",
      // code: "",
      address: "",
      ghanaPostAddress: "",
      district: "",
      contactPerson: {
        fullName: "",
        emailAddress: "",
        phoneNumber: "",
      },
      parentContractor: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Contractor name is required"),
      // type: yup.string().required("Contractor type is required"),
      address: yup.string().required("Contractor address is required"),
      ghanaPostAddress: yup.string()
      .matches(
        validators.GhanaPostRegex,
        "Kindly enter a valid GhanaPost Address"
      )
      .required("GhanaPost Address is required"),
      district: yup.string().required("District is required"),
      contactPerson: yup.object().shape({
        fullName: yup.string().required("Contact Person's name is required"),
        emailAddress: yup.string().email("Invalid email address"),
        phoneNumber: yup.string()
          .matches(
            /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
            "Kindly enter a valid phone number"
          )
          .required("Phone number is required"),
      }),
    }),
    onSubmit: async (values) => {
      await createContractor({
        variables: {
          ...values,
          parentContractor: values?.parentContractor || undefined,
        },

      }).then(({ data }) => {
        if (data.createContractor._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Contractor Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Contractor",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Add New Contractor'
      description='Provide the details to add a new contractor'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Contractor..." : "Add Contractor"}
          </button>
        </>
      )}
    >
      <ContractorForm form={form} />
    </Modal>
  );
}
