import { SelectInput, TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

interface RegionFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    cartesianLocation: {
      easting: number;
      northing: number;
      zone: string;
    };
  }>;
  disabled?: boolean;
}

const RegionForm: FC<RegionFormProps> = ({ form, disabled }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Region Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <TextInput
              id="code"
              label="Region Code"
              type="text"
              placeholder="e.g. 07"
              disabled={disabled}
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1 col-span-2">
            <TextInput
              id="name"
              label="Region Name"
              type="text"
              placeholder="e.g. Accra West Region"
              {...form}
            />
          </div>

          <div className="col-start-1">
            <TextInput
              id="cartesianLocation.easting"
              label="Easting (X)"
              type="number"
              placeholder="e.g. 56"
              {...form}
              required={true}
            />
          </div>

          <div>
            <TextInput
              id="cartesianLocation.northing"
              label="Northing (Y)"
              type="number"
              placeholder="e.g. 56"
              {...form}
              required={true}
            />
          </div>

          <div className="">
            <SelectInput
              id="cartesianLocation.zone"
              label="Zone"
              options={[
                { label: "Select Zone", value: "" },
                { label: "Zone 30°N", value: "30" },
                { label: "Zone 31°N", value: "31" },
              ]}
              placeholder="e.g. Zone 30/31"
              {...form}
              required={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionForm;
