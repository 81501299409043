import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, TariffClassForm } from 'components';
import * as yup from 'yup'

const CREATE_TARIFF_CLASS = gql`
  mutation CreateTariffClass(
    $code: String!
    $name: String!
    $description: String
    $serviceClass: ServiceClass!
  ) {
    createTariffClass(
      code: $code
      name: $name
      description: $description
      serviceClass: $serviceClass
    ) {
      _id
    }
  }
`


export const createTariffClassValidationSchema = yup.object({
  code: yup.string().matches(/^[A-Z][0-9][0-9]$/, "Kindly enter a valid tariff class code").required("Kindly enter a tariff class code"),
  description: yup.string().max(1024, "Description is too long"),
  name: yup.string().required("Kindly enter a tariff class").min(3, "Tariff class name is too long").max(256, "Tariff class name is too short"),
  serviceClass: yup.string().oneOf(["Residential", "NonResidential", "SpecialLoadTariff", "Industrial"], "Kindly choose a valid service class").required("Kindly choose a service class"),
})

export default function CreateTariffClassContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const [createTariffClass, { loading }] = useMutation(CREATE_TARIFF_CLASS)

  const form = useFormik({
    initialValues: {
      code: "",
      description: "",
      name: "",
      serviceClass: "",
    },
    validationSchema: createTariffClassValidationSchema,
    onSubmit: async (values) => {
      await createTariffClass({
        variables: {
          ...values
        }
      }).then(({ data }) => {
        if (data.createTariffClass._id) {
          toast(JSON.stringify({ type: "success", title: "Tariff Class Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Tariff Class" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Tariff Class"
      description="Provide the details to add a new tariff class"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Tariff Class..." : "Add Tariff Class"}
          </button>

        </>
      )}>
      <TariffClassForm
        form={form}
      />
    </Modal>
  )
}