import { validators, wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { DistrictForm, Modal } from "components";
import { DistrictFormProps } from "components/forms/district";
import * as Yup from "yup";

const CREATE_DISTRICT = gql`
mutation CreateDistrict(
  $code: String!
  $name: String!
  $region: ID!
  $cartesianLocation: CartesianLocationInput
  $jobCode: String
) {
  createDistrict(
    code: $code
    name: $name
    region: $region
    cartesianLocation: $cartesianLocation
    jobCode: $jobCode
  ) {
    _id
  }
}
`;


export const districtValidationSchema = Yup.object().shape({
  code: Yup.string()
    .test("len", "Must be exactly 2 characters", (val) => val?.length === 2)
    // all characters must be digits and non-zeros
    .matches(/^[0-9][1-9]*$/, "District Code must be a non-zero number")
    .required("District code is required"),
  jobCode: Yup.string()
    .test("len", "Must be exactly 2 characters", (val) => val?.length === 2)
    // all characters must be digits and non-zeros
    .matches(/^[A-Z]{2}$/, "Job Code must be 2 uppercase letters")
    .required("Job code is required"),
  name: Yup.string().required("District name is required"),
  region: Yup.object().nullable().required("Region is required"),
  cartesianLocation: Yup.object({
    easting: Yup.number().required("Easting is required"),
    northing: Yup.number().required("Northing is required"),
    zone: Yup.string().required("Zone is required"),
  })
});

export default function CreateDistrictContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createDistrict, { loading }] = useMutation(CREATE_DISTRICT);

  const form = useFormik<DistrictFormProps["form"]["values"]>({
    initialValues: {
      code: "",
      name: "",
      region: null,
      jobCode: "",
      cartesianLocation: {
        northing: 0,
        easting: 0,
        zone: "",
      },
    },
    validationSchema: districtValidationSchema,
    onSubmit: async (values) => {
      await createDistrict({
        variables: {
          ...values,
          cartesianLocation: {
            ...values.cartesianLocation,
            ...(values?.cartesianLocation?.zone ? { zone: parseInt(values?.cartesianLocation?.zone) } : {}),
          },
          region: values?.region?._id,
          code: [values?.region?.code, values?.code].join("-"),
        },
      }).then(({ data }) => {
        if (data.createDistrict._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "District Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create District",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New District"
      description="Provide the details to add a new district"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding District..." : "Add District"}
          </button>
        </>
      )}
    >
      <DistrictForm form={form} />
    </Modal>
  );
}
