import { gql, useQuery } from '@apollo/client';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import moment from 'moment';
import BillingPreviewForm from 'components/forms/tariff-instance/billing-preview';
import { Modal } from 'components';

const GET_TARIFF_INSTANCE = gql`
  query GetTariffInstance($id: ID!) {
    tariffInstance: getTariffInstance(id: $id) {
      _id
      code
      name
      tariffClass {
        _id
      }
      energyCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      serviceCharge {
        type
        value
        steps {
          name
          minimumConsumption
          maximumConsumption
          value
          exclusive
        }
      }
      hasExtraItems
      extraItems {
        name
        category
        type
        appliedTo
        value
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`


export default function CalculateTariffInstanceContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_TARIFF_INSTANCE, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Calculate Bill"
      size='4xl'
      description="Provide details to simulate bill calculation"
    >
      {data?.tariffInstance?._id && (
        <BillingPreviewForm
          values={{
            tariffInstanceInfo: {
              code: data?.tariffInstance?.code || "",
              name: data?.tariffInstance?.name ? `${data?.tariffInstance?.name} - Copy` : "",
              startDate: data?.tariffInstance?.startDate || moment().format("YYYY-MM-DD"),
            },
            chargeInfo: {
              energyCharge: {
                type: data?.tariffInstance?.energyCharge?.type || "FixedRate",
                value: data?.tariffInstance?.energyCharge?.value || 0,
                steps: data?.tariffInstance?.energyCharge?.steps?.map(({ __typename, _id, ...step }: any) => step) || [],
              },
              serviceCharge: {
                type: data?.tariffInstance?.serviceCharge?.type || "FixedRate",
                value: data?.tariffInstance?.serviceCharge?.value || 0,
                steps: data?.tariffInstance?.serviceCharge?.steps?.map(({ __typename, _id, ...step }: any) => step) || [],
              },
            },
            extraItemsInfo: {
              hasExtraItems: !!data?.tariffInstance?.hasExtraItems || false,
              extraItems: data?.tariffInstance?.extraItems?.map(({ __typename, _id, ...extraItem }: any) => extraItem) || [],
            }
          }}
          hideSubmitButtons={true}
        />
      )}
    </Modal>
  )
}