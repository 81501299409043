import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, UserRoleForm } from "components";
import moment from "moment";
import { IUserRoleFormData } from "components/forms/user-role/schema";
import * as yup from 'yup'

const CREATE_USER_ROLE = gql`
  mutation CreateUserRole(
    $name: String!
    $description: String
    $type: UserRoleType!
    $expiresAt: DateTime
    $requiresRegion: Boolean
    $requiresDistrict: Boolean
    $permissions: [String]
    $applications: [Application]
  ) {
    createUserRole(
      name: $name
      description: $description
      type: $type
      expiresAt: $expiresAt
      requiresRegion: $requiresRegion
      requiresDistrict: $requiresDistrict
      permissions: $permissions
      applications: $applications
    ) {
      _id
    }
  }
`;

export default function CreateUserRoleContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createUserRole, { loading }] = useMutation(CREATE_USER_ROLE);

  const form = useFormik<IUserRoleFormData>({
    initialValues: {
      roleInfo: {
        name: "",
        description: "",
        type: "",
        expiresAt: moment().add(8, "hours").format("YYYY-MM-DD"),
        requiresRegion: true,
        requiresDistrict: true,
      },
      rolePermissionsInfo: {
        applications: [],
        permissions: [],
      },
    },
    onSubmit: async (values) => {
      await createUserRole({
        variables: {
          ...values.roleInfo,
          expiresAt:
            values.roleInfo.type === "TEMPORAL"
              ? moment(values.roleInfo.expiresAt).toDate()
              : undefined,
          applications: values.rolePermissionsInfo.applications,
          permissions: values.rolePermissionsInfo.permissions,
        },
      }).then(({ data }) => {
        if (data.createUserRole._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Role Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create User Role",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Add New User Role'
      description='Provide the details to add a new User Role'
      hideActions={true}
      size='4xl'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding User Role..." : "Add User Role"}
          </button>
        </>
      )}
    >
      <UserRoleForm form={form} validationSchema={UserRoleFormDataValidationSchema} />
    </Modal>
  );
}



export const UserRoleFormDataValidationSchema = yup.object({
  roleInfo: yup.object({
    name: yup.string().min(3, "Role name is too short").max(128, "Role name is too long").required("Kindly enter a role name"),
    description:  yup.string().min(3, "Descriptionis too short").max(1024, "Description is too long"),
    type: yup.string().oneOf(["Custom", "Temporal"]).required("Kindly choose a role type"),
    //expiresAt: yup.date().required("Kindly enter the role expiry date"),
    requiresRegion: yup.boolean(),
    requiresDistrict: yup.boolean(),
  }),
  rolePermissionsInfo: yup.object({
    applications: yup.array().of(yup.string()).min(1, "Kindly give the user role access to at least one application"),
    permissions: yup.array().of(yup.string()).min(1, "Kindly give the user role at least one permission"),
  }),
})

export type UserRoleFormDataValidationSchemaType = typeof UserRoleFormDataValidationSchema