import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import ComplaintTypeForm from "components/forms/complaint-type";
import * as yup from 'yup'

const CREATE_COMPLAINT_TYPE = gql`
 mutation CreateComplaintType(
  $name: String!
  $category: ComplaintCategory!
  $userRole: ID
  $description: String
  $requiresDocument: Boolean
  $requiresInvestigation: Boolean
  $requiresServicePoint: Boolean
  $contactEmailAddress: String
) {
  createComplaintType(
    name: $name
    category: $category
    userRole: $userRole
    description: $description
    requiresDocument: $requiresDocument
    requiresInvestigation: $requiresInvestigation
    requiresServicePoint: $requiresServicePoint
    contactEmailAddress: $contactEmailAddress
  ) {
    _id
  }
}

`;

export const createComplaintTypeValidationSchema = yup.object({
  name: yup.string().required("Kindly enter the complaint name").min(3, "Complaint Type name is too short").max(256, "Complaint Type name is too long"),
  description:  yup.string().max(1024, "Complaint Type description is too long"),
  category: yup.string().oneOf(["CustomerService", "Commercial", "Technical", "RevenueProtection", "Operations", "Geospatial"]).required("Kindly choose a complaint category"),
  requiresDocument: yup.boolean(),
  requiresInvestigation: yup.boolean(),
  requiresServicePoint: yup.boolean(),
  userRole: yup.string().nullable().required("Kindly choose a user role"),
  contactEmailAddress: yup.string().email("Kindly enter a valid email address"),
})

export default function CreateComplaintTypeContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createComplaintType, { loading }] = useMutation(CREATE_COMPLAINT_TYPE);

  const form = useFormik({
    initialValues: {
      name: "",
      description: "",
      category: "",
      requiresDocument: false,
      requiresInvestigation: false,
      requiresServicePoint: true,
      userRole: null as any,
      contactEmailAddress: "",
    },
    validationSchema: createComplaintTypeValidationSchema,
    onSubmit: async (values) => {
      await createComplaintType({
        variables: {
          ...values,
        }
      }).then(({ data }) => {
        if (data.createComplaintType._id) {
          toast(JSON.stringify({ type: "success", title: "Complaint Type Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create complaint type" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Add New Complaint Type'
      description='Provide the details to add a new Complaint Type'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Complaint Type..." : "Add New Complaint Type"}
          </button>
        </>
      )}
    >
      <ComplaintTypeForm form={form} />
    </Modal>
  );
}
