import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { UserRoleView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import ComplaintTypeView from "components/cards/complaint-type-view";

export const GET_COMPLAINT_TYPE = gql`
  query GetComplaintType($id: ID!) {
    complaintType: getComplaintType(id: $id) {
      _id
      code
      name
      description
      category
      categoryCode
      code
      contactEmailAddress
      requiresDocument
      requiresInvestigation
      requiresServicePoint
      userRole {
        _id
        code
        name
        description
        type
        applications
        permissions
        status
        expiresAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewComplaintTypeContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_COMPLAINT_TYPE, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
        complaintType: searchParams.id,
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='Complaint Type Information'
      description='Details of complaint type are shown below'
      renderActions={() => (
        <>
          {/* //TODO: Set permissions */}
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(updateItem)}
          >
            Edit
          </button>
        </>
      )}
    >
      <ComplaintTypeView complaintType={data?.complaintType} />
    </Modal>
  );
}
