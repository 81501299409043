import { useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, UserRoleForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import moment from "moment";
import { IUserRoleFormData } from "components/forms/user-role/schema";
import { UserRoleFormDataValidationSchemaType, UserRoleFormDataValidationSchema } from "./create";

const GET_USER_ROLE = gql`
  query GetUserRole($id: ID!) {
    userRole: getUserRole(id: $id) {
      _id
      code
      name
      description
      type
      applications
      permissions
      status
      expiresAt
      requiresDistrict
      requiresRegion
      meta {
        permissionsCount
        applicationsCount
        usersCount
        __typename
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole(
    $id: ID!
    $name: String
    $description: String
    $type: UserRoleType
    $expiresAt: DateTime
    $requiresRegion: Boolean
    $requiresDistrict: Boolean
    $permissions: [String]
    $applications: [Application]
  ) {
    updateUserRole(
      id: $id
      name: $name
      description: $description
      type: $type
      expiresAt: $expiresAt
      requiresRegion: $requiresRegion
      requiresDistrict: $requiresDistrict
      permissions: $permissions
      applications: $applications
    ) {
      _id
    }
  }
`;

export default function UpdateUserRoleContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetchingUserRole } = useQuery(GET_USER_ROLE, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateUserRole, { loading }] = useMutation(UPDATE_USER_ROLE);

  const form = useFormik<IUserRoleFormData>({
    initialValues: {
      roleInfo: {
        name: "",
        description: "",
        type: "",
        expiresAt: moment().add(8, "hours").format("YYYY-MM-DD"),
        requiresRegion: true,
        requiresDistrict: true,
      },
      rolePermissionsInfo: {
        applications: [],
        permissions: [],
      },
    },
    onSubmit: async (values) => {
      await updateUserRole({
        variables: {
          id: searchParams.id,
          ...values.roleInfo,
          expiresAt:
            values.roleInfo.type === "TEMPORAL"
              ? moment(values.roleInfo.expiresAt).toDate()
              : undefined,
          requiresRegion: !!values.roleInfo.requiresRegion,
          requiresDistrict: !!values.roleInfo.requiresDistrict,
          applications: values.rolePermissionsInfo.applications,
          permissions: values.rolePermissionsInfo.permissions,
        },
      }).then(({ data }) => {
        if (data.updateUserRole._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Role Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create User Role",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      roleInfo: {
        name: data?.userRole?.name || "",
        description: data?.userRole?.description || "",
        type: data?.userRole?.type || "",
        expiresAt: data?.userRole?.expiresAt || "",
        requiresDistrict: data?.userRole?.requiresDistrict || "",
        requiresRegion: data?.userRole?.requiresRegion || "",
      },
      rolePermissionsInfo: {
        applications: data?.userRole?.applications || [],
        permissions: data?.userRole?.permissions || [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.userRole, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchingUserRole}
      title='Edit User Role Information'
      description='Provide the details to update user role'
      hideActions={true}
      size='4xl'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing User Role..." : "Edit User Role"}
          </button>
        </>
      )}
    >
      {data?.userRole?._id && <UserRoleForm validationSchema={UserRoleFormDataValidationSchema} form={form} />}
    </Modal>
  );
}
