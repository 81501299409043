import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { currentConfigVar } from 'apollo/cache/config'
import moment from 'moment'
import numeral from 'numeral'
import { FC } from 'react'
import { useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import { classNames } from 'utils'

const statsMap = [
  { name: 'New Service Orders', accessor: 'totalCreatedAt' },
  { name: 'Assigned Service Orders', accessor: 'totalAssignedAt' },
  { name: 'Resolved Service Orders', accessor: 'totalResolvedAt' },
  { name: 'Approved Service Orders', accessor: 'totalApprovedAt' },
  { name: 'Rejected Service Orders', accessor: 'totalRejectedAt' },
]

const DATE_FORMAT = "YYYY-MM-DD";

const OVERVIEW_SUMMARY = gql`
query GetSummaryServiceOrderOverview($region: ID, $district: ID, $fromDate: DateTime!, $toDate: DateTime!) {
  summary: getSummaryServiceOrderOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate) {
    totalCreatedAt
    totalAssignedAt
    totalResolvedAt
    totalApprovedAt
    totalRejectedAt
  }
}
`

const ServiceOrderOverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>()

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      region: searchParams?.region || undefined,
      district: searchParams?.district || undefined,
      fromDate: moment(searchParams?.fromDate, DATE_FORMAT).startOf("day").toISOString(),
      toDate: moment(searchParams?.toDate, DATE_FORMAT).endOf("day").toISOString()
    },
  })

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Service Order Overview</h3>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-5 md:divide-y-0 md:divide-x">
        {statsMap.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                {numeral(data?.summary?.[item.accessor] || 0).format("0,0")}
                {/* <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span> */}
              </div>

              <div
                className={classNames(
                  // item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {/* {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )} */}

                {/* <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span> */}
                {/* {item.change} */}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default ServiceOrderOverviewContainer