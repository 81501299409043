import { SelectInput, TextInput } from "components/core";
import { RegionPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

export interface DistrictFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    region: any;
    jobCode: string;
    cartesianLocation: {
      easting: number;
      northing: number;
      zone: string;
    };
  }>;
  disabled?: boolean;
}

const DistrictForm: FC<DistrictFormProps> = ({ form, disabled }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">District Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <RegionPicker
              id="region"
              label="Region"
              disabled={disabled}
              placeholder="e.g. Accra East Region"
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="code"
              label="District Code"
              disabled={disabled || !form?.values?.region}
              preText={form?.values?.region?.code?.concat("-")}
              type="text"
              placeholder="e.g. 04"
              required={true}
              maxLength={2}
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextInput
              id="name"
              label="District Name"
              type="text"
              placeholder="e.g. Legon District"
              required={true}
              {...form}
            />
          </div>
          <div className="space-y-1">
            <TextInput
              id="jobCode"
              label="Job Code"
              type="text"
              disabled={disabled}
              placeholder="e.g. XX"
              required={true}
              {...form}
            />
          </div>
          <div className="col-start-1">
            <TextInput
              id="cartesianLocation.easting"
              label="Easting (X)"
              type="number"
              placeholder="e.g. 56"
              {...form}
              required={true}
            />
          </div>

          <div>
            <TextInput
              id="cartesianLocation.northing"
              label="Northing (Y)"
              type="number"
              placeholder="e.g. 56"
              {...form}
              required={true}
            />
          </div>

          <div className="">
            <SelectInput
              id="cartesianLocation.zone"
              label="Zone"
              disabled={disabled}
              options={[
                { label: "Select Zone", value: "" },
                { label: "Zone 30°N", value: "30" },
                { label: "Zone 31°N", value: "31" },
              ]}
              placeholder="e.g. Zone 30/31"
              {...form}
              required={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistrictForm;
