import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { currentConfigVar } from 'apollo/cache/config'
import moment from 'moment'
import numeral from 'numeral'
import { FC } from 'react'
import { useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import { classNames } from 'utils'
import { Pie, Sunburst } from '../graphs'

const statsMap = [
  { name: 'New Service Orders', accessor: 'totalCreatedAt' },
  { name: 'Assigned Service Orders', accessor: 'totalAssignedAt' },
  { name: 'Resolved Service Orders', accessor: 'totalResolvedAt' },
  { name: 'Approved Service Orders', accessor: 'totalApprovedAt' },
  { name: 'Rejected Service Orders', accessor: 'totalRejectedAt' },
]

const DATE_FORMAT = "YYYY-MM-DD";

const OVERVIEW_SUMMARY = gql`
query GetSummaryServiceOrderOverview($region: ID, $district: ID, $fromDate: DateTime!, $toDate: DateTime!) {
  inspection: getSummaryServiceOrderOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate type: Inspection) {
    totalCreatedAt
    totalAssignedAt
    totalResolvedAt
    totalApprovedAt
    totalRejectedAt
  }
  installation: getSummaryServiceOrderOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate type: Installation) {
    totalCreatedAt
    totalAssignedAt
    totalResolvedAt
    totalApprovedAt
    totalRejectedAt
  }
  recapture: getSummaryServiceOrderOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate type: Recapture) {
    totalCreatedAt
    totalAssignedAt
    totalResolvedAt
    totalApprovedAt
    totalRejectedAt
  }
  disconnection: getSummaryServiceOrderOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate type: Disconnection) {
    totalCreatedAt
    totalAssignedAt
    totalResolvedAt
    totalApprovedAt
    totalRejectedAt
  }
  reconnection: getSummaryServiceOrderOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate type: Reconnection) {
    totalCreatedAt
    totalAssignedAt
    totalResolvedAt
    totalApprovedAt
    totalRejectedAt
  }
  fieldInvestigation: getSummaryServiceOrderOverview(region: $region, district: $district, fromDate: $fromDate, toDate: $toDate type: FieldInvestigation) {
    totalCreatedAt
    totalAssignedAt
    totalResolvedAt
    totalApprovedAt
    totalRejectedAt
  }
}
`

const serviceOrderTypesMap = [
  { label: "Inspection", accessor: "inspection", "color": "#B81365" },
  { label: "Installation", accessor: "installation", "color": "#BFAB25"  },
  { label: "Recapture", accessor: "recapture", "color": "#026C7C"  },
  { label: "Disconnection", accessor: "disconnection", "color": "#0FA3B1"  },
  { label: "Reconnection", accessor: "reconnection", "color": "#175676"  },
  { label: "Field Investigation", accessor: "fieldInvestigation", "color": "#0582CA"  },
]

const serviceOrderStatusesMap = [
  { label: "New", accessor: "totalCreatedAt", "color": "#B8B3BE" },
  { label: "Assigned", accessor: "totalAssignedAt", "color": "#F2C14E" },
  { label: "Resolved", accessor: "totalResolvedAt", "color": "#F78154" },
  { label: "Approved", accessor: "totalApprovedAt", "color": "#5FAD56" },
  { label: "Rejected", accessor: "totalRejectedAt", "color": "#D62246" },
]

const ServiceOrderOverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>()

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      region: searchParams?.region || undefined,
      district: searchParams?.district || undefined,
      fromDate: moment(searchParams?.fromDate, DATE_FORMAT).startOf("day").toISOString(),
      toDate: moment(searchParams?.toDate, DATE_FORMAT).endOf("day").toISOString()
    },
  })

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Service Order Overview</h3>
      <div className='h-full w-full bg-white rounded-lg shadow p-6 mt-5'>
        <Pie
          // data={{
          //   "name": "nivo",
          //   "color": "hsl(191, 70%, 50%)",
          //   "children": serviceOrderStatusesMap.map(status => ({
          //     name: status.label,
          //     color: status.color,
          //     children: serviceOrderTypesMap.map(type => ({
          //       name: type.label,
          //       color: type.color,
          //       loc: data?.[type.accessor]?.[status.accessor] || 0,
          //     }))
          //   }))
          // }}
        />
      </div>
    </div>
  )
}

export default ServiceOrderOverviewContainer