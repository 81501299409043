import { gql, useQuery } from "@apollo/client";


const GET_CONTRACTORS = gql`
query GetContractors(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $district: ID
) {
  rows: getContractors(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    district: $district
  ) {
    _id
    code
    name
    type
    address
    ghanaPostAddress
    contactPerson {
      emailAddress
      fullName
      phoneNumber
    }
    createdAt
    updatedAt
  }
  count: getContractorsCount(
    search: $search
    searchFields: $searchFields
    district: $district
  )
}

`;




export const useContractors = (variables: any) => {
  const { data, ...rest } = useQuery(GET_CONTRACTORS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const contractors = data?.rows || [];
  const count = data?.count || 0;
  return { contractors, count, ...rest };
}