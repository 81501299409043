import { useEffect } from "react";
import { validators, wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import MeterContractorForm from "components/forms/meter-contractor";
import lodash from "lodash";
import * as yup from 'yup';

const GET_CONTRACTOR = gql`
  query GetContractor($id: ID!) {
  contractor: getMeterContractor(id: $id) {
    _id
    code
    name
    address
    ghanaPostAddress
    contactPerson {
      emailAddress
      phoneNumber
      fullName
    }
    createdAt
    updatedAt
    districts {
      _id
    }
  }
}
`;

const UPDATE_CONTRACTOR = gql`
  mutation UpdateMeterContractor(
    $id: ID!
    $name: String!
    $address: String!
    $ghanaPostAddress: String!
    $contactPerson: ContactPersonInput!
    $districts: [ID!]!
  ) {
    updateMeterContractor(
      id: $id
      name: $name
      address: $address
      ghanaPostAddress: $ghanaPostAddress
      contactPerson: $contactPerson
      districts: $districts
    ) {
      _id
      code
      createdAt
      updatedAt
    }
  }
`;

export default function UpdateContractorContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetchingContractor } = useQuery(GET_CONTRACTOR, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateContractor, { loading }] = useMutation(UPDATE_CONTRACTOR);

  const form = useFormik({
    initialValues: {
      name: "",
      address: "",
      districts: [] as string[],
      ghanaPostAddress: "",
      contactPerson: {
        fullName: "",
        emailAddress: "",
        phoneNumber: "",
      },
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Contractor name is required"),
      address: yup.string().required("Contractor address is required"),
      ghanaPostAddress: yup.string()
      .matches(
        validators.GhanaPostRegex,
        "Kindly enter a valid GhanaPost Address"
      )
      .required("GhanaPost Address is required"),
      district: yup.array().min(1, "Kindly enter at least one district"),
      contactPerson: yup.object().shape({
        fullName: yup.string().required("Contact Person's name is required"),
        emailAddress: yup.string().email("Invalid email address"),
        phoneNumber: yup.string()
          .matches(
            /^(0|\+233)(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
            "Kindly enter a valid phone number"
          )
          .required("Phone number is required"),
      }),
    }),
    onSubmit: async (values) => {
      await updateContractor({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.updateMeterContractor._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Contractor Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Contractor",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      name: data?.contractor?.name || "",
      address: data?.contractor?.address || "",
      districts: lodash.map(data?.contractor?.districts, "_id") || [],
      ghanaPostAddress: data?.contractor?.ghanaPostAddress || "",
      contactPerson: {
        fullName: data?.contractor?.contactPerson?.fullName || "",
        emailAddress: data?.contractor?.contactPerson?.emailAddress || "",
        phoneNumber: data?.contractor?.contactPerson?.phoneNumber || "",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.contractor, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchingContractor}
      title="Edit Contractor Information"
      description="Provide the details to update contractor"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Contractor..." : "Edit Contractor"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      {data?.contractor?._id && <MeterContractorForm form={form} />}
    </Modal>
  );
}
