import { gql, useQuery } from "@apollo/client";



const GET_ACTIVITY_LOGS = gql`
query GetTariffClasses(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
) {
  rows: getTariffClasses(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
  ) {
    _id
    code
    name
    description
    serviceClass
    createdAt
    updatedAt
  }
  count:getTariffClassesCount(
    search: $search
    searchFields: $searchFields
  )
}

`;

export const useActivityLogs = (variables: any) => {
  const { data, ...rest } = useQuery(GET_ACTIVITY_LOGS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const logs = data?.rows || [];
  const count = data?.count || 0;
  return { logs, count, ...rest };
}