import { Switch } from "@headlessui/react";
import { SelectInput, TextArea, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { classNames, wrapClick } from "utils";

interface InstanceInfoFormProps {
  handleNext: (values: any) => void;
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  validationSchema: any
  handleCancel: () => void;
}

const InstanceInfoForm: FC<InstanceInfoFormProps> = ({
  initialValues,
  validationSchema,
  handleNext,
  handleCancel,
}) => {
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6">
        <div>
          <span className="text-xs font-light">Instance Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-start-1">
              <SelectInput
                id="type"
                label="Type"
                placeholder="eg. Custom"
                required={true}
                options={[
                  { label: "-- Select a Role Type --", value: "" },
                  { label: "Custom", value: "Custom" },
                  { label: "Temporal", value: "Temporal" },
                ]}
                {...form}
              />
            </div>

            <div className="col-span-1">
              <TextInput
                id="name"
                label="Name"
                type="text"
                placeholder="e.g. 2022 Tariff Regime"
                required={true}
                {...form}
              />
            </div>

            {form?.values?.type === "TEMPORAL" && (
              <div>
                <SelectInput
                  id="expiresAt"
                  label="Expires In"
                  placeholder="e.g. 3 days"
                  required={true}
                  options={[
                    { label: "-- select one --", value: "" },
                    { label: "Custom", value: "CUSTOM" },
                    { label: "Temporal", value: "TEMPORAL" },
                  ]}
                  {...form}
                />
              </div>
            )}

            <div className="col-start-1 col-span-3">
              <TextArea
                id="description"
                label="Description"
                placeholder="e.g. A brief description of the user role"
                {...form}
              />
            </div>

            <Switch.Group as="div">
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Requires Region
                </Switch.Label>
              </span>
              <div className="flex items-center justify-between mt-1  h-[38px]">
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Does role require Region selection?
                </Switch.Description>
                <Switch
                  checked={form.values.requiresRegion}
                  onChange={(val: boolean) =>
                    form.setFieldValue("requiresRegion", val)
                  }
                  className={classNames(
                    form.values.requiresRegion
                      ? "bg-primary-600"
                      : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      form.values.requiresRegion
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
            </Switch.Group>

            <Switch.Group as="div">
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Requires District
                </Switch.Label>
              </span>
              <div className="flex items-center justify-between mt-1  h-[38px]">
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Does role require District selection?
                </Switch.Description>
                <Switch
                  checked={form.values.requiresDistrict}
                  onChange={(val: boolean) =>
                    form.setFieldValue("requiresDistrict", val)
                  }
                  className={classNames(
                    form.values.requiresDistrict
                      ? "bg-primary-600"
                      : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      form.values.requiresDistrict
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
            </Switch.Group>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default InstanceInfoForm;
