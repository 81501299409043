import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface UserRolePickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  rawId?: boolean;
}

const GET_USER_ROLES = gql`
  query GetUserRolesSelect {
    userRoles: getUserRoles(sort: "name" pageSize: 0 page: 0) {
      _id
      code
      name
      requiresDistrict
      requiresRegion
    }
  }
`;

const UserRolePickerContainer: FC<UserRolePickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_USER_ROLES, {
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "role"}
      label={label ?? "User Role"}
      placeholder="Select User Role"
      optionsLoading={loading}
      options={(data?.userRoles ?? [])?.map((userRole: any) => ({
        label: {
          title: userRole.name as string,
        },
        value: rawId ? userRole?._id : userRole
      }))}
      {...form}
    />
  )
}

export default UserRolePickerContainer