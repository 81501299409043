import { Application, UserPermission } from "apollo/cache/auth";
import { Applications } from "apollo/data";
import lodash from "lodash";
import numeral from "numeral";
import { FC } from "react";

interface UserRoleViewProps {
  userRole: {
    code: string;
    name: string;
    type: string;
    expiresAt: string;
    description: string;
    applications: Application[];
    permissions: UserPermission[];
    status: string;
  };
}

const UserRoleView: FC<UserRoleViewProps> = ({ userRole }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Role Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>Type</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {lodash.startCase(userRole?.type?.toLowerCase()) || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Name</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {userRole?.name || "N/A"}
            </div>
          </div>
          {userRole?.type === "TEMPORAL" && (
            <div className=''>
              <span className='block text-sm font-light text-gray-700'>
                Expires In
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {userRole?.expiresAt || "N/A"}
              </div>
            </div>
          )}
          <div className='col-start-1 col-span-3'>
            <span className='block text-sm font-light text-gray-700'>
              Description
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {userRole?.description || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Applications Information</span>
        <div className='space-y-6 mt-2'>
          {lodash.map(
            Applications.filter((application) =>
              lodash.includes(userRole?.applications, application.code)
            ),
            (application, idx) => {
              const id = `applications.${application.code}`;
              return (
                <div key={idx} className='pt-6 first:pt-0'>
                  <span className='text-xs font-light'>{application.url}</span>
                  <div className='space-y-6 mt-2'>
                    <div className='col-span-3'>
                      <div>
                        <div className='flex justify-between'>
                          <span className='flex flex-grow flex-col'>
                            <span className='text-sm font-medium text-gray-900'>
                              Gets Access to {application?.name}
                            </span>
                            <span className='text-sm text-gray-500 mt-1'>
                              {application?.description}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Permissions Information</span>
        <div className='space-y-6 mt-2'>
          <div className='border border-gray-300 bg-gray-50 grid grid-cols-3 gap-6 p-6 rounded'>
            {lodash.map(
              lodash
                .reduce(
                  Applications,
                  (acc: any[], app) => {
                    lodash.forEach(app.sections, (section) => {
                      const existingSection = lodash.find(acc, {
                        name: section.name,
                      });
                      if (!existingSection) {
                        acc.push({
                          name: section.name,
                          permissions: section.permissions,
                        });
                      } else {
                        existingSection.permissions = [
                          ...existingSection.permissions,
                          ...section.permissions.filter(
                            (perm) =>
                              !existingSection.permissions
                                ?.map(
                                  (existingPerm: any) => existingPerm?.value
                                )
                                .includes(perm.value)
                          ),
                        ];
                      }
                    });

                    return acc;
                  },
                  []
                )
                ?.filter(
                  (section) =>
                    lodash.intersection(
                      lodash.map(section.permissions, "value"),
                      userRole?.permissions
                    ).length
                ),
              (section) => (
                <div className=''>
                  <span className='block text-sm font-medium text-gray-700'>
                    {lodash.startCase(section.name)}
                  </span>
                  <div className='mt-1 w-full space-y-1'>
                    {lodash.map(
                      section.permissions?.filter((permission: any) =>
                        userRole?.permissions?.includes(
                          permission.value as UserPermission
                        )
                      ),
                      (permission) => (
                        <div className='relative flex items-start'>
                          <div className='flex h-6 items-center'>
                            <input
                              id={`${permission?.value}.permissions`}
                              aria-describedby={`${permission.value}-description`}
                              name={`${permission?.value}.permissions`}
                              type='checkbox'
                              value={permission.value}
                              checked={userRole?.permissions?.includes(
                                permission.value as UserPermission
                              )}
                              className='h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600'
                            />
                          </div>
                          <div className='ml-3 text-sm leading-6'>
                            <label
                              htmlFor='candidates'
                              className='font-medium text-gray-900'
                            >
                              {permission.value}
                            </label>
                            {false && (
                              <p
                                id='candidates-description'
                                className='text-gray-500'
                              >
                                Get notified when a candidate applies for a job.
                              </p>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRoleView;
