import { Application, UserPermission } from "apollo/cache/auth";
import { Applications } from "apollo/data";
import lodash from "lodash";
import numeral from "numeral";
import { FC } from "react";

interface ComplaintTypeViewProps {
  complaintType:any
}

const ComplaintTypeView: FC<ComplaintTypeViewProps> = ({ complaintType }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Complaint Type Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>Code</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.code || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Name</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.name || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Requires Document</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.requiresDocument ? "Yes" : "No"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Requires Investigation</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.requiresInvestigation ? "Yes" : "No"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Requires Service Point</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.requiresServicePoint ? "Yes" : "No"}
            </div>
          </div>
          <div className=' col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Description
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.description || "N/A"}
            </div>
          </div>
        </div>
      </div>
    
      <div className="pt-6">
        <span className='text-xs font-light'>Category Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>Category Name</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.category || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Category Code</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.categoryCode || "N/A"}
            </div>
          </div>
     
        </div>
      </div>
      <div className="pt-6">
        <span className='text-xs font-light'>User Role Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>Role Name</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.userRole?.name || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Role Code</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.userRole?.code || "N/A"}
            </div>
          </div>
     
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Contact Email</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {complaintType?.contactEmailAddress || "N/A"}
            </div>
          </div>
     
        </div>
      </div>
    </div>
  );
};

export default ComplaintTypeView;
