import { Avatar } from "components";
import { FC } from "react";
import { classNames } from "utils";

interface UserTeamViewProps {
  userTeam: {
    region: any;
    district: any;
    leader: any;
    members: any[];
    code: string;
    name: string;
    status: string;
  };
}

function Owner({ owner, type }: { owner: any; type?: "leader" }) {
  return (
    <div
      className={classNames(
        type === "leader"
          ? "border-green-600 bg-green-50 dark:border-green-800 dark:bg-green-800 hover:border-green-400 dark:hover:border-green-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            [(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {[(owner?.lastName || "")?.trim(), (owner?.firstName || "")?.trim()]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.phoneNumber || "N/A"}
        </p>
      </div>
    </div>
  );
}

const UserTeamView: FC<UserTeamViewProps> = ({ userTeam }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Team Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>Name</span>
            <div className='mt-1 block w-full sm:text-sm'>
            {userTeam?.name || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>Region</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {userTeam?.region?.name || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              District
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {userTeam?.district?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Members Information</span>
        <div className='space-y-6 mt-2'>
        <div className="grid grid-cols-3 gap-6">
          <Owner
            key={"leader"}
            owner={userTeam?.leader}
            type="leader"
          />
          {userTeam?.members?.map?.((user: any) => (
            <Owner key={user._id} owner={user} />
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default UserTeamView;
