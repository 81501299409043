import numeral from 'numeral';
import { FC } from 'react'

interface RegionViewProps {
  region: {
    code: string;
    name: string;
    address: string;
    ghanaPostAddress: string;
    contactPerson: {
      fullName: string;
      phoneNumber: string;
    };
  };
}

const RegionView: FC<RegionViewProps> = ({ region }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Region Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Region Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {region?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Region Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {region?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              GhanaPost Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {region?.ghanaPostAddress || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Office Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {region?.address || "N/A"}
            </div>
          </div>

        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contact Person</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {region?.contactPerson?.fullName || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {region?.contactPerson?.phoneNumber || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegionView