import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { currentConfigVar } from 'apollo/cache/config'
import moment from 'moment'
import { FC } from 'react'
import { useNavigate, useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import { ActionButton } from 'components'
import numeral from 'numeral'


const OVERVIEW_SUMMARY = gql`
query GetMonthlyReaderItinerariesSummary($reader: ID!, $region: ID, $district: ID, $month: DateTime, $year: DateTime) {
  itineraries: getMonthlyReaderItinerariesSummary(reader: $reader, region: $region, district: $district, month: $month, year: $year) {
    _id
    itineraryCode
    itineraryDescription
    customersReadCount
    customersCount
  }
}
`

const ReaderItinerariesOverviewContainer: FC<{reader: string}> = ({reader}) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const { data } = useQuery(OVERVIEW_SUMMARY, {
    variables: {
      reader,
      region: searchParams?.region || undefined,
      district: searchParams?.district || undefined,
      month: moment(searchParams?.fromDate || new Date()).month() + 1,
      year: moment(searchParams?.fromDate || new Date()).year(),
    },
  })

  const navigateItem = (id: string) => () => {
    navigate({ to: `/reading-sets/${id}`})
  } 

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
      {data?.itineraries?.map((itinerary: any) => (
        <div
          key={itinerary?.itineraryCode}
          className="flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <div className="min-w-0 flex-1">
            <div className="focus:outline-none">
              <p className="text-sm font-medium text-gray-900">{itinerary?.itineraryCode}</p>
              <p className="truncate text-sm text-gray-500">{numeral(itinerary?.customersReadCount).format("0,0")}/{numeral(itinerary?.customersCount).format("0,0")} Meters Read</p>
            </div>
          </div>
          <div className="flex-shrink-0">
            <ActionButton
              action={'goto'}
              onClick={navigateItem(itinerary?._id)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default ReaderItinerariesOverviewContainer