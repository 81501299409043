import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { UserTeamView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const GET_USER_TEAM = gql`
  query GetUserTeam($id: ID!) {
    userTeam: getUserTeam(id: $id) {
      _id
      code
      name
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      leader {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        role {
          _id
          code
          name
        }
        createdAt
        updatedAt
      }
      members {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        gender
        profileImageUrl
        role {
          _id
          code
          name
        }
        createdAt
        updatedAt
      }
      meta {
        usersCount
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewUserTeamContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_USER_TEAM, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
        userTeam: searchParams.id,
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='User Team Information'
      description='Details of user team are shown below'
      size='4xl'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "user-teams:*", "user-teams:update"])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <UserTeamView userTeam={data?.userTeam} />
    </Modal>
  );
}
