import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { DistrictView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_DISTRICT = gql`
  query GetDistrict($id: ID!) {
    district: getDistrict(id: $id) {
      _id
      code
      name
      region {
        _id
        code
        name
      }
      cartesianLocation {
        northing
        easting
        zone
      }
      meta {
        usersCount
        blocksCount
        roundsCount
        plotsCount
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
  }
`

export default function ViewDistrictContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_DISTRICT, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="District Information"
      description="Details of district are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "districts:*", "districts:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <DistrictView
        district={data?.district}
      />
    </Modal>
  )
}