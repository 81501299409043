import { FC } from "react";
import { withPermissions, wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { UserView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import toast from "react-hot-toast";

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user: getUser(id: $id) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      role {
        _id
        code
        name
        description
        requiresDistrict
        requiresRegion
        type
        applications
        permissions
        status
        expiresAt
        createdAt
        updatedAt
      }
      region {
        _id
      }
      regionCode
      regionName
      district {
        _id
      }
      districtCode
      districtName
      meta {
        isPasswordSet
        isBlocked
        isDeleted
        lastLoginAt
      }
      createdAt
      updatedAt
      roleCode
      roleName
    }
  }
`;
const RESET_FINGER_PRINT = gql`
  mutation ResetFingerPrint($id: ID!) {
    resetUserFingerprint(id: $id) {
      _id
    }
  }
`;

interface ViewUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const ViewUserContainer: FC<ViewUserContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
      }),
    });
  };
  const [resetFingerprint, { loading: resetting }] = useMutation(
    RESET_FINGER_PRINT,
    {
      onCompleted: () => {
        refetch?.();
        // setOpen(false);
        toast(
          JSON.stringify({
            type: "success",
            title: "Fingerprint reset successfully",
          })
        );
      },
      onError: (error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: "Could not reset fingerprint",
          })
        );
      },
    }
  );

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title='User Information'
      description='Details of user are shown below'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "users:*", "users:update"])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
          {withPermissions(["*:*", "users:*", "users:update"])(
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() =>
                resetFingerprint({ variables: { id: searchParams.id } })
              }
            >
              {resetting ? " Resetting ..." : " Reset Fingerprint"}
            </button>
          )}
        </>
      )}
    >
      {data?.user?._id && <UserView user={data?.user} />}
    </Modal>
  );
};

export default ViewUserContainer;
