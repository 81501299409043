import { FC } from "react"
import { EyeIcon, PencilSquareIcon, DocumentDuplicateIcon, CalculatorIcon, ArrowsPointingOutIcon, PaperAirplaneIcon, CalendarDaysIcon, UserPlusIcon, UserIcon, ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, ArrowPathRoundedSquareIcon, MagnifyingGlassPlusIcon, ArrowTopRightOnSquareIcon, Cog8ToothIcon, TrashIcon, LockOpenIcon, LockClosedIcon } from "@heroicons/react/24/outline"
import { classNames, wrapClick } from "utils";
import "react-tooltip/dist/react-tooltip.css"

const Actions = ["update", "view", "configure", "calculate", "upload", "enable", "disable", "delete", "assign", "schedule", "send", "resolve", "approve", "reject", "reassign", "expand", "goto", "clone", "investigate"] as const;
export type Action = typeof Actions[number];
const ActionIcons: { [key in Action]: typeof EyeIcon } = {
  "approve": ClipboardDocumentCheckIcon,
  "assign": UserPlusIcon,
  "calculate": CalculatorIcon,
  "configure": Cog8ToothIcon,
  "reassign": UserIcon,
  "reject": ArrowPathRoundedSquareIcon,
  "resolve": ClipboardDocumentListIcon,
  "schedule": CalendarDaysIcon,
  "send": PaperAirplaneIcon,
  "update": PencilSquareIcon,
  "view": EyeIcon,
  "expand": ArrowsPointingOutIcon,
  "goto": ArrowTopRightOnSquareIcon,
  "clone": DocumentDuplicateIcon,
  "investigate": MagnifyingGlassPlusIcon,
  "delete": TrashIcon,
  "upload": ClipboardDocumentListIcon,
  "enable": LockOpenIcon,
  "disable": LockClosedIcon,
};

interface ActionButtonProps {
  action: Action;
  onClick: (...val: any) => any;
  disabled?: boolean;
};

const ActionButton: FC<ActionButtonProps> = ({ action, onClick, disabled = false }) => {

  const Icon = ActionIcons[action];

  return (
    <button
      type="button"
      data-tooltip-id="tooltip"
      data-tooltip-content={action}
      data-tooltip-delay-show={1000}
      onClick={wrapClick(onClick)}
      disabled={disabled}
      className={classNames(
        disabled ? "cursor-not-allowed text-gray-500 hover:bg-gray-300" : "text-gray-500 hover:bg-gray-300 hover:text-gray-900",
        "inline-flex items-center rounded-full border border-transparent p-1  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
      )}
    >
      <Icon className="h-5 w-5" aria-hidden="true" />
    </button>
  )
}
export default ActionButton;