import { FC } from "react";

interface MeterContractorViewProps {
  contractor: {
    code: string;
    name: string;
    type: string;
    address: string;
    ghanaPostAddress: string;
    contactPerson: {
      fullName: string;
      phoneNumber: string;
    };
    districts: {
      code: string;
      name: string;
    }[];
  };
}

const MeterContractorView: FC<MeterContractorViewProps> = ({ contractor }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Contractor Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Contractor Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {contractor?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Contractor Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {contractor?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              GhanaPost Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {contractor?.ghanaPostAddress || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Office Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {contractor?.address || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Person</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {contractor?.contactPerson?.fullName || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {contractor?.contactPerson?.phoneNumber || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Person</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          {contractor?.districts?.map((district, index) => (
            <div>
              <span className="block text-sm font-light text-gray-700">
                District {index + 1}
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {district?.code || "N/A"} - {district?.name || "N/A"}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeterContractorView;
