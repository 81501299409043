import { StepWizard } from "components";
import { FormikProps } from "formik"
import { FC, useState } from "react"
import ExtraItemsInfoForm from "./extra-items-info";
import InstanceInfoForm from "./instance-info";
import ChargeInfoForm from "./charge-info";
import SummaryPage from "./summary";
import BillingPreviewPage from "./billing-preview";

interface TariffInstanceFormProps {
  form: FormikProps<{
    tariffInstanceInfo: {
      code: string;
      name: string;
      startDate: string;
    },
    chargeInfo: {
      energyCharge: {
        type: string;
        value: number;
        steps: {
          name: string;
          displayName: string;
          minimumConsumption: number;
          maximumConsumption: number;
          value: number;
          exclusive: boolean;
        }[];
      };
      serviceCharge: {
        type: string;
        value: number;
        steps: {
          name: string;
          displayName: string;
          minimumConsumption: number;
          maximumConsumption: number;
          value: number;
          exclusive: boolean;
        }[];
      };
    };
    extraItemsInfo: {
      hasExtraItems: boolean;
      extraItems: {
        name: string;
        displayName: string;
        category: string;
        type: string;
        appliedTo: string;
        value: number;
      }[];
    }
  }>;
}

const steps = [
  { name: "Instance Info", href: 0 },
  { name: "Charge Info", href: 1 },
  { name: "Extra Items Info", href: 2 },
  { name: "Bill Preview", href: 3 },
  { name: "Preview", href: 4 },
]

const TariffInstanceForm: FC<TariffInstanceFormProps> = ({ form }) => {

  const [step, setStep] = useState(0)
  const [lastStep, setLastStep] = useState(0)

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  }

  const handleNextStep = (_step?: string) => (_values: any) => {
    // check if last step
    if(_step) {
    form.setFieldValue(_step, _values)
    }
    setStep(step + 1)
    setLastStep(step === lastStep ? (lastStep + 1) : lastStep)
  }

  const handlePreviousStep = () => {
    setStep(step - 1)
  }

  const handleCancel = () => {
    form.resetForm();
    onCancel();
  }

  const renderForm = () => {

    switch (step) {
      case 0: {
        return <InstanceInfoForm
          handleNext={handleNextStep("tariffInstanceInfo")}
          initialValues={form.values["tariffInstanceInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
      case 1: {
        return <ChargeInfoForm
          handleNext={handleNextStep("chargeInfo")}
          initialValues={form.values["chargeInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
      case 2: {
        return <ExtraItemsInfoForm
          handleNext={handleNextStep("extraItemsInfo")}
          initialValues={form.values["extraItemsInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
      case 3: {
        return <BillingPreviewPage
          handleNext={handleNextStep()}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
      case 4: {
        return <SummaryPage
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleStep={setStep}
          handleSubmit={form.handleSubmit}
          handleCancel={handleCancel}
          submitLoading={form.isSubmitting}
        />
      }
      default: {
        return <InstanceInfoForm
          handleNext={handleNextStep("chargeInfo")}
          initialValues={form.values["chargeInfo"]}
          values={form.values}
          handlePrevious={handlePreviousStep}
          handleCancel={handleCancel}
        />
      }
    }
  }

  return (
    <div className="bg-white dark:bg-gray-900 shadow-xl flex flex-col flex-1  overflow-hidden relative">
      <div className="px-6 pt-6">
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {renderForm()}
      </div>
    </div>
  )
}

export default TariffInstanceForm