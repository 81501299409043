import { Switch } from "@headlessui/react";
import { ComplaintCategories } from "apollo/data";
import {
  SelectInput,
  TextArea,
  TextInput,
} from "components/core";
import { UserRolePicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";
import { classNames } from "utils";

interface ComplaintTypeFormProps {
  form: FormikProps<{
    name: string;
    description: string;
    category: string;
    requiresDocument: boolean;
    requiresInvestigation: boolean;
    requiresServicePoint: boolean;
    userRole: any;
    contactEmailAddress: string;
  }>;
}

const ComplaintTypeForm: FC<ComplaintTypeFormProps> = ({ form }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Complaint Type Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <TextInput
              id='name'
              label='Name'
              type='text'
              placeholder='e.g. Stolen Meter Request'
              required={true}
              {...form}
            />
          </div>
          <div>
            <UserRolePicker
              id='userRole'
              label='User Role'
              placeholder='User Role Name'
              rawId
              required={true}
              {...form}
              setFieldValue={(field: string, value: any) => {
                form.setFieldValue(field, value);
              }}
            />
          </div>
          <div>
            <TextInput
              id='contactEmailAddress'
              label='Contact Email '
              type='email'
              placeholder='e.g. dto@ecg.com'
              {...form}
            />
          </div>
          <div className=''>
            <SelectInput
              id='category'
              label='Category'
              placeholder='CC00'
              required={true}
              options={[
                {
                  label: "--- Select Category ---",
                  value: "",
                },
                ...ComplaintCategories.map((code) => ({
                  label: code.name,
                  value: code.name,
                })),
              ]}
              {...form}
            />
          </div>
          <div className='col-start-1 col-span-2'>
            <TextArea
              id='description'
              label='Description'
              placeholder='e.g. write something here'
              {...form}
            />
          </div>

          <Switch.Group as='div'>
            <span className='flex flex-grow flex-col'>
              <Switch.Label
                as='span'
                className='text-sm font-medium text-gray-900'
                passive
              >
                Requires Document
              </Switch.Label>
            </span>
            <div className='flex items-center justify-between mt-1  h-[38px]'>
              <Switch.Description as='span' className='text-sm text-gray-500'>
                Does this complaint type require document upload?
              </Switch.Description>
              <Switch
                checked={form.values.requiresDocument}
                onChange={(val: boolean) =>
                  form.setFieldValue("requiresDocument", val)
                }
                className={classNames(
                  form.values.requiresDocument
                    ? "bg-primary-600"
                    : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden='true'
                  className={classNames(
                    form.values.requiresDocument
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </Switch.Group>

          <Switch.Group as='div'>
            <span className='flex flex-grow flex-col'>
              <Switch.Label
                as='span'
                className='text-sm font-medium text-gray-900'
                passive
              >
                Requires Investigation
              </Switch.Label>
            </span>
            <div className='flex items-center justify-between mt-1  h-[38px]'>
              <Switch.Description as='span' className='text-sm text-gray-500'>
                Does this complaint type require investigation service order
              </Switch.Description>
              <Switch
                checked={form.values.requiresInvestigation}
                onChange={(val: boolean) =>
                  form.setFieldValue("requiresInvestigation", val)
                }
                className={classNames(
                  form.values.requiresInvestigation
                    ? "bg-primary-600"
                    : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden='true'
                  className={classNames(
                    form.values.requiresInvestigation
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </Switch.Group>
         
          <Switch.Group as='div'>
            <span className='flex flex-grow flex-col'>
              <Switch.Label
                as='span'
                className='text-sm font-medium text-gray-900'
                passive
              >
                Requires Service Point
              </Switch.Label>
            </span>
            <div className='flex items-center justify-between mt-1  h-[38px]'>
              <Switch.Description as='span' className='text-sm text-gray-500'>
                Does this complaint type require investigation service order
              </Switch.Description>
              <Switch
                checked={form.values.requiresServicePoint}
                onChange={(val: boolean) =>
                  form.setFieldValue("requiresServicePoint", val)
                }
                className={classNames(
                  form.values.requiresServicePoint
                    ? "bg-primary-600"
                    : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden='true'
                  className={classNames(
                    form.values.requiresServicePoint
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </Switch.Group>
         
        </div>
      </div>
    </div>
  );
};

export default ComplaintTypeForm;
