import { StepWizard } from "components";
import { FormikProps } from "formik";
import { FC, useState } from "react";
import RoleInfoForm from "./role-info";
import PermissionsInfoForm from "./permissions-info";
import SummaryPage from "./summary";
import { IUserRoleFormData } from "./schema";
import { UserRoleFormDataValidationSchemaType } from "pages/user-roles/create";

interface UserRoleFormProps {
  form: FormikProps<IUserRoleFormData>;
  validationSchema?: UserRoleFormDataValidationSchemaType
}

const steps = [
  { name: "Role Info", href: 0 },
  { name: "Permissions Info", href: 1 },
  { name: "Preview", href: 2 },
];

const UserRoleForm: FC<UserRoleFormProps> = ({ form, validationSchema }) => {
  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const handleNextStep = (_step?: string) => (_values: any) => {
    // check if last step
    if (_step) {
      form.setFieldValue(_step, _values);
    }
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    form.resetForm();
    onCancel();
  };

  const renderForm = () => {
    switch (step) {
      case 0: {
        return (
          <RoleInfoForm
            handleNext={handleNextStep("roleInfo")}
            initialValues={form.values["roleInfo"]}
            validationSchema={validationSchema ? validationSchema.fields.roleInfo : {}}
            values={form.values}
            handlePrevious={handlePreviousStep}
            handleCancel={handleCancel}
          />
        );
      }
      case 1: {
        return (
          <PermissionsInfoForm
            handleNext={handleNextStep("rolePermissionsInfo")}
            initialValues={{
              permissions: form.values?.rolePermissionsInfo.permissions,
              applications: form.values?.rolePermissionsInfo.applications,
            }}
            validationSchema={validationSchema ? validationSchema.fields.rolePermissionsInfo : {}}
            values={form.values}
            handlePrevious={handlePreviousStep}
            handleCancel={handleCancel}
          />
        );
      }
      case 2: {
        return (
          <SummaryPage
            values={form.values}
            handlePrevious={handlePreviousStep}
            handleStep={setStep}
            handleSubmit={form.handleSubmit}
            handleCancel={handleCancel}
            submitLoading={form.isSubmitting}
          />
        );
      }
      default: {
        return (
          <RoleInfoForm
            handleNext={handleNextStep("roleInfo")}
            initialValues={form.values["roleInfo"]}
            values={form.values}
            validationSchema={validationSchema ? validationSchema.fields.roleInfo: {}}
            handlePrevious={handlePreviousStep}
            handleCancel={handleCancel}
          />
        );
      }
    }
  };

  return (
    <div className="bg-white dark:bg-gray-900 shadow-xl flex flex-col flex-1  overflow-hidden relative">
      <div className="px-6 pt-6">
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">{renderForm()}</div>
    </div>
  );
};

export default UserRoleForm;
