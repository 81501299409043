import { useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, RegionForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { regionValidationSchema } from "./create";

const GET_REGION = gql`
  query GetRegion($id: ID!) {
    region: getRegion(id: $id) {
      _id
      code
      name
      cartesianLocation {
        northing
        easting
        zone
      }
      meta {
        usersCount
        districtsCount
        blocksCount
        roundsCount
        plotsCount
        itinerariesCount
        propertiesCount
        customersCount
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_REGION = gql`
  mutation UpdateRegion(
    $id: ID!
    $code: String!
    $name: String!
    $cartesianLocation: CartesianLocationInput
  ) {
    updateRegion(
      id: $id
      code: $code
      name: $name
      cartesianLocation: $cartesianLocation
    ) {
      _id
    }
  }
`;

export default function UpdateRegionContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_REGION, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateRegion, { loading }] = useMutation(UPDATE_REGION);

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      cartesianLocation: {
        northing: 0,
        easting: 0,
        zone: "",
      },
    },
    validationSchema: regionValidationSchema,
    onSubmit: async (values) => {
      await updateRegion({
        variables: {
          id: searchParams.id,
          ...values,
          cartesianLocation: {
            ...values.cartesianLocation,
            ...{ zone: parseInt(values?.cartesianLocation?.zone) },
          },
        },
      }).then(({ data }) => {
        if (data.updateRegion._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Region Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not create Region" })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      code: data?.region?.code || "",
      name: data?.region?.name || "",
      cartesianLocation: {
        northing: data?.region?.cartesianLocation?.northing || 0,
        easting: data?.region?.cartesianLocation?.easting || 0,
        zone: data?.region?.cartesianLocation?.zone?.toString?.() || "",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.region, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Edit Region Information'
      description='Provide the details to update region'
      renderActions={() => (
        <>
          <button
            type='button'
            disabled={loading}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving Changes..." : "Save Changes"}
          </button>
        </>
      )}
    >
      {data?.region?._id && <RegionForm form={form}  disabled={true}/>}
    </Modal>
  );
}
