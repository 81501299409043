import { FC } from 'react'
import { validators, wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, UserForm } from 'components';
import * as yup from 'yup';
const CREATE_USER = gql`
  mutation CreateUser(
    $code: String!
    $lastName: String!
    $firstName: String!
    $gender: Gender
    $ghanaCardNumber: String
    $phoneNumber: String!
    $emailAddress: String
    $profileImageUrl: String
    $role: ID!
    $district: ID
    $region: ID
  ) {
    createUser(
      code: $code
      lastName: $lastName
      firstName: $firstName
      gender: $gender
      ghanaCardNumber: $ghanaCardNumber
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      profileImageUrl: $profileImageUrl
      role: $role
      district: $district
      region: $region
    ) {
      _id
    }
  }
`;

interface CreateUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const CreateUserContainer: FC<CreateUserContainerProps> = ({ open, setOpen, refetch }) => {
  const [createUser, { loading }] = useMutation(CREATE_USER)

  const form = useFormik({
    initialValues: {
      code: "",
      lastName: "",
      firstName: "",
      gender: "Male",
      ghanaCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      profileImageUrl: "",
      role: null as any,
      region: "", 
      district: "",
    },
    validationSchema: yup.object().shape({
      code: yup.string().required("Code is required"),
      lastName: yup.string().required("Last Name is required"),
      firstName: yup.string().required("First Name is required"),
      ghanaCardNumber: yup.string()
      .matches(
        validators.GhanaCardRegex,
        "Kindly enter a valid Ghana Card Number"
      ),
      emailAddress: yup.string().required("Email address is required").email("Kindly enter a valid email address"),
      phoneNumber: yup.string()
        .matches(
          /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
          "Kindly enter a valid phone number"
        )
        .required("Phone number is required"),
      role: yup.object().nullable().required("Role is required"),
      gender: yup.string().oneOf(["Male", "Female"])
    }),
    onSubmit: async (values) => {
      await createUser({
        variables: {
          ...values,
          emailAddress: values?.emailAddress?.toLowerCase(),
          role: values.role?._id
        }
      }).then(({ data }) => {
        if (data.createUser._id) {
          toast(JSON.stringify({ type: "success", title: "User Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create User" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New User"
      description="Provide the details to add a new user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding User..." : "Add User"}
          </button>

        </>
      )}>
      <UserForm
        form={form}
      />
    </Modal>
  )
}

export default CreateUserContainer;